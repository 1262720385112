export default {
        h1: {
                fontFamily: 'Gilroy-ExtraBold',
                fontSize: 54,
                lineHeight: '58px',
                letterSpacing: '-0.03em',
                '@media (max-width: 600px)': {
                        fontSize: 44,
                        lineHeight: '48px',
                }
        },
        h2: {
                fontFamily: 'Gilroy-ExtraBold',
                fontSize: 38,
                lineHeight: '45px',
                letterSpacing: '-0.01em',
                '@media (max-width: 600px)': {
                        fontSize: 30,
                        lineHeight: '32px',
                }
        },
        h3: {
                fontFamily: 'Gilroy-ExtraBold',
                fontSize: 24,
                letterSpacing: '-0.01em',
                '@media (max-width: 600px)': {
                        fontSize: 22,
                }
        },
        h4: {
                fontFamily: 'Gilroy-ExtraBold',
                fontSize: 22,
        },
        h5: {
                fontFamily: 'Gilroy-ExtraBold',
                fontSize: 16,
        },
        h6: {
                fontFamily: 'Gilroy-ExtraBold',
                fontSize: 14,
        },
        subtitle1: {
                fontFamily: 'Gilroy-Light',
                fontSize: 26,
                fontWeight: '800',
                lineHeight: '32px'
        },
        subtitle2: {
                fontFamily: 'Gilroy-Light',
                fontSize: 16,
                fontWeight: 800
        },
        body1: {
                fontFamily: 'Manrope',
                fontSize: 14,
        },
        body2: {
                fontFamily: 'Manrope',
                fontSize: 16,
                lineHeight: '28px',
                '@media (max-width: 600px)': {
                        fontSize: 14,
                        lineHeight: '26px'
                }
        },
        caption: {
                fontFamily: 'Manrope',
                fontSize: 12,
                lineHeight: '16px'
        },
        overline: {
                fontFamily: 'Gilroy-ExtraBold',
                fontSize: 120,
                lineHeight: '130px',
                letterSpacing: '-1px',
                display: 'block',
                textTransform: 'none',
                '@media (max-width: 600px)': {
                        fontSize: 44,
                        lineHeight: '50px',
                }
        },
        label: {
                fontFamily: 'Manrope',
                fontWeight: 600,
                fontSize: 15,
        }
};
