import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton, Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import useDetectKeyboardOpen from 'src/hooks/useDetectKeyboardOpen';
import Home from './Home';
import Search from './Search';
import Cart from './Cart';
import Heart from './Heart';
import User from './User';
import WhatsApp from './WhatsApp';

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: '1000',
        position: 'fixed',
        bottom: '8px',
        left: '8px',
        right: '8px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: '#2E2E2E',
        height: props => props.visible ? '64px' : 'initial',
        padding: props => props.visible ? '8px 16px' : 'initial',
        boxShadow: props => props.visible ? '0 -1px 10px rgba(0, 0, 0, 0.25)': 'initial',
        '& > *': {
            visibility: props => props.visible ? 'visible' : 'hidden',
            pointerEvents: props => props.visible ? 'all' : 'none',
        },
        '& svg': {
            color: '#FFFFFF',
        },
    },
}));

function BottomNav() {
    const [visible, setVisible] = useState(true);
    const classes = useStyles({ visible });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const keyboardIsOpen = useDetectKeyboardOpen();

    useEffect(() => {
        setVisible(!keyboardIsOpen);
    }, [keyboardIsOpen]);

    if (!isMobile || location.pathname === '/onestepcheckout') {
        return null;
    }

    return (
        <>
            <Paper className={classes.root}>
                <Home />
                <Search menuIsVisible={visible} />
                <Cart />
                <Heart />
                <User menuIsVisible={visible} />
                <WhatsApp />
            </Paper>
        </>
    );
}

export default BottomNav;
