import React, { useState, useEffect } from 'react';

import {
    Box,
    Typography,
    makeStyles
} from '@material-ui/core';

import geforce from 'src/theme/scss/images/geforce-product.png';

const useStyles = makeStyles((theme) => ({
    textFeature: {
        width: '100%',
        fontFamily: 'Manrope',
        fontSize: 12,
        color: '#777777',
        fontWeight: 400,
        paddingLeft: 20,
        lineHeight: 1.8,
        position: 'relative',
        '&:before': {
            content: "''",
            display: 'block',
            width: 5,
            height: 5,
            borderRadius: '50%',
            backgroundColor: '#ff367d', //'#ED1C24',
            position: 'absolute',
            top: 8,
            left: 0,
        }
    },
    productFeatures: {
        marginBottom: 5,
        marginTop: 5,
        height: 88,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: '128px',
            overflow: 'hidden',
        }
    },
    infoGpu: {
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        width: '100%',
        filter: 'drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.06))',
        '& span': {
            maxHeight: '30px',
            overflow: 'hidden'
        }
    },
}));

export const ProductDetails = React.memo(function ProductDetails({ product }) {
    const classes = useStyles();

    const [attributes, setAttributes] = useState({});

    function setFormatedAttributes() {
        if (!product.attributes)
            return;

        const productDescription = getProductDescription();
        const productGpu = getProductGpu();

        setAttributes({
            descriptionItems: productDescription,
            gpu: productGpu
        });
    }

    function getProductDescription() {

        const attributes = product.attributes
            .filter(attribute => attribute.isList)
            .map(attribute => {
                return { ...attribute, type: attribute.type }
            }).sort((a, b) => a.sortOrder - b.sortOrder);

        return attributes
            .map(attribute => attribute.type === 'text' ? attribute.optionId : attribute.optionLabel)
            .filter(attribute => attribute);

    }

    function getProductGpu() {
        if (product.attributeGroup?.name !== 'Notebook') {
            return null;
        }

        const gpu = product.attributes.find(attribute => (attribute.code == 'placa-de-video-dedicada' || attribute.code == 'placa-de-video-onboard')) || {};

        return gpu.text ? {
            image: geforce,
            name: gpu.text
        } : null;
    }

    useEffect(() => {
        setFormatedAttributes();
    }, []);

    return (
        <>
            <Box className={classes.infoGpu}>
                {attributes.gpu ? (
                    <>
                        <img src={attributes.gpu.image} />
                        <Typography variant="caption">{attributes.gpu.name}</Typography>
                    </>
                ) : <></>}
            </Box>
            <Box className={classes.productFeatures}>
                {attributes.descriptionItems ? attributes.descriptionItems.map((attribute) => (
                    <p
                        key={attribute}
                        className={classes.textFeature}
                    >
                        {attribute}
                    </p>
                ))
                    : <></>
                }
            </Box>
        </>
    );
});