import React, { useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';

import {
    makeStyles,
    Box,
    useTheme,
    useMediaQuery
} from "@material-ui/core";

import { ContentView } from 'src/components/Content';

import { useNode, useEditor } from "@craftjs/core";
import { getBoxMarginProps, getBoxPaddingProps, getBreakpointBackgroundImage, getBreakpointValue, getColor, getHeight } from './functions';

export const SliderBannerCustom = (props) => {

    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

    const {
        descendants
    } = useNode((node) => ({
        descendants: node.data.nodes
    }));

    const { query } = useEditor((state, query) => ({
        query: query
    }));

    const gallerySwiperRef = useRef(null);
    const thumbnailSwiperRef = useRef(null);

    const dynamicParams = {
        slidesPerView: Number(props.slidesPerView) || 1,
        spaceBetween: Number(props.spaceBetween) || 0,
        effect: props.effect,
        bullets: {
            bottom: props.bullets?.bottom ? `${props.bullets?.bottom}px` : '0px',
        },
        showNext: false
    }

    if (props.autoplay && props.autoplay?.delay > 0) {
        dynamicParams.autoplay = {
            delay: Number(props.autoplay.delay),
            disableOnInteraction: props.autoplay.disableOnInteraction || false
        }
    }

    const swiperParams = {
        spaceBetween: dynamicParams.spaceBetween,
        loop: false,
        effect: dynamicParams.slidesPerView > 1 ? 'slide' : dynamicParams.effect,
        speed: 700,
        slidesPerView: dynamicParams.slidesPerView
    };

    if (dynamicParams.autoplay) {
        swiperParams.autoplay = {
            delay: dynamicParams.autoplay.delay,
            disableOnInteraction: dynamicParams.autoplay.disableOnInteraction
        }
    }

    const thumbnailSwiperParams = {
        spaceBetween: 15,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
    };

    if (!props.showThumbnails) {
        swiperParams.rebuildOnUpdate = true;
        swiperParams.pagination = {
            el: '.swiper-pagination',
            clickable: true,
        };
        swiperParams.navigation = {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    }

    // if (window.location.pathname === '/') {
    swiperParams.pagination.type = 'custom';
    swiperParams.pagination.renderCustom = (swiper, current, total) => {
        let text = '<div class="swiper-pagination-bullets home">';
        for (let i = 1; i <= total; i++) {
            if (current == i) {
                text += `
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="banner-current-slide">
                  <path id="arrow" d="M14.176 10L13 11.175L16.8198 15L13 18.825L14.176 20L19.18 15L14.176 10Z" fill="white"/>
                    <circle id="circle" cx="15" cy="15" r="14" stroke="white" stroke-width="2" stroke-dasharray="90">
                      <animate
                            attributeType="XML"
                            attributeName="stroke-dashoffset"
                            from="90"
                            to="0"
                            dur="${dynamicParams.autoplay.delay + 500}ms"
                      ></animate>
                  </circle>
                </g>
              </svg>
          `;
            } else {
                text += "<span class='swiper-pagination-bullet'> </span>";
            }
        }
        text += "</div>";
        return text;
    }
    // }

    useEffect(() => {
        if (props.showThumbnails) {
            const gallerySwiper = gallerySwiperRef.current.swiper;
            const thumbnailSwiper = thumbnailSwiperRef.current.swiper;

            if (gallerySwiper.controller && thumbnailSwiper.controller) {
                gallerySwiper.controller.control = thumbnailSwiper;
                thumbnailSwiper.controller.control = gallerySwiper;
            }
        }
    }, []);

    const swiperSizesBySlidersPerView = {
        1: '90% !important',
        2: '45% !important',
        3: '30% !important',
        4: '20% !important',
        5: '15% !important',
        6: '10% !important',
    }

    const useStyles = makeStyles((theme) => ({
        root: {
        },
        swiperMainContainer: {
            '& .swiper-slide': {
                width: dynamicParams.slidesPerView === 1 ? '100%' : 'auto',

            },
            '& .swiper-slide-active': {
                width: dynamicParams.showNext ? swiperSizesBySlidersPerView[dynamicParams.slidesPerView] : 'auto'
            },
            '& .swiper-pagination-custom': {
                bottom: dynamicParams.bullets?.bottom || '0px',
            },
            '& .swiper-pagination-bullets': {
                '&.home': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                bottom: dynamicParams.bullets?.bottom || '0px',
                '& .swiper-pagination-bullet': {
                    height: '12px',
                    width: '12px',
                    margin: '0 20px',
                    cursor: 'pointer',
                    opacity: '1',
                    backgroundColor: 'transparent',
                    '&::after': {
                        content: '""',
                        display: 'block',
                        width: '6.66px',
                        height: '6.66px',
                        opacity: 0.7,
                        backgroundColor: '#ff367d', // '#AF0007',
                        borderRadius: '50%'
                    }
                },
                '& .swiper-pagination-bullet-active': {
                    width: 30,
                    height: 30,
                    transform: 'translateY(35%)',
                    // backgroundColor: 'transparent',
                    // backgroundImage: `url(${currentSlideIcon})`,
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat',
                    opacity: 1,
                }
            },
            '& .swiper-button-prev, & .swiper-button-next': {
                color: '#FFFFFF !important',
                width: '48px !important',
                height: '48px !important',
                transition: 'color .3s',
            },
            '& .swiper-button-prev:hover, & .swiper-button-next:hover': {
                color: '#db1842 !important'
            },
            '& .swiper-button-prev': {
                transform: 'scale(0.7) translateX(100px)',
                [theme.breakpoints.down('sm')]: {
                    transform: 'scale(0.7) translateX(0)',
                }
            },
            '& .swiper-button-disabled': {
                display: 'none',
            },
            '& .swiper-button-next': {
                transform: 'scale(0.7) translateX(-100px)',
                [theme.breakpoints.down('sm')]: {
                    transform: 'scale(0.7) translateX(0)',
                }
            }
        },
        swiperMainSlide: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        },
        swiperThumbnailContainer: {
            position: 'relative',
            marginTop: theme.spacing(-5),
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(-1),
            },
            '& .swiper-slide': {
                borderRadius: '10px',
                opacity: 0.8,
                cursor: 'pointer',
                transform: 'scale(0.9)',
                transition: 'all 0.3s ease'
            },
            '& .swiper-slide-active': {
                opacity: 1,
                transform: 'scale(1)'
            }
        },
        swiperThumbnailItem: {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100px',
            width: '200px',
            [theme.breakpoints.down('xs')]: {
                height: '50px',
                width: '100px'
            },
        }
    }));

    const classes = useStyles();

    return (
        <Box
            {...getBoxMarginProps(props, breakpoint)}
            style={{
                backgroundImage: getBreakpointBackgroundImage(props, breakpoint),
                backgroundSize: getBreakpointValue(props, breakpoint, 'background', 'size'),
                backgroundColor: getColor(getBreakpointValue(props, breakpoint, 'background', 'color')),
                backgroundRepeat: getBreakpointValue(props, breakpoint, 'background', 'repeat'),
                backgroundPosition: getBreakpointValue(props, breakpoint, 'background', 'position'),
            }}
        >
            <Box className={classes.swiperMainContainer}>
                <Swiper
                    {...swiperParams}
                    ref={gallerySwiperRef}
                >
                    {descendants.map((descendant) => {
                        let newNode = JSON.parse(query.serialize());
                        Object.entries(newNode).map((item) => {
                            let key = item[0];
                            let value = item[1];
                            if (key == 'ROOT') {
                                newNode.ROOT.nodes = [descendant];
                            }
                            if (value.parent == 'ROOT') {
                                delete newNode[key];
                            }
                        })

                        return (
                            <div>
                                <ContentView json={newNode} />
                            </div>
                        );
                    })}
                </Swiper>

                {props.showThumbnails && (
                    <Box className={[classes.swiperThumbnailContainer, 'swiperThumbnailContainer']}>
                        <Swiper {...thumbnailSwiperParams} ref={thumbnailSwiperRef} >
                            {descendants.map((descendant) => {
                                let newNode = JSON.parse(query.serialize());
                                let thumbImageUrl = getBreakpointBackgroundImage(newNode[descendant].props, breakpoint)

                                return (
                                    <div key={`slide_thumbnail_${descendant}`} className={classes.swiperThumbnailItem} style={{
                                        backgroundImage: thumbImageUrl,
                                    }} />
                                )
                            })}
                        </Swiper>
                    </Box>
                )}
            </Box>

        </Box>
    )
};

export const SliderBannerCustomItem = (props) => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

    return (
        <Box
            display="flex"
            style={{
                backgroundImage: getBreakpointBackgroundImage(props, breakpoint),
                backgroundSize: getBreakpointValue(props, breakpoint, 'background', 'size'),
                backgroundColor: getColor(getBreakpointValue(props, breakpoint, 'background', 'color')),
                backgroundRepeat: getBreakpointValue(props, breakpoint, 'background', 'repeat'),
                backgroundPosition: getBreakpointValue(props, breakpoint, 'background', 'position'),
                height: getHeight(props, breakpoint),
            }}
        >
            <Box
                {...getBoxPaddingProps(props, breakpoint)}
                display="flex"
                justifyContent={getBreakpointValue(props, breakpoint, 'justifyContent')}
                alignItems={getBreakpointValue(props, breakpoint, 'alignItems')}
                flexDirection={getBreakpointValue(props, breakpoint, 'flexDirection')}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                {props.children}
            </Box>
        </Box>
    )
};
