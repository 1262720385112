import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { formatPrice, getPixOffer } from 'src/utils/functions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    textAlign: 'center',
    padding: '8px',
    marginInline: 'auto',
    marginBottom: '16px',
    maxWidth: '400px'
  },
  discount: {
    fontSize: 9,
    fontWeight: 800,
    textTransform: 'uppercase',
    color: '#000000',
    backgroundColor: '#39d950',
    borderRadius: 4,
    margin: '0 auto',
    width: 'max-content',
    padding: '5px 10px'
  },
  highlight: {
    fontSize: '16px',
    fontWeight: 800,
    color: '#ff367d'
  },
  pixValue: {
    fontSize: '16px',
    fontWeight: 800,
    color: '#0085f8',
  },
  description: {
    fontWeight: 800,
    color: '#000000',
    marginBottom: '2px'
  },
  or: {
    alignSelf: 'center',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  }
}));

export default function PaymentOptions({ order }) {
  const classes = useStyles();
  const store = useSelector(state => state.interface.store);
  const pixOffer = getPixOffer(order.salePriceOffers, store.paymentOptions);

  const installments =
    order.installments && order.installments.length > 0
      ? {
          quantity: order.installments.length,
          value: order.installments[order.installments.length - 1].amount,
          total: order.installments[order.installments.length - 1].total
        }
      : null;

  return (
    <Box className={classes.root}>
      {pixOffer && (
        <Box>
          <Typography className={classes.pixValue}>
            {formatPrice(pixOffer.totalFinal)}
          </Typography>
          <Typography className={classes.description}>
            à vista no Pix
          </Typography>
          <Box className={classes.discount}>
            {pixOffer.discount} de desconto
          </Box>
        </Box>
      )}
      {pixOffer && installments && (
        <Typography className={classes.or}>ou</Typography>
      )}
      {installments && (
        <Box className={classes.total}>
          <Typography className={classes.highlight}>
            {installments.quantity}x de {formatPrice(installments.value)}
          </Typography>
          <Typography className={classes.description}>
            Total {formatPrice(installments.total)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
