import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    makeStyles,
    Typography,
    Box,
    Container,
    Grid,
    Input,
    TextField,
    Button,
    FormControl,
    FormHelperText,
    darken,
} from '@material-ui/core';
import InputMask from 'react-input-mask';

import ManagerApi from 'src/services/managerApi';
import { addMessage, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import { trackDataLayer } from 'src/utils/analytics';

const useStyles = makeStyles((theme) => ({

    btnHome: {
        backgroundColor: '#ff367d !important',
        '&:hover': {
            backgroundColor: `${darken('#db1842', 0.1)} !important`,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100% !important'
        }
    },
    bigTitleNewsletter: {
        color: '#FFFFFF',
        textAlign: 'center',
        marginBottom: '-35px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '-16px',
        },
    },
    textNewsletter: {
        color: '#777777',
        marginBottom: '30px',
    },
    controlNewsletter: {
        width: '100%'
    },
    inputNewsletter: {
        color: '#777777',
        width: '100%'
    },
    newsletterBox: {
        justifyContent: 'center'
    }

}));

const interestList = [
    {
        "value": "Notebook Gamer",
        "label": "Notebook Gamer"
    },
    {
        "value": "Notebook para Arquitetura",
        "label": "Notebook para Arquitetura"
    },
    {
        "value": "Notebook para Engenharia",
        "label": "Notebook para Engenharia"
    },
    {
        "value": "Notebook para Design",
        "label": "Notebook para Design"
    },
    {
        "value": "Notebook para Audiovisual/Fotografia",
        "label": "Notebook para Audiovisual/Fotografia"
    },
    {
        "value": "Notebook para Odontologia",
        "label": "Notebook para Odontologia"
    },
    {
        "value": "Notebook Office",
        "label": "Notebook Office"
    },
];

export default function Newsletter() {
    const dispatch = useDispatch();
    const [translate, i18n] = useTranslation();
    const [errorsBackend, setErrorsBackend] = React.useState([]);

    const newsletterValidationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(translate('errorRequiredField')),
        email: Yup.string().email(translate('errorEmailField')).max(255).required(translate('errorRequiredField')),
        interest: Yup.string().required(translate('errorRequiredField')),
        phone: Yup.string().required(translate('errorRequiredField')),
    });

    async function handleSubscribeNewsletter(values, resetForm) {

        try {

            if (values.interest) {
                values.area_de_interesse = values.interest;
                delete values.interest;
            }

            const requestId = uuidv4();

            dispatch(addLoadingGlobal(requestId));

            new ManagerApi('/customer/newsletter').post(values)
                .then((response) => {
                    if (response.data.success) {
                        dispatch(addMessage(translate('alertNewsletterSubscribedSuccessfully'), 'success'));
                        resetForm();
                        trackDataLayer({
                            'customer': values.email
                        })
                    } else if (!response.data.success) {
                        setErrorsBackend(response.data.errors)
                    }
                }).catch(error => {
                    dispatch(addMessage(error.response.data.message, 'error'));
                }).finally(() => {
                    dispatch(removeLoadingGlobal(requestId));
                });
        } catch { }

    }

    const classes = useStyles();

    return (
        <Container maxWidth={false} className="hide-on-print">
            <Grid
                container
                direction="row"
                justifyContent="center"
            >
                <Grid item md={8} sm={12} xs={12}>
                    <Box>
                        <Typography className={classes.bigTitleNewsletter} variant="overline">
                            newsletter
                        </Typography>
                        <Typography variant="subtitle1" className={classes.subtitleNewsletter}>
                            SAIBA DAS NOSSAS NOVIDADES ANTES DE TODO MUNDO
                        </Typography>
                        <Typography variant="body2" className={classes.textNewsletter}>
                            Fique por dentro das nossas novidades, ofertas e promoções, cadastrando-se abaixo:
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                    <Formik
                        initialValues={{ name: '', email: '', interest: '', phone: '' }}
                        enableReinitialize
                        validationSchema={newsletterValidationSchema}
                        onSubmit={async (values, { resetForm }) => handleSubscribeNewsletter(values, resetForm)}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit} >
                                    <Grid container className={classes.newsletterBox} spacing={1}>
                                        <Grid item md={3} sm={12} xs={12}>
                                            <FormControl className={classes.controlNewsletter}>
                                                <TextField
                                                    name="interest"
                                                    value={values.interest}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder="Área de Interesse"
                                                    error={Boolean(touched.interest && errors.interest)}
                                                    helperText={touched.interest && errors.interest}
                                                    displayEmpty
                                                    className={classes.inputNewsletter}
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                    select
                                                >
                                                    <option value="">Área de Interesse</option>

                                                    {interestList.map((item) => (
                                                        <option value={item.value}>{item.label}</option>
                                                    ))}

                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={3} sm={12} xs={12}>
                                            <FormControl className={classes.controlNewsletter}>
                                                <TextField
                                                    placeholder="Meu nome é:"
                                                    className={classes.inputNewsletter}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={3} sm={12} xs={12}>
                                            <FormControl className={classes.controlNewsletter}>
                                                <TextField
                                                    placeholder="Meu e-mail é:"
                                                    className={classes.inputNewsletter}
                                                    error={Boolean(touched.email && errors.email)}
                                                    helperText={touched.email && errors.email}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                />
                                                {errorsBackend.email &&
                                                    <FormHelperText error>
                                                        {errorsBackend.email}
                                                    </FormHelperText>
                                                }
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={3} sm={12} xs={12}>
                                            <FormControl className={classes.controlNewsletter}>
                                                <InputMask
                                                    mask="(99)99999-9999"
                                                    maskChar={false}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.phone}
                                                >
                                                    {() => (
                                                        <TextField
                                                            error={Boolean(touched.phone && errors.phone)}
                                                            helperText={touched.phone && errors.phone}
                                                            className={classes.inputNewsletter}
                                                            fullWidth
                                                            name="phone"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.phone}
                                                            placeholder="Meu whatsapp é:"
                                                        />
                                                    )}
                                                </InputMask>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={2} sm={12} xs={12}>
                                            <Button
                                                className={classes.btnHome + " btn btn-sm"}
                                                disabled={isSubmitting}
                                                type="submit"
                                                style={{ maxWidth: '100%' }}
                                            >
                                                CADASTRAR
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </Container>
    )
}
