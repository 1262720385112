import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Chip,
    Link,
    Typography,
    Button,
} from '@material-ui/core';

import { ProductDetails } from './ProductDetails';
import { ProductActions } from './ProductActions';
import { useTheme } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import { ProductPrice } from './ProductPrice';
import clsx from 'clsx';
import { Footer } from './Footer';
import useSeals from 'src/hooks/useSeals';
import { getDefaultProductPrices } from 'src/utils/functions';

import LazyLoad from 'react-lazy-load';
import MobileProductCard from './MobileCard';

const useStyles = makeStyles((theme) => ({
    '@keyframes fadeIn': {
        from: {
            opacity: 0.2,
        },
        to: {
            opacity: 1,
        }
    },
    link: {
        color: '#000',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    container: {
        height: '100%',
        width: '100%',
        position: 'relative',
        minHeight: 500,
        [theme.breakpoints.down('md')]: {
            minHeight: 490,
        }
    },
    card: {
        backgroundColor: 'transparent',
        minHeight: 500,
        borderRadius: 5,
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: 450,
        position: 'relative',
        textDecoration: 'none',
        transition: 'background 0.2s ease',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
    media: {
        width: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
        height: 'auto',
        maxHeight: '195px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    productName: {
        color: '#333333',
        overflow: 'hidden',
        textAlign: 'center',
        height: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '18px',
            marginInline: '16px',
        },
    },
    discountTag: {
        backgroundColor: '#0085f8',
        color: '#fff',
        fontWeight: 500,
        top: '16px',
        right: '16px',
        zIndex: 1,
        position: 'absolute',
        ...theme?.components?.productCard?.discountTag,
        [theme.breakpoints.down('md')]: {
            top: '8px',
        },
    },
    chipsContainer: {
        top: '5px',
        right: '5px',
        zIndex: 1,
        position: 'absolute'
    },
    priceFrom: {
        fontSize: 11,
        fontWeight: 800,
        textTransform: 'uppercase',
        color: '#0085f8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ' &:hover': {
            color: '#DB1842',
            backgroundColor: 'transparent'
        }
    },
    iconCart: {
        marginRight: 10
    },
    simpleVersion: {
        backgroundColor: '#ffffff',
        padding: theme.spacing(2),
        minHeight: 490,
        maxWidth: '100%',
        [theme.breakpoints.down('md')]: {
            minHeight: '100%',
        }
    },
    advacedVersion: {
        '&:hover': {
            [theme.breakpoints.up('md')]: {
                animation: '$fadeIn 0.5s ease-out both',
                filter: 'drop-shadow(0px 60px 100px rgba(0, 0, 0, 0.2))',
                backgroundColor: '#ffffff',
                position: 'absolute',
                zIndex: 2,
                top: 0
                // top: '50%',
                // transform: 'translateY(-50%)',
            }
        }
    },
    hide: {
        display: 'none'
    },
    seal1: {
        width: '100%',
    }
}));

function ProductCard({ product, isReview, addReview, order, isProductView, fixedSimpleVersion = false, mobileVariant = 'vertical' }) {

    const store = useSelector((state) => state.interface.store);

    const [isHovering, setIsHovering] = useState(false);

    const theme = useTheme();

    const mobileSize = (useMediaQuery(theme.breakpoints.down('sm')));

    const simpleVersion = fixedSimpleVersion || mobileSize;

    const classes = useStyles();

    const seals = useSeals(product);

    const hasDiscount = () => {
        let response = false;

        if (product.saleable) {
            if (product.finalPrice !== product.price) {
                response = true;
            }
        }

        return response;
    }

    const getDiscountTag = () => {
        if (!hasDiscount()) {
            return (<></>);
        }

        const { price, finalPrice } = getDefaultProductPrices(product)

        let label = "-" + ((1 - (finalPrice / price)) * 100).toFixed(0).toString().replace(".", ",") + "%";
        return (
            <Chip label={label} className={classes.discountTag} color="primary" />
        );
    }

    const productImage = {
        initial: store.productPlaceholder,
        hover: store.productPlaceholder
    };

    if (product.medias && product.medias.length) {
        productImage.initial = product.medias[0].url;
        productImage.hover = product.medias.length > 1 ? product.medias[1].url : product.medias[0].url;
    }

    // productImage.initial = "https://placehold.co/1000x1000";
    // productImage.hover = "https://placehold.co/1000x1000";

    function handleActiveCard() {
        setIsHovering(true);
    }

    function handleInactiveCard() {
        setIsHovering(false);
    }

    if (mobileSize || fixedSimpleVersion) {
        return <MobileProductCard product={product} variant={mobileVariant} />;
    }

    return (
        <Link
            to={isReview ? false : product.url}
            className={classes.link}
            component={RouterLink}
        >
            <Box
                className={classes.container}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    className={clsx([classes.card],
                        {
                            [classes.advacedVersion]: !simpleVersion,
                            [classes.simpleVersion]: simpleVersion,
                        })}
                    onMouseOver={handleActiveCard}
                    onMouseLeave={handleInactiveCard}
                >
                    <Box>
                        <Box>
                            <Box>
                                <Typography className={classes.productName} gutterBottom variant="h3" component={Box} mt={5}>
                                    {product.name}
                                </Typography>
                                <LazyLoad
                                    height={195}
                                    threshold={0.95}
                                >
                                    <img
                                        src={isHovering ? productImage.hover : productImage.initial}
                                        alt={product.name}
                                        title={product.name}
                                        className={classes.media}
                                    />
                                </LazyLoad>
                            </Box>

                            {seals.seal1?.image ? (
                                <img src={seals.seal1.image} className={classes.seal1} />
                            ) : null}

                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                style={{
                                    height: isHovering && !simpleVersion ? '100%' : '100%'
                                }}
                            >
                                <ProductDetails
                                    product={product}
                                />

                                <Box
                                    className={clsx({
                                        [classes.hide]: !isHovering || simpleVersion,
                                    })}
                                >
                                    <Footer
                                        product={product}
                                        isReview={isReview}
                                        hasDiscount={hasDiscount()}
                                    />
                                </Box>

                                <Box
                                    className={clsx({
                                        [classes.hide]: isHovering && !simpleVersion,
                                    })}
                                >
                                    {product.stock > 0 ? (
                                        <ProductPrice
                                            isReview={isReview}
                                            product={product}
                                            classes={classes}
                                        />
                                    ) : null}

                                    {simpleVersion && <ProductActions showButtonDetails={false} product={product} hasDiscount={hasDiscount()} />}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {getDiscountTag()}
                    {isReview && (
                        <Box className={classes.buttonRate}>
                            <Button fullWidth variant='outlined' color='primary' onClick={() => addReview(order[0].id, product.id)}>
                                Avaliar
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Link>
    )
}

ProductCard.propTypes = {
    product: PropTypes.object.isRequired
};

export default React.memo(ProductCard);
