import React from 'react';
import { useDispatch } from 'react-redux';
import { addLoadingGlobal, removeLoadingGlobal, addMessage, fetchCart, updateCart } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import { v4 as uuidv4 } from 'uuid';
import {
    Plus as PlusIcon,
    Check as CheckIcon,
    X as XIcon,
} from 'react-feather';
import {
    Box,
    FormControl,
    Button,
    Typography,
    makeStyles,
    TextField,
    IconButton
} from '@material-ui/core';
import { formatPrice } from 'src/utils/functions';

const useStyles = makeStyles((theme) => ({
    formControl: {
        flexDirection: 'row',
        marginTop: theme.spacing(1)
    },
    inputControl: {
        width: '100%',
        marginRight: '8px',
    },
    cupomApplied: {
        color: '#db1842',
        fontWeight: 400,
        whiteSpace: 'nowrap'
    },
    sectionTitle: {
        fontWeight: 800,
        color: '#333333',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 180
        }
    },
    coupomBtn: {
        minWidth: 50,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'initial',
        },
    },
    coupomForm: {
        flexDirection: 'column',
        position: 'relative',
        display: 'flex'
    },
    coupomFormLarge: {
        width: '100%',
        position: 'relative',
        display: 'flex',
    },
    sectionTitle: {
        fontSize: '16px',
        fontWeight: '800',
        color: '#333333',
        fontFamily: 'Gilroy-ExtraBold',
    }
}));

export default function DiscountCoupon({ order, showDiscountValue = false }) {
    const [showInputCoupom, setShowInputCoupom] = React.useState(!!order.coupon);

    async function handleRemoveDiscountCoupon() {
        const requestId = uuidv4();
        dispatch(addLoadingGlobal(requestId));
        const response = await updateDiscountCoupon('delete');
        dispatch(removeLoadingGlobal(requestId));

        if (response.data.success) {
            dispatch(addMessage('Cupom removido com sucesso', 'success'));
            setShowInputCoupom(false)
            dispatch(updateCart(response.data.content));
        }
    }

    async function handleUpdateDiscountCoupon(coupon) {
        const requestId = uuidv4();

        try {
            dispatch(addLoadingGlobal(requestId));

            const response = await updateDiscountCoupon(coupon);

            if (response) {
                if (!response.data?.content?.coupon) {
                    dispatch(addMessage('Cupom inválido ou expirado', 'error'));
                    return;
                }
                dispatch(addMessage('Cupom adicionado com sucesso', 'success'));
                dispatch(updateCart(response.data.content));
            }

        } catch (error) {
            dispatch(addMessage(error.response.data.message, 'error'));

        } finally {
            dispatch(removeLoadingGlobal(requestId));

        }

    }

    async function updateDiscountCoupon(coupon) {
        const saleOrderApi = new ManagerApi('/sale/order');
        const response = await saleOrderApi.put(order.id, {
            coupon
        });

        if (response.data && !response.data.success) {
            if (response.data.message && response.data.message.indexOf('not found') === -1) {
                dispatch(addMessage(response.data.message, 'error'));
            }
            return false;
        }

        return response;
    }

    const dispatch = useDispatch();

    const classes = useStyles();

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography className={classes.sectionTitle}>Você tem cupom?</Typography>
                {showInputCoupom ? <></> :
                    <IconButton size="small" onClick={() => { setShowInputCoupom(!showInputCoupom) }} className={classes.coupomBtn}>
                        <>
                            <PlusIcon size="20px"></PlusIcon>
                        </>
                    </IconButton>
                }
            </Box>
            <form onSubmit={e => {
                e.preventDefault();
                handleUpdateDiscountCoupon(e.target.coupon.value)
            }} className={classes.coupomForm}>
                {showInputCoupom && (
                    <FormControl className={classes.formControl}>
                        <TextField
                            fullWidth
                            name="coupon"
                            value={order.coupon}
                            className={classes.inputControl}
                        />
                        {order.coupon ? (
                            <IconButton
                                size="small"
                                type="button"
                                onClick={handleRemoveDiscountCoupon}
                                className={classes.coupomBtn}
                            >
                                <XIcon size="20px"></XIcon>
                            </IconButton>
                        ) : (
                            <>
                                <IconButton size="small" type="submit" className={classes.coupomBtn}>
                                    <CheckIcon size="20px"></CheckIcon>
                                </IconButton>
                                <IconButton
                                    size="small"
                                    type="button"
                                    onClick={() => { setShowInputCoupom(!showInputCoupom) }}
                                    className={classes.coupomBtn}
                                >
                                    <XIcon size="20px"></XIcon>
                                </IconButton>
                            </>
                        )}
                    </FormControl>
                )}
            </form>
            {showDiscountValue && (
                <Box display="flex">
                    {(order.discountCoupon && order.discountCoupon.discountType == 'percentual') &&
                        <Typography className={classes.cupomApplied}>Desconto aplicado de
                            {order.discountCoupon.discountType == 'percentual' ? (
                                <> - {parseInt(order.discountCoupon.value)}%</>
                            ) : (
                                <> - {formatPrice(order.discountCoupon.value)}</>
                            )}
                        </Typography>
                    }
                </Box>
            )}
        </Box>
    )
}
