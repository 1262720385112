import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Typography,
    IconButton,
    ButtonGroup,
    Button
} from '@material-ui/core';
import {
    Plus as PlusIcon,
    Minus as MinusIcon
} from 'react-feather';
import { deleteItemCart, updateItemCart } from 'src/actions';
import DialogComponent from 'src/components/Dialog/Dialog';
import deleteIcon from 'src/theme/scss/icons/delete.svg';
import arrowIcon from 'src/theme/scss/icons/blueArrow.svg';
import ConfigurationList from 'src/layouts/Catalog/Product/Bundle/ConfigurationList';
import Price from 'src/layouts/Catalog/Product/Bundle/Price';
import { AddonList } from 'src/layouts/CheckoutLayout/Cart/AddonList';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: '15px 0'
    },
    infoContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            display: "block",
        }
    },
    productMedia: {
        width: '80px;',
        height: '80px;',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    btnGroupQty: {
        marginRight: 20
    },
    btnQty: {
        fontFamily: 'Gilroy-ExtraBold',
        fontWeight: 100,
        border: 0,
        backgroundColor: '#f4f4f4',
        padding: '7px 12px',
        minWidth: 'auto'
    },
    settingsButton: {
        fontFamily: 'Manrope',
        fontSize: 12,
        textTransform: 'none',
        color: '#0084F4',
        padding: '4px 0 12px',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
}));

export default function CartItem({ item, items, editCart = true }) {
    const [translate] = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [stateDialog, setStateDialog] = useState(false);
    const [idItem, setIdItem] = useState('');
    const [openSettings, setOpenSettings] = useState(false);
    const store = useSelector((state) => state.interface.store);
    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const handleDeleteItemCart = (id) => {
        setIdItem(id);
        setStateModal();

        if (stateDialog)
            dispatch(deleteItemCart(idItem));
    };

    const setStateModal = () => {
        stateDialog ? setStateDialog(false) : setStateDialog(true);
    };

    const handleClick = (e) => {
        let newCount = item.quantity

        if (e.currentTarget.id === "increment") {
            newCount++;
        }
        else {
            newCount--;
            if (newCount == 0) {
                handleDeleteItemCart(item);
                return;
            }
        }
        dispatch(updateItemCart(item, newCount, [], item.addons));
    };

    let productImage = store.productPlaceholder;
    if (item.mediaUrl !== '') {
        productImage = item.mediaUrl;
    }

    let buttonDisabled = false;
    if (item.type == 'addon') {
        buttonDisabled = true;
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.infoContainer}>
                <Box display='flex'>
                    <Box display='flex' mr={2}>
                        <Box className={classes.productMedia} style={{ backgroundImage: 'url("' + productImage + '")' }} />
                    </Box>
                    <Box display="flex" alignItems="center" mr={2}>
                        <Typography variant="h6">{item.name}</Typography>
                    </Box>
                </Box>
                <Box display='flex' justifyContent='space-between'>
                    <Box display="flex" alignItems={mobileSize ? "flex-start" : "center"} flexDirection={mobileSize ? "column" : "row"}>
                        {editCart ? (
                            <>
                                <ButtonGroup size="small" aria-label="outlined primary button group" className={classes.btnGroupQty}>
                                    <Button
                                        id='decrement'
                                        onClick={handleClick}
                                        className={classes.btnQty}
                                        disabled={buttonDisabled}
                                    >
                                        <MinusIcon size='14px' />
                                    </Button>
                                    <Button className={classes.btnQty} disabled={buttonDisabled}>{item.quantity}</Button>
                                    <Button
                                        id='increment'
                                        onClick={handleClick}
                                        className={classes.btnQty}
                                        disabled={buttonDisabled}
                                    >
                                        <PlusIcon size='14px' />
                                    </Button>
                                </ButtonGroup>
                            </>
                        ) : (<Typography variant="caption" color="textSecondary"> {item.quantity} </Typography>)}
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Price item={item} items={items} />
                        {editCart ? (
                            <Box>
                                <IconButton className={classes.deleteItemCart} onClick={() => handleDeleteItemCart(item)}>
                                    <img src={deleteIcon} alt="Remover" />
                                </IconButton>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                {stateDialog ?
                    <DialogComponent
                        state={stateDialog}
                        title=''
                        text={translate('textAskConfirmDeleteAction')}
                        buttonClose={translate('buttonCancel')}
                        buttonSubmit={translate('buttonConfirm')}
                        handleClose={() => setStateModal()}
                        handleSubmit={() => handleDeleteItemCart()}
                    /> : null}
            </Box>
            {item.type == 'bundle' && (
                <Box
                    mt={2}
                >
                    <Button className={classes.settingsButton} onClick={() => setOpenSettings(!openSettings)}>
                        Ver detalhes do produto
                        <img src={arrowIcon} style={{ marginLeft: 10 }} />
                    </Button>
                    {openSettings && (<ConfigurationList item={item} items={items} />)}
                </Box>
            )}
            <AddonList
                item={item}
                items={items}
            />
        </Box>
    )
}
