import React from 'react';
import { Helmet } from 'react-helmet';

export function GoogleAnalytics({ analyticsId, customer }) {
    if (!analyticsId) {
        return (<></>);
    }

    window.hasGAnalytics = 1;

    return (
        <Helmet>
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
            />
            <script>
            {`
                window.dataLayer = window.dataLayer || [];
    
                function gtag() {
                    dataLayer.push(arguments);
                }
    
                gtag('js', new Date());

                ${
                    customer 
                    ? `gtag('config', '${analyticsId}', {send_page_view: false, user_id: '${customer.id}'});` 
                    : `gtag('config', '${analyticsId}', {send_page_view: false});`
                }
            `}
            </script>
        </Helmet>
    );
}
