import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
} from '@material-ui/core';
import { clearCart, fetchFavorites, loadFavorites, loadLocalStorageFavorites } from 'src/actions';
import TopBar from 'src/layouts/MainLayout/Topbar/TopBar';
import Footer from 'src/layouts/MainLayout/Footer';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f4f4f4',
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    contentContainer: {
        display: 'fle;x',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: '118px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '64px',
        },
    },
    contentHome: {
        flex: '1 1 auto',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'
    }
}));

function MainLayout({ children }) {
    const isHomePage = window.location.pathname === '/';
    const classes = useStyles();
    const dispatch = useDispatch();
    const store = useSelector((state) => state.interface.store);
    const cart = useSelector((state) => state.cart);

    useEffect(_ => {
        dispatch(loadLocalStorageFavorites());
        dispatch(loadFavorites());
    }, [])

    if (cart.order.status !== 'new') {
        dispatch(clearCart());
    };

    return (
        <div className={classes.root + ' print-container'}>
            <TopBar className="hide-on-print" />
            <div className={clsx(classes.wrapper, (store.interface.hasCategoriesMenu ? classes.wrapperTwoLines : null))}>
                <div className={classes.contentContainer}>
                    <div id="page-content" className={isHomePage ? classes.contentHome : classes.content}>
                        {children}
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

MainLayout.propTypes = {
    children: PropTypes.any
};

export default MainLayout;
