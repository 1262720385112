import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    makeStyles,
    useTheme,
    useMediaQuery,
    Grid,
    Container
} from '@material-ui/core';
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import logo from 'src/theme/scss/icons/logoNewBlack.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        background: '#ffffff !important',
        position: 'inherit'
    },
    checkoutHeader: {
        padding: '25px 0',
        borderBottom: '1px solid #F4F4F4'
    },
    checkoutBradcrumb: {
        display: 'flex',
        justifyContent: 'center'
    },
    logoImage: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            maxWidth: 120,
            margin: '0 10px'
        }
    },
    secureSite: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            maxWidth: 70
        }
    },
    reclameAqui: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            maxWidth: 70
        }
    }
}));

function CheckoutHeader({ className, textTypography, textBreadcrumb, linkToBreadcrumb, ...rest }) {
    const store = useSelector((state) => state.interface.store);
    const classes = useStyles();

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    return (
        <>
            <AppBar
                className={clsx(classes.root, className)}
                color="default"
                {...rest}
            >
                <Container maxWidth={false}>
                    <Box className={classes.checkoutHeader}>
                        <Grid
                            className={mobileSize ? classes.mobileContainer : null}
                            container
                            justify="center"
                            alignItems="center">
                            <Grid item>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%">
                                    <Box className={classes.logo}>
                                        <RouterLink to="/">
                                            <img
                                                alt={store.name}
                                                src={logo}
                                                className={classes.logoImage}
                                            />
                                        </RouterLink>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Box className={classes.checkoutBradcrumb}>
                    <Breadcrumb
                        textLink="Página Inicial"
                        textTypography={textTypography}
                        textBreadcrumb={textBreadcrumb}
                        linkToBreadcrumb={linkToBreadcrumb}
                    />
                </Box>
            </AppBar>
        </>
    );
}

CheckoutHeader.propTypes = {
    className: PropTypes.string
};

export default CheckoutHeader;
