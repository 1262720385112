import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import AgeNotification from 'src/components/AgeNotification';
import ScrollReset from 'src/components/ScrollReset';
import GlobalLoading from 'src/components/GlobalLoading';
import GlobalMessage from 'src/components/GlobalMessage';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import { useSelector } from 'react-redux';
import { Analytics } from './components/Analytics';
import { Meliuz } from './components/Meliuz';
import { Hotjar } from './components/Hotjar';
import { Rakuten } from './components/Rakuten';
import Utm from './components/Utm';
import ContentModals from './components/Modals';
import BottomNav from './components/BottomNav';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();

  const { settings } = useSettings();
  const store = useSelector((state) => state.interface.store);

  return (
    <Router history={history}>
      <Auth>
        <ThemeProvider theme={createTheme(settings, store.theme)}>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={2}>
                <ScrollReset />
                <Analytics />
                <Hotjar />
                <Rakuten />
                <Utm />
                <Meliuz />
                <BottomNav />
                <CookiesNotification />
                <AgeNotification />
                <ContentModals />
                <Routes />
                <GlobalLoading />
                <GlobalMessage />
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </Auth>
    </Router>
  );
}

export default App;
