import React, { useState } from 'react';
import {
    Typography
} from '@material-ui/core';
import { formatPrice, getCartItemPrice } from 'src/utils/functions.js';

export default function Price({ item, items }) {
    const price = getCartItemPrice(item, items);

    return (
        <Typography variant="h5">{formatPrice(price)}</Typography>
    )
}
