import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    Button,
    Checkbox,
    Grid,
    makeStyles
} from '@material-ui/core';

import { toggleComparison, toggleDialogComparison, addComparisonProduct } from 'src/actions';
import useFavorites from 'src/hooks/useFavorites';
import heart from 'src/theme/scss/icons/pink-heart.svg';
import heartChecked from 'src/theme/scss/icons/pink-heart-checked.svg';
import { Link as RouterLink } from "react-router-dom";
import { ButtonAddToCart } from './ButtonAddToCart';
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import NotifyMe from './NotifyMe';
import useSeals from 'src/hooks/useSeals';

const useStyles = makeStyles((theme) => ({
    favoriteButton: props => ({
        position: 'absolute',
        top: '16px',
        left: '8px',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }),
    detailsButton: {
        fontSize: '14px',
        fontWeight: '500',
        transition: 'color .2s',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        color: '#777777',
        flexBasis: 'initial',
        '& svg': {
            fontSize: '16px',
            transform: 'translate(0px, 1px)',
            transition: 'transform .2s'
        },
        '&:hover': {
            color: '#DB1842',
            '& svg': {
                transform: 'translate(4px, 1px)',
            }
        },
    },
    productActions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '32px',
        '& button': {
            fontFamily: 'Manrope',
            textTransform: 'none',
        },
    },
    unavailableProductActions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .buttons': {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            '& button': {
                width: '100%',
                fontSize: '16px',
                fontFamily: 'Manrope',
                textTransform: 'none',
            }
        },
        '& .comparisonBox': {
            marginTop: '0',
        },
    },
    sealsContainer: {
        position: 'absolute',
        top: '56px',
        right: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    seal2: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    seal3: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    }
}));

export function ProductActions({ product, showButtonDetails = true, hasDiscount }) {
    const classes = useStyles({ hasDiscount, saleable: product.saleable });

    // const comparisonProducts = useSelector((state) => state.interface.comparison);

    const { comparisonDialog, comparison: comparisonProducts } = useSelector((state) => state.interface);

    const [inComparison, setInComparison] = useState(false);

    const dispatch = useDispatch();

    const { addProductToFavorites, removeProductFromFavorite, browserHasSuport } = useFavorites();
    const localStorageFavoritesList = useSelector((state) => state.interface.localStorageFavorites);

    const [databaseFavoriteId, setDatabaseFavoriteId] = useState(null);

    const favorites = useSelector((state) => state.interface.favorites);

    const seals = useSeals(product);

    async function getIsInComparison() {
        const inComparison = comparisonProducts.find(comparisonProduct => comparisonProduct.id === product.id);
        setInComparison(!!inComparison);
    }

    const handleFavoriteProduct = async (event) => {
        event.preventDefault();
        if (databaseFavoriteId) {
            await removeProductFromFavorite(product.id)
        } else {
            await addProductToFavorites(product);
        }
    }

    const handleCompareProduct = async () => {
        dispatch(toggleComparison(product));
        dispatch(toggleDialogComparison());
    }

    function handleToggleComparisonDialog(event) {
        event.preventDefault();
        // if (comparisonProducts.length < 4) {
        dispatch(toggleComparison(product));
        // dispatch(addComparisonProduct(product));
        // }
        dispatch(toggleDialogComparison());
    }

    useEffect(_ => {

        if (Array.isArray(comparisonProducts)) {
            getIsInComparison();
        }

    }, [comparisonProducts]);

    useEffect(_ => {
        if (!browserHasSuport) {
            const favoriteData = localStorageFavoritesList.find(fav => fav === product.id);

            if (favoriteData) {
                setDatabaseFavoriteId(favoriteData)
            } else {
                setDatabaseFavoriteId(null)
            }

        }
    }, [localStorageFavoritesList, browserHasSuport, product]);

    useEffect(_ => {
        if (browserHasSuport) {
            const favoriteData = favorites.find(fav => fav?.productId === product.id);

            if (favoriteData) {
                setDatabaseFavoriteId(favoriteData.id)
            } else {
                setDatabaseFavoriteId(null)
            }
        }
    }, [favorites, product]);

    return (
        <>
            {product.saleable ? (
                <Box className={classes.productActions}>
                    <ButtonAddToCart product={product} />
                    <Box className="comparisonBox">
                        <Checkbox
                            checked={inComparison}
                            onClick={handleToggleComparisonDialog}
                        />
                        <Button
                            onClick={handleToggleComparisonDialog}
                            className="btn btn-5"
                        >
                            COMPARAR
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box className={classes.unavailableProductActions}>
                    <Box className='buttons'>
                        <Button variant="contained" disabled fullWidth onClick={e => e.preventDefault()}>
                            Produto indisponível
                        </Button>
                        {product.type === 'simple' && <NotifyMe product={product.id} />}
                    </Box>
                    <Box className="comparisonBox">
                        <Checkbox
                            checked={inComparison}
                            onClick={handleToggleComparisonDialog}
                        />
                        <Button
                            onClick={handleToggleComparisonDialog}
                            className="btn btn-5"
                        >
                            COMPARAR
                        </Button>
                    </Box>
                </Box>
            )}

            <Button className={classes.favoriteButton} onClick={handleFavoriteProduct}>
                <img src={databaseFavoriteId ? heartChecked : heart} className="icon" />
            </Button>
            <Box className={classes.sealsContainer}>
                {seals.seal2?.image ? (<img src={seals.seal2.image} className={classes.seal2} />) : null}
                {seals.seal3?.image ? (<img src={seals.seal3.image} className={classes.seal3} />) : null}
            </Box>
        </>
    )
}
