import React from 'react';
import {
    Box,
    makeStyles,
    Typography,
    IconButton,
    Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
    X as CloseIcon,
} from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import { removeComparisonProduct, toggleComparison, toggleDialogComparison } from 'src/actions';
import useMobile from 'src/hooks/useMobile';

const useStyles = makeStyles((theme) => ({
    modalComparisonClose: {
        display: 'none'
    },
    modalComparisonOpen: {
        display: 'block'
    },
    modalComparison: {
        backgroundColor: '#fff',
        bottom: '0',
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63, 63, 68, 0.15)',
        display: 'flex',
        height: '100px',
        left: '50%',
        marginBottom: theme.spacing(3),
        maxWidth: '1760px',
        paddingLeft: theme.spacing(10),
        position: 'fixed',
        transform: 'translateX(-50%)',
        width: '100%',
        zIndex: 100,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        }
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        textAlign: 'left',
        borderTop: `5px solid ${theme.palette.primary.main}`,
        maxWidth: '396px',
        marginRight: '25px',
        minWidth: '215px',

    },
    productComparisonBox: {
        border: '2px dashed #c9c9c9',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '140px',
        width: '100%',
        height: '100%',
        maxHeight: '60px',
    },
    product: {
        position: 'relative',
        height: '100%',
        width: '100%',
        maxWidth: '140px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            maxHeight: '90px',
        }
    },
    removeProduct: {
        position: 'absolute',
        left: 0,
        top: 0
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1)
    },
    button: {
        '&:hover': {
            color: '#fff'
        }
    }
}));

export function ComparisonDialog() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { isMobile } = useMobile();
    const { comparisonDialog, comparison: comparisonProducts } = useSelector((state) => state.interface);

    function handleRemoveProduct(productId) {
        dispatch(removeComparisonProduct(productId));
    }

    function handleCloseComparisonDialog() {
        dispatch(toggleDialogComparison());
    }

    let classContainer = classes.modalComparisonClose;

    if (comparisonDialog && window.location.pathname != "/comparacao-produtos") {
        classContainer = classes.modalComparisonOpen;
    }

    return (
        <Box className={classContainer}>
            <Box className={classes.modalComparison}>
                {isMobile ? (
                    <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            ml={5}
                            style={{
                                height: '60px',
                                width: '60px',
                                borderRadius: '50%',
                                border: '2.5px solid #E4E4E4'
                            }}
                        >
                            <Typography variant="h2" style={{
                                color: "#333333",
                                fontSize: "20px",
                                fontWeight: 800
                            }}>
                                {comparisonProducts.length}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flex={1}
                            ml={3}
                        >
                            {comparisonProducts.length > 1 ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Button
                                        component={RouterLink}
                                        to="/comparacao-produtos"
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        onClick={handleCloseComparisonDialog}
                                        className={classes.button}
                                        style={{
                                            height: '50px'
                                        }}
                                    >
                                        Comparar Produtos
                                    </Button>
                                </Box>
                            ) : (
                                <Typography style={{
                                    color: "#333333",
                                    fontSize: "15px",
                                    fontWeight: 600
                                }}>
                                    Adicione mais um produto  para comparar
                                </Typography>
                            )}

                        </Box>
                        {/* <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleCloseComparisonDialog}
                            >
                                <CloseIcon size='12px' color="#C9C9C9" />
                            </IconButton> */}
                    </Box>
                ) : (
                    <>
                        <Box className={classes.title}>
                            <Typography
                                style={{
                                    fontSize: '26px',
                                    fontWeight: '700',
                                    color: '#333',
                                }}
                            >
                                Comparar notebooks
                            </Typography>
                            <Typography>
                                Você deve selecionar ao menos 2 produtos para comparar
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flex={1}
                            gridGap={40}
                        >
                            {comparisonProducts &&
                                [1, 2, 3, 4].map((item, index) => (
                                    comparisonProducts[index] ? (
                                        <Box key={index} className={classes.product}>
                                            <img
                                                src={comparisonProducts[index].image}
                                            />
                                            <IconButton
                                                aria-label="close"
                                                className={classes.removeProduct}
                                                onClick={() => handleRemoveProduct(comparisonProducts[index].id)}
                                                style={{
                                                    backgroundColor: '#fff'
                                                }}
                                            >
                                                <CloseIcon size='12px' color="#C9C9C9" />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <Box key={index} className={classes.productComparisonBox}>
                                            <Typography variant="h2" style={{
                                                color: "#c9c9c9",
                                                fontSize: "20px",
                                                fontWeight: 800
                                            }}>
                                                {item}
                                            </Typography>
                                        </Box>
                                    )
                                ))
                            }
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                width: '100%',
                                maxWidth: '396px'
                            }}
                        >
                            <Button
                                component={RouterLink}
                                to="/comparacao-produtos"
                                variant='contained'
                                color='primary'
                                disabled={comparisonProducts.length < 2}
                                onClick={handleCloseComparisonDialog}
                                className={classes.button}
                            >
                                Comparar Produtos
                            </Button>
                        </Box>
                        {/* <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={handleCloseComparisonDialog}
                            >
                                <CloseIcon size='12px' color="#C9C9C9" />
                            </IconButton> */}
                    </>
                )}
            </Box>
        </Box>
    );
}