import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton, makeStyles } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles(theme => ({
    root: {
        color: '#FFFFFF',
        // backgroundColor: '#76B900',
        // border: '2px solid #76B900',
        // borderRadius: '50%',
        height: '48px',
        width: '48px',
        transition: 'transform .3s',
        "& svg": {
            fill: '#25D366',
            height: '24px',
            width: '24px',
        }
    }
}));

function BottomNavWhatsApp() {
    const classes = useStyles();
    const store = useSelector((state) => state.interface.store);

    if (!store.contacts.whatsapp) {
        return null;
    }

    const href = 'https://wa.me/' + store.contacts.whatsapp.match(/\d+/g).join([]);

    return (
        <IconButton component="a" href={href} target="_blank" className={classes.root}>
            <WhatsAppIcon />
        </IconButton>
    );
}

export default BottomNavWhatsApp;
