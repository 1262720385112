import axios from 'axios';
import { WP_BLOG_URL, WP_FORUM_URL } from '../config';


axios.defaults.headers.post['Authorization'] = localStorage.getItem('accessToken')

export const apiWpBlog = axios.create({
  baseURL: WP_BLOG_URL,
});

export const apiWpForum = axios.create({
  baseURL: WP_FORUM_URL,
});

