import React, { useState } from 'react';

import {
    Box,
    Container,
    Grid,
    Button
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function CategoriesBlock() {
    const [openCategory, setOpenCategory] = useState('');

    const categories = useSelector((state) => state.interface.globalData.categoriesMenu);

    const professionalCategoryId = "62790877acb3a5863";
    const funCategoryId = "6273c9287201e8552";

    const professionalChildren = categories.filter((category) => (category.parent && category.parent.id) === professionalCategoryId);
    const funChildren = categories.filter((category) => (category.parent && category.parent.id) === funCategoryId);

    function handleToggleCategory(categoryId) {
        setOpenCategory(oldCategory => {
            return oldCategory === categoryId ? '' : categoryId;
        });
    }

    return (<></>);

    return (
        <Box id="home-categories-block" className="categories-block">
            <Container
                maxWidth={false}
                style={{ marginBottom: openCategory ? '180px' : '0px' }}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Grid item xs={12} md={4}>
                        <Box className="item-category">
                            <Button className="item-toggle" onClick={() => handleToggleCategory(professionalCategoryId)}>
                                <span className="border border1"></span>
                                <Box className="block-text">
                                    <span className="title">Para profissionais</span>
                                    <span className="text">Notebooks pensados por área de atuação</span>
                                </Box>
                            </Button>
                            {openCategory ===  professionalCategoryId && (
                                <div className="item-block">
                                    <span className="border border1"></span>
                                    <Container maxWidth={false}>
                                        <div className="items">
                                            {professionalChildren.length && professionalChildren.map(category => (
                                                <Button
                                                    key={category.id}
                                                    component={RouterLink}
                                                    className="item"
                                                    to={category.url}
                                                >
                                                    {category.name}
                                                </Button>
                                            ))}
                                        </div>
                                    </Container>
                                </div>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className="item-category">
                            <Button className="item-toggle" onClick={() => handleToggleCategory(funCategoryId)}>
                                <span className="border border2"></span>
                                <Box className="block-text">
                                    <span className="title">Para sua diversão</span>
                                    <span className="text">Notebooks ideiais para o uso cotidiano</span>
                                </Box>
                            </Button>
                            {openCategory === funCategoryId && (
                                <div className="item-block">
                                    <span className="border border2"></span>
                                    <Container maxWidth={false}>
                                        <div className="items">
                                            {funChildren.length && funChildren.map(category => (
                                                <Button
                                                    key={category.id}
                                                    component={RouterLink}
                                                    className="item"
                                                    to={category.url}
                                                >
                                                    {category.name}
                                                </Button>
                                            ))}
                                        </div>
                                    </Container>
                                </div>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className="item-category">
                            <Button
                                component={RouterLink}
                                to="/notebooks"
                                className="item-toggle"
                            >
                                <span className="border border3"></span>
                                <Box className="block-text">
                                    <span className="title">Todos os modelos</span>
                                    <span className="text">Conheça todos os modelos de notebooks Avell</span>
                                </Box>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}