import React from "react";

import {
	Box,
	Container as MaterialContainer,
	useTheme,
	useMediaQuery
} from "@material-ui/core";

import { getBoxMarginProps, getBreakpointBackgroundImage, getBreakpointValue, getColor } from "./functions";

export const Container = (props) => {
	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

	const boxProps = {
		...getBoxMarginProps(props, breakpoint),
		style: {
			backgroundImage: getBreakpointBackgroundImage(props, breakpoint),
			backgroundSize: getBreakpointValue(props, breakpoint, 'background', 'size'),
			backgroundColor: getColor(getBreakpointValue(props, breakpoint, 'background', 'color')),
			backgroundRepeat: getBreakpointValue(props, breakpoint, 'background', 'repeat'),
			backgroundPosition: getBreakpointValue(props, breakpoint, 'background', 'position')
		}
	};

	const position = getBreakpointValue(props, breakpoint, 'position');

	if (position && position.type) {
		boxProps.style.position = position.type;
		boxProps.style.zIndex = 2;
		if (position.top !== "") boxProps.style.top = Number(position.top);
		if (position.right !== "") boxProps.style.right = Number(position.right);
		if (position.bottom !== "") boxProps.style.bottom = Number(position.bottom);
		if (position.left !== "") boxProps.style.left = Number(position.left);
	}

	if (getBreakpointValue(props, breakpoint, 'hidden', null, false)) boxProps.style.display = 'none';

	let maxWidth = getBreakpointValue(props, breakpoint, 'maxWidth');

	if (maxWidth === 'full') maxWidth = false;

	if (props.id) {
		boxProps.id = props.id;
	}

	return (
		<Box
			{...boxProps}
		>
			<MaterialContainer disableGutters={maxWidth === false && breakpoint === 'xs'} maxWidth={maxWidth}>
				{props.children}
			</MaterialContainer>
		</Box>
	)
};