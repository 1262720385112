import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Box,
    Grid,
    Link,
    makeStyles,
    Typography
} from '@material-ui/core';
import { addProductCart, deleteItemCart } from 'src/actions';
import { getAddonPrice, getCartItemUnitPrice } from 'src/utils/functions';
import AddonListPrice from './AddonListPrice';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    addonContainer: {
    },
    addonTitle: {
        fileSize: '18px',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    addonText: {
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            '& a': {
                fontSize: '12px',
            }
        },
    },
    noAddonCard: {
        alignItems: 'center',
        display: 'flex',
        textAlign: 'center',
    },
    addonCard: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.06)',
        cursor: 'pointer',
        height: '100%',
        padding: '15px',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 16px',
        },
    },
    addonCardActive: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.06)',
        cursor: 'pointer',
        height: '100%',
        padding: '15px',
        outline: '4px solid #0084F4',
        [theme.breakpoints.down('sm')]: {
            padding: '8px 16px',
            outline: '2px solid #0084F4',
        },
    },
    addonCardTitle: {
        fontFamily: 'Manrope',
        fontSize: '12px',
        fontWeight: '800',
        color: '#ff367d', // '#0084F4',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
        },
    },
}));

export function AddonList({
    item,
    items
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [stateSelectedAddon, setStateSelectedAddon] = useState({});
    const [itemPrice, setItemPrice] = useState(item.price);

    const handleChangeAddon = (item, addon) => {
        const itemCartId = item.id;
        const itemQuantity = item.quantity;
        const newStateSelectedAddon = { ...stateSelectedAddon };
        const data = [];
        if (newStateSelectedAddon[itemCartId]) {
            const itemCart = items.find(item => item.parent == itemCartId && item.productId == newStateSelectedAddon[itemCartId]);
            if (itemCart) {
                data.push({
                    product: itemCart.id,
                    quantity: itemQuantity,
                    delete: 1
                });
            }
        }
        if (addon != 'noAddon') {
            addon.parent = itemCartId;
            data.push({
                product: addon,
                quantity: itemQuantity,
                parent: itemCartId
            });
            newStateSelectedAddon[itemCartId] = addon.id;
        } else {
            newStateSelectedAddon[itemCartId] = addon;
        }

        dispatch(addProductCart({ data }));

        setStateSelectedAddon(newStateSelectedAddon);
    }

    useEffect(() => {
        const cartAddons = items.filter(item => item.type == 'addon');
        const initStateSelectedAddon = { ...stateSelectedAddon };
        cartAddons.map(addon => {
            initStateSelectedAddon[addon.parent] = addon.productId
        });
        setStateSelectedAddon(initStateSelectedAddon);

        setItemPrice(getCartItemUnitPrice(item, items));
    }, [items]);

    if (item.addonProducts && item.addonProducts.length == 0) {
        return (<></>);
    }

    return (
        <Box className={classes.addonContainer}>
            <Grid container>
                <Grid Grid item xs={12}>
                    <Typography variant="h4" className={classes.addonTitle}>Garantia estendida</Typography>
                    <Typography variant="body1" className={classes.addonText}>Adicione mais segurança na sua compra. Veja o regulamento <Link target="_blank" href="/garantia-estentida" rel="noreferrer">aqui</Link>.</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid Grid item md={4} xs={12}>
                    <Box
                        className={'noAddon' == stateSelectedAddon[item.id] ? `${classes.addonCardActive} ${classes.noAddonCard}` : `${classes.addonCard} ${classes.noAddonCard}`}
                        onClick={() => handleChangeAddon(item, 'noAddon')}>
                        <Box flexGrow={1}>
                            <Typography className={classes.addonCardTitle}>Garantia 1 ano de fábrica</Typography>
                        </Box>
                    </Box>
                </Grid>
                {item.addonProducts.map(addon =>
                    <Grid Grid item md={4} xs={12}>
                        <Box
                            className={addon.id == stateSelectedAddon[item.id] ? classes.addonCardActive : classes.addonCard}
                            onClick={() => handleChangeAddon(item, addon)}>
                            <Box flexGrow={1}>
                                <Typography className={classes.addonCardTitle}>{addon.name}</Typography>
                                <AddonListPrice price={getAddonPrice(addon, itemPrice, item.quantity)} />
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
