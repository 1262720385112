import React from "react";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ThemeSliderBanner from 'src/components/SliderBanner/SliderBanner';

export const SliderBanner = ({ images, showThumbnails }) => {
    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const bannerSize = 850;
    const bannerSizeMobile = 200;

    return (
        <ThemeSliderBanner
            images={images.images}
            showThumbnails={showThumbnails}
            height={mobileSize ? bannerSizeMobile : bannerSize}
        />
    );
}
