import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        width: '100%'
    },
    input: {
        paddingLeft: 5,
        backgroundColor: '#e6e4e5',
        width: '100%',
        borderRadius: 5,
        border: 0,
        height: '2.5rem',
        '&:focus': {
            borderTopLeftRadius: 0,
            outline: `1px solid ${theme.palette.primary.main}`
        }
    },
    label: {
        color: '#FFF',
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        borderTop: `1px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid ${theme.palette.primary.main}`,
        borderTopRightRadius: 12,
        fontSize: '0.75rem',
        fontWeight: 'bold',
        padding: '2px 10px'
    },
    error: {
        width: '100%',
        color: '#FFF',
        textAlign: 'right',
        fontSize: '0.9rem'
    }
}));

export function TextField({label, id, error, helperText, ...rest}) {
    const classes = useStyles();

    return (
        <Box className={classes.inputContainer}>
            <Box
                display='flex'
                p={0}
                m={0}
            >
                <label
                    htmlFor={id}
                    className={classes.label}
                >
                    {label}
                </label>
                <span className={classes.error}>
                    {error && helperText}
                </span>
            </Box>
            <input
                id={id}
                className={classes.input}
                {...rest}
            />
        </Box>
    );
}