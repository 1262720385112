import React from 'react';

import {
    Typography,
    Link,
    makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    itemHelp: {
        border: '3px solid #ffffff',
        color: '#222222',
        backgroundColor: '#ffffff',
        maxWidth: '200px',
        minWidth: '180px',
        width: '100%',
        height: '180px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        margin: '0 10px',
        flexDirection: 'column',
        '&:hover': {
            textDecoration: 'none',
            color: "#ed1c24",
            border: '3px solid #ed1c24',
            '& img': {
                filter: `invert(21%) sepia(78%) saturate(6302%) hue-rotate(349deg) brightness(95%) contrast(96%)`,
            }
        },
        '@media (max-width: 1700px)': {
            maxWidth: '140px',
            minWidth: '140px',
            width: '100%',
            height: '140px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0',
            height: 'initial',
            width: '100%',
            minWidth: 'initial',
            padding: '8px',
            '& img': {
                width: '24px',
                height: '24px',
            },
            '& h6': {
                fontSize: '12px',
                marginTop: '4px'
            },
        },
    },
    redBullet: {
        width: '8px',
        height: '8px',
        display: 'block',
        borderRadius: '50%',
        backgroundColor: '#ff367d', // '#ED1C24'
    },
    redBulletContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));


export function HelpButton({ title, icon, onClick, showBullet = true }) {
    const classes = useStyles();

    return (
        <>
            <Link component="button" onClick={onClick} className={classes.itemHelp + '  itemHelpHover'}>
                <img src={icon} className="icon iconBlack" />
                <Typography variant="subtitle2" >
                    {title}
                </Typography>
            </Link>

            {showBullet && (
                <div className={classes.redBulletContainer}>
                    <div className={classes.redBullet}></div>
                </div>
            )}
        </>
    );
}