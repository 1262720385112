import React from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";

import { getBoxMarginProps, getBreakpointValue, getColor } from "./functions";

export const Text = (props) => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

	const textStyle = {};

	const color = getColor(props.color);
	const fontSize = getBreakpointValue(props, breakpoint, 'size');
	const lineHeight = getBreakpointValue(props, breakpoint, 'lineHeight');
  
	if(color) textStyle.color = color;
	if(fontSize) textStyle.fontSize =`${fontSize}px`;
	if(lineHeight) textStyle.lineHeight = lineHeight;

	return (
		<Box
			{...getBoxMarginProps(props, breakpoint)}
			style={{
				textAlign: getBreakpointValue(props, breakpoint, 'textAlign'),
			}}
		>
			<Typography 
				variant={props.variant}
				style={{ ...textStyle }}
				dangerouslySetInnerHTML={{
					__html: props.text
				}}
			/>
		</Box>
	)
}