export function getBackgroundImage(background) {
  if (!background) return null;

  let backgroundUrl = '';

  if (background.url)
    backgroundUrl = background.url;
  else if (background.image?.url)
    backgroundUrl = background.image.url;

  return backgroundUrl ? `url(${backgroundUrl})` : ''
}

export function getBreakpointBackgroundImage(props, breakpoint) {
  let value = props.background;

  if (value) {
    value = props.background.url || props.background.image?.url;
  }

  if (props[breakpoint]) {
    value = props[breakpoint].background;

    if (value) {
      value = props[breakpoint].background.url || props[breakpoint].background.image?.url;
    }

    if (!value) {
      const inverseBreakpoint = inverseBreakpoints[breakpoint];

      value = props[inverseBreakpoint].background;

      if (value) {
        value = props[inverseBreakpoint].background.url || props[inverseBreakpoint].background.image?.url;
      }

      if (value && (props[breakpoint].background.hidden || props[breakpoint].background.image?.hidden)) return '';
    }
  };

  return value ? `url(${value})` : ''
}

export function getColor(color) {
  if (typeof color === 'object') {
    return `rgba(${Object.values(color)})`
  }
  if (typeof color === 'string') {
    return color;
  }

  return '';
}

export function getImageUrl(image) {
  if (typeof image === 'object') {
    return image.base64 || image.url
  }

  return image;
}

export const isPercentage = (val) =>
  typeof val == 'string' && val.indexOf('%') > -1;

export const percentToPx = (value, comparativeValue) => {
  if (value.indexOf('px') > -1 || value == 'auto' || !comparativeValue)
    return value;
  const percent = parseInt(value);
  return (percent / 100) * comparativeValue + 'px';
};
export const pxToPercent = (value, comparativeValue) => {
  // if ( typeof value == 'number' ) return;
  const val = (Math.abs(value) / comparativeValue) * 100;
  if (value < 0) return -1 * val;
  else return Math.round(val);
};
export const getElementDimensions = (element) => {
  const computedStyle = getComputedStyle(element);

  let height = element.clientHeight,
    width = element.clientWidth; // width with padding

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom);
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight);

  return {
    width,
    height,
  };
};

export function getHeight(props, breakpoint) {
  let height = props.height;
  let heightMeasurement = props.heightMeasurement;

  if (props[breakpoint]) {
    height = props[breakpoint].height;
    heightMeasurement = props[breakpoint].heightMeasurement;
  }

  if (!height) {
    const inverseBreakpoint = inverseBreakpoints[breakpoint];

    height = props[inverseBreakpoint].height;
    heightMeasurement = props[inverseBreakpoint].heightMeasurement;
  }

  return height ? `${height}${heightMeasurement}` : '';
}

export const inverseBreakpoints = {
  xs: 'md',
  md: 'xs'
}

function hasValues(value) {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  if (typeof value == 'object') {
    return Object.values(value).length > 0;
  }

  return value !== '' && value !== undefined && value !== null;
}

export function getBreakpointValue(props, breakpoint, propkey, index, searchReverseBreakpoint = true) {
  let value = props[propkey];

  if (index) {
    value = props[propkey][index];
  }

  if (hasValues(props[breakpoint][propkey])) {
    value = props[breakpoint][propkey]

    if (value && index) {
      value = props[breakpoint][propkey][index]
    }
  };

  if (!value && searchReverseBreakpoint) {
    const inverseBreakpoint = inverseBreakpoints[breakpoint];

    if (hasValues(props[inverseBreakpoint][propkey])) {
      value = props[inverseBreakpoint][propkey]

      if (value && index) {
        value = props[inverseBreakpoint][propkey][index]
      }
    }
  }

  return value;
}

export function getBoxMarginProps(props, breakpoint, sx = true) {
  if (sx) {
    return {
      mt: Number(getBreakpointValue(props, breakpoint, 'marginTop')),
      mr: Number(getBreakpointValue(props, breakpoint, 'marginRight')),
      mb: Number(getBreakpointValue(props, breakpoint, 'marginBottom')),
      ml: Number(getBreakpointValue(props, breakpoint, 'marginLeft')),
    }
  }

  return {
    marginTop: Number(getBreakpointValue(props, breakpoint, 'marginTop')),
    marginRight: Number(getBreakpointValue(props, breakpoint, 'marginRight')),
    marginBottom: Number(getBreakpointValue(props, breakpoint, 'marginBottom')),
    marginLeft: Number(getBreakpointValue(props, breakpoint, 'marginLeft')),
  }
}

export function getBoxPaddingProps(props, breakpoint) {
  return {
    pt: Number(getBreakpointValue(props, breakpoint, 'paddingTop')),
    pr: Number(getBreakpointValue(props, breakpoint, 'paddingRight')),
    pb: Number(getBreakpointValue(props, breakpoint, 'paddingBottom')),
    pl: Number(getBreakpointValue(props, breakpoint, 'paddingLeft')),
  }
}
