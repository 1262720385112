import React from 'react';
import Swiper from 'react-id-swiper';
import { Link as RouterLink } from 'react-router-dom';

import {
    Box,
    Grid,
    Typography,
    Link,
    useTheme,
    useMediaQuery,
    makeStyles
} from '@material-ui/core';

import {
    ChevronRight as ChevronRightIcon
} from 'react-feather';

import home from 'src/theme/scss/images/home.jpg';
import study from 'src/theme/scss/images/study.jpg';
import play from 'src/theme/scss/images/play.jpg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    swiperMainContainer: {
        '& .swiper-container': {
            '& .swiper-pagination-bullets': {
                bottom: 0,
    
                '& .swiper-pagination-bullet': {
                    backgroundColor: '#C9C9C9',
                },
                '& .swiper-pagination-bullet-active': {
                    backgroundColor: 'transparent',
                    border: '2px solid #e71b23',
                    height: '12px',
                    width: '12px',
                    transform: 'scale(1)'
                }
            }
        }
    },
    itemStrands: {
        color: '#ffffff',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        '&:after': {
            content: "''",
            display: 'block',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        '&:hover': {
            textDecoration: 'none',
            color: '#ffffff',
        },
        [theme.breakpoints.down('sm')]: {
            height: 320
        },
    },
    blockStrands: {
        zIndex: '1'
    },
    hidden: {
        display: 'none'
    },
    show: {
        display: 'block'
    }
}));

const params = {
    speed: 500,
    loop: true,
    spaceBetween: 0,
    effect: 'fade',
    slidesPerView: 1,
    rebuildOnUpdate: true,
    observer: true,
    centeredSlides: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    },
};

export function Strands() {
    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const classes = useStyles();

    const strands = [
        {
            id: 1,
            title: 'FPS LÁ EM CIMA',
            buttonText: 'NOTEBOOKS COM TELA QHD',
            buttonLink: '/fpslaemcima',
            backgroundImage: home
        },
        {
            id: 2,
            title: 'FINOS E LEVES',
            buttonText: 'PRA COLOCAR NA MOCHILA E SAIR POR AI',
            buttonLink: '/finoseleves',
            backgroundImage: study
        },
        {
            id: 3,
            title: 'GRÁFICOS INCRÍVEIS',
            buttonText: 'NOTEBOOKS COM RTX SÉRIE 30 TI',
            buttonLink: '/graficosincriveis',
            backgroundImage: play
        },
    ]

    return (
        <Box className={classes.strands}>
            <Box className={mobileSize ? classes.hidden : classes.show}>
                <Grid
                    container
                    alignItems="center"
                >
                    { strands.map((strand) => (
                        <Grid
                            item
                            sm={4}
                            key={strand.id}
                        >
                            <Link
                                className={classes.itemStrands}
                                style={{ backgroundImage: 'url("' + strand.backgroundImage + '")' }}
                                component={RouterLink}
                                to={strand.buttonLink}
                            >
                                <Box className={classes.blockStrands}>
                                    <Typography variant="h2">
                                        {strand.title}
                                    </Typography>
                                    <Box className="btn btn-9">
                                        {strand.buttonText}
                                        <ChevronRightIcon size={16} className="icon" />
                                    </Box>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box className={clsx(classes.swiperMainContainer, {
                [classes.show]: mobileSize,
                [classes.hidden]: !mobileSize
            })}>
                <Swiper {...params}>
                    { strands.map((strand) => (
                        <div
                            key={strand.id}
                        >
                            <Link
                                className={classes.itemStrands}
                                style={{ backgroundImage: 'url("' + strand.backgroundImage + '")' }}
                                component={RouterLink}
                                to={strand.buttonLink}
                            >
                                <Box className={classes.blockStrands}>
                                    <Typography variant="h2">
                                        {strand.title}
                                    </Typography>
                                    <Box className="btn btn-9">
                                        {strand.buttonText}
                                        <ChevronRightIcon size={16} className="icon" />
                                    </Box>
                                </Box>
                            </Link>
                        </div>
                    ))}
                </Swiper>
            </Box>
        </Box>
    );
}