import React from 'react';
import { useHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Box, Input, Grid, FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ManagerApi from 'src/services/managerApi';
import { useDispatch } from 'react-redux';
import { addMessage, openDialogGlobal, closeDialogGlobal } from 'src/actions';
import dialogIllustration from 'src/theme/scss/images/illustration-modal-chamado.svg';
import closeModalIcon from 'src/theme/scss/icons/close-modal.svg'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: '80px 60px',
        backgroundColor: '#F4F4F4',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            padding: '48px 24px',
        }
    },
}))(MuiDialogContent);

const DialogHeading = withStyles((theme) => ({
    root: {
        color: '#000000',
        fontSize: '30px',
        lineHeight: '1.2',
        fontFamily: 'Gilroy-ExtraBold',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            marginTop: '32px',
            textAlign: 'center',
        },
        '& span': {
            textTransform: 'uppercase',
            color: '#76B900',
        },
    },
}))(Typography);

const DialogSubHeading = withStyles((theme) => ({
    root: {
        fontSize: '18px',
        color: '#333333',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            textAlign: 'center'
        },
    },
}))(Typography);

const DialogImageContainer = withStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        '& img': {
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '80%',
            }
        }
    },
}))(Box);

const DialogInfoContainer = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '60px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 'initial',
        },
        '& form': {
            '& > *': {
                marginBottom: '12px',
            },
            '& input': {
                fontSize: '1.125rem',
                '&::placeholder': {
                    fontSize: '1.125rem',
                }
            },
            '& button': {
                width: '100%',
                color: '#ffffff',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: '800',
                fontFamily: 'Manrope',
                borderRadius: '6px',
                padding: '12px 24px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                }
            }
        }
    },
}))(Box);

const DialogCloseButton = withStyles((theme) => ({
    root: {
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'translate(50%, -50%)',
        filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1))'
    },
}))(Box);


const ErrorMessage = withStyles((theme) => ({
    root: {
        color: '#FF0000',
        fontSize: '.875rem',
    },
}))(Typography);

export default function CustomizedDialogs({ handleClickOpen, open, handleClose }) {
    const dispatch = useDispatch();
    let history = useHistory();

    const validationSchema = Yup.object().shape({
        areaCode: Yup.string().max(3).required("Informe o DDD"),
        phone: Yup.string().max(10, "Informe no máximo 10 dígitos").required("Informe o número"),
    });

    let initialForm = {
        areaCode: '',
        phone: ''
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                PaperProps={{ style: { maxWidth: '1004px', overflow: 'visible' } }}
            >
                <DialogCloseButton onClick={handleClose}>
                    <img src={closeModalIcon} alt="" />
                </DialogCloseButton>
                <DialogContent>

                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <DialogImageContainer>
                                <img src={dialogIllustration} alt="Nós Ligamos" />
                            </DialogImageContainer>
                        </Grid>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DialogInfoContainer>
                                <DialogHeading>Infelizmente nenhum consultor está online!</DialogHeading>
                                <DialogSubHeading>Envie-nos um e-mail para que possamos retornar o contato.</DialogSubHeading>
                                <form>
                                    {/* <Button onClick={() => { dispatch(openDialogGlobal('dialog-0800')) } } variant="contained" color="primary">0800 VIRTUAL</Button> */}
                                    <Button style={{ backgroundColor: '#ff367d' }} onClick={() => { dispatch(closeDialogGlobal()); history.push('/contato'); }} to="/contato" variant="contained" color="primary">E-MAIL</Button>
                                </form>
                            </DialogInfoContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}