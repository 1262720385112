import React from 'react';

import { Box, Button, Container, Dialog, makeStyles, Typography, withStyles } from '@material-ui/core';

import closeModalIcon from 'src/theme/scss/icons/close-modal.svg'
import logo from 'src/theme/scss/icons/logoNewWhite.svg';
import backgroundDialogImage from 'src/theme/scss/images/baner_dialog.jpg';
import whiteArrow from 'src/theme/scss/images/seta_branca.png';
import arrow from 'src/theme/scss/images/seta.png';
import curveArrow from 'src/theme/scss/images/seta_curva.png';
import { TextField } from 'src/components/TextField';
import { v4 as uuidv4 } from 'uuid';
import { Formik } from 'formik';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addLoadingGlobal, addMessage, removeLoadingGlobal } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import InputMask from 'react-input-mask';

const DialogCloseButton = withStyles((theme) => ({
    root: {
        cursor: 'pointer',
        position: 'absolute',
        top: '5px',
        right: '5px',
        filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1))'
    },
}))(Box);


const useStyles = makeStyles((theme) => ({
    logo: {
        '& img': {
            width: '6rem',
        },
        position: 'relative',
        '&:before': {
            content: '""',
            backgroundImage: `url(${curveArrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            display: 'block',
            position: 'absolute',
            width: 50,
            left: 30,
            height: 70,
            top: -80,
        },
        [theme.breakpoints.down('xs')]: {
            '&:before': {
                width: 30,
                left: 30,
                height: 40,
                top: -40,
            },
        }
    },
    titleSection: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    },
    title: {
        fontSize: '1.3rem',
        lineHeight: 1
    },
    content: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(2),
        }
    },
    container: {
        position: 'relative',
        backgroundImage: `url(${backgroundDialogImage})`,
        backgroundPosition: 'center',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 50,
            paddingRight: 50
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 30,
            paddingRight: 30
        }
    },
    button: {
        maxWidth: '12rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            fontSize: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            '& span': {
                fontSize: '0.8rem'
            },
        }
    },
    form: {
        position: 'relative',
        '&:before': {
            content: '""',
            backgroundImage: `url(${arrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            display: 'block',
            position: 'absolute',
            width: 130,
            height: 100,
            top: -70,
            left: -35
        },
        [theme.breakpoints.down('xs')]: {
            '&:before': {
                width: 35,
                top: -30,
                left: -25
            }
        }
    },
    footer: {
        position: 'relative',
        '&:before': {
            content: '""',
            backgroundImage: `url(${whiteArrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            display: 'block',
            position: 'absolute',
            width: 130,
            height: 100,
            top: 20,
            left: -80
        },
        [theme.breakpoints.down('sm')]: {
            '&:before': {
                width: 80,
                height: 100,
                top: 30,
                left: -50
            }
        }
    }
}));


export function NewsletterDialog({ isOpen, onClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [translate] = useTranslation();

    const newsletterValidationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(translate('errorRequiredField')),
        email: Yup.string().email(translate('errorEmailField')).max(255).required(translate('errorRequiredField')),
        whatsapp: Yup.string().required(translate('errorRequiredField')),
        occupation: Yup.string().required(translate('errorRequiredField')),
    });

    async function handleSubscribeNewsletter(values) {
        const requestId = uuidv4();

        try {
            dispatch(addLoadingGlobal(requestId));

            const response = await new ManagerApi('/customer/newsletter').post({
                name: values.name,
                email: values.email,
                additionalInformation: {
                    whatsapp: values.whatsapp,
                    occupation: values.occupation,
                }
            }).catch(error => {
                throw error;
            });

            if (response.data.success) {
                localStorage.setItem('@avell/newsletterSubscribed', 1);
                dispatch(addMessage(translate('alertNewsletterSubscribedSuccessfully'), 'success'));
                onClose();
            }
        } catch(error) {
            dispatch(addMessage(error.response.data.message, 'error'));
        } finally {
            dispatch(removeLoadingGlobal(requestId));
        }
    }

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="Newsletter"
            PaperProps={{ style: { width: '500px', maxWidth: '500px', overflow: 'hidden' } }}
        >            
            <Formik
                initialValues={{ name: '', email: '', whatsapp: '', occupation: '' }}
                enableReinitialize
                validationSchema={newsletterValidationSchema}
                onSubmit={handleSubscribeNewsletter}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => {
                    return (
                        <Container
                            maxWidth={false}
                            className={classes.container}
                        >
                            <DialogCloseButton onClick={onClose}>
                                <img src={closeModalIcon} alt="" />
                            </DialogCloseButton>
                            <Box
                                className={classes.content}
                            >
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    flexDirection='column'
                                >
                                    <Box
                                        className={classes.logo}
                                    >
                                        <img
                                            alt={"Avell"}
                                            src={logo}
                                        />
                                    </Box>

                                    <Box
                                        textAlign='center'
                                        className={classes.titleSection}
                                    >
                                        <Typography
                                            color='primary'
                                            variant='h5'
                                            className={classes.title}
                                        >
                                            QUER RECEBER
                                        </Typography>
                                        <Typography
                                            variant='h5'
                                            className={classes.title}
                                            style={{
                                                color: '#FFF'
                                            }}
                                        >
                                            PROMOS EXCLUSIVAS DA
                                        </Typography>
                                        <Typography
                                            color='primary'
                                            variant='h5'
                                            className={classes.title}
                                        >
                                            IMPERDÍVEL AVELL BF22
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    flexDirection='column'
                                    gridGap={10}
                                    className={classes.form}
                                >
                                    <TextField
                                        id='name'
                                        variant='outlined'
                                        label='NOME'
                                        placeholder="Insira o seu nome"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                    <TextField
                                        id='email'
                                        variant='outlined'
                                        label='EMAIL'
                                        placeholder="Insira o seu e-mail"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                    <InputMask
                                        mask="(99)99999-9999"
                                        maskChar={null}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.whatsapp}
                                    >
                                        {() => (
                                            <TextField
                                                id='whatsapp'
                                                variant='outlined'
                                                label='WHATSAPP'
                                                placeholder="Insira o seu whatsapp"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.whatsapp}
                                                error={Boolean(touched.whatsapp && errors.whatsapp)}
                                                helperText={touched.whatsapp && errors.whatsapp}
                                            />
                                        )}
                                    </InputMask>
                                    <TextField
                                        id='occupation'
                                        variant='outlined'
                                        label='ATUAÇÃO'
                                        placeholder="Insira a sua área de atuação"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.occupation}
                                        error={Boolean(touched.occupation && errors.occupation)}
                                        helperText={touched.occupation && errors.occupation}
                                    />
                                </Box>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    mt={4}
                                    className={classes.footer}
                                >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className={classes.button}
                                        onClick={handleSubmit}
                                    >
                                        CLIQUE AQUI E INSCREVA-SE
                                    </Button>
                                </Box>
                            </Box>
                        </Container>
                    )
                }}
            </Formik>
        </Dialog>
    );
}