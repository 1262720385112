import React from "react";
import { Helmet } from 'react-helmet';

export const Image360 = (props) => {

  const {images} = props;
  
  return (
    <>
      <Helmet>
          <script src="/image-360-view.min.js"></script>
          <script defer>window.CI360.init();</script>
      </Helmet>

      {images.images.length && (
        <div
          className="cloudimage-360"
          data-folder={images.url_base}
          data-filename={"/{index}."+images.extension}
          data-amount={images.images.length}
        />
      )}
    </>
  )
}