import React, { useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import {
    Link,
    makeStyles,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { paramCase } from 'change-case';

export default function SliderBanner({ images, showThumbnails }) {
    const gallerySwiperRef = useRef(null);
    const thumbnailSwiperRef = useRef(null);

    const useStyles = makeStyles((theme) => ({
        root: {
        },
        swiperMainContainer: {
            '& .swiper-pagination': {
                bottom: 50,
                [theme.breakpoints.down('xs')]: {
                    bottom: 25,
                },
            },
            '& .swiper-pagination-bullet': {
                width: '7px',
                height: '7px',
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                margin: '0 25px !important'
            },
            '& .swiper-pagination-bullet-active': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1
            },
            '& .swiper-button-prev, & .swiper-button-next':{
                padding: '1rem',
                color: '#777777 !important',
                transition: 'color .3s',
                '&:hover': {
                    color: '#ffffff !important'
                },
                '&::after': {
                    fontSize: '24px'
                }
            },
        },
        swiperMainSlide: {
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                height: 624,
                alignItems: 'flex-end'
            },
        },
        linkSlide: {
            '&:hover': {
                textDecoration: 'none'
            }
        },
        infoSlide: {
            width: 650,
            marginTop: 50,
            marginLeft: 80,
            [theme.breakpoints.down('sm')]: {
                marginBottom: 35,
                marginLeft: 20
            },
        },
        info: {
            color: '#ffffff',
            textDecoration: 'none'
        },
        hidden: {
            display: 'none',
        },
        img1: {
            marginBottom: 20,
            maxWidth: 100,
            [theme.breakpoints.down('sm')]: {
                height: 62,
                marginBottom: 15,
            },
        }
    }));

    const classes = useStyles();

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const swiperParams = {
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        spaceBetween: 0,
        speed: 1500,
        loop: true,
        effect: 'fade',
        slidesPerView: 1
    };


    const thumbnailSwiperParams = {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
    };


    if (!showThumbnails) {
        swiperParams.rebuildOnUpdate = true;
    }

    if (images.length > 1 && !showThumbnails) {
        swiperParams.navigation = {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        };
        
        swiperParams.pagination = {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        };
    }

    useEffect(() => {
        if (images.length && showThumbnails) {
            const gallerySwiper = gallerySwiperRef.current.swiper;
            const thumbnailSwiper = thumbnailSwiperRef.current.swiper;

            if (gallerySwiper.controller && thumbnailSwiper.controller) {
                gallerySwiper.controller.control = thumbnailSwiper;
                thumbnailSwiper.controller.control = gallerySwiper;
            }
        }
    }, [images, showThumbnails]);

    return (
        <div className={classes.root}>
            <div className={classes.swiperMainContainer}>
                <Swiper
                    ref={gallerySwiperRef}
                    {...swiperParams}
                >
                    {images.map((image, idx) => (
                        <div key={`slide_main_${idx}`} >
                            {image.link ? (
                                <Link href={image.link} className={classes.linkSlide}>
                                    <div key={`slide_main_${idx}`} className={classes.swiperMainSlide} style={{ backgroundImage: 'url(' + image.url + ')' }}>
                                        <Box className={classes.infoSlide}>
                                            <img src={image.img1} className={classes.img1}/>        
                                            <Typography variant="h3" className={classes.info}>
                                                {image.text1}
                                            </Typography>
                                            <Typography variant="h1" className={classes.info} style={{ marginBottom: "10px" }}>
                                                {image.text2}
                                            </Typography>
                                            <Typography variant="subtitle2" className={mobileSize ? classes.hidden : classes.info} style={{ marginBottom: "20px" }}>
                                                {image.text3}
                                            </Typography>
                                            <div className="btn btn-1" style={{ marginBottom: "30px" }}>
                                                {image.btnText}
                                            </div>
                                            <img src={image.img2} className={mobileSize ? classes.hidden : classes.img2} />        
                                            <img src={image.img3} className={mobileSize ? classes.hidden : classes.img3} />        
                                        </Box>
                                    </div>
                                </Link>
                            ) : (
                                <div key={`slide_main_${idx}`} className={classes.swiperMainSlide}>
                                    <img src={image.url} style={{ width: "100%" }} />
                                </div>
                            )}
                        </div>
                    ))}
                </Swiper>
                {showThumbnails && (
                    <Box className={classes.swiperThumbnailContainer}>
                        <Swiper
                            {...thumbnailSwiperParams}
                            ref={thumbnailSwiperRef}
                        >
                            {images.map((image, idx) => (
                                <div key={`slide_thumbnail_${idx}`} style={{
                                    backgroundImage: `url(${image.url})`,
                                    backgroundSize: 'cover',
                                    height: '100px',
                                    width: '100px'
                                }} />
                            ))}
                        </Swiper>
                    </Box>
                )}
            </div>
        </div>
    )
}
