import React from 'react';

import { useNode } from '@craftjs/core';
import { CategoriesBlock } from 'src/views/Home/CategoriesBlock';


export const RenderCategories = ({ render }) => {

    const {
      id
    } = useNode((node) => ({
      currentNode: node.id
    }));
  
    const bannerContainerNodeId = "Usk9k0TNSh";
  
    return bannerContainerNodeId === id ? (
      <>
          <CategoriesBlock />
      </>
    ) : (
      <>
          {render}
      </>
    )
  };
  