import React from 'react';
import { Helmet } from 'react-helmet';

export function Rakuten() {

    if (process.env.NODE_ENV !== 'production') {
        return (<></>);
    }

    return (
        <Helmet>
            <script>
                {`
                    var url = window.location.href;
                    var expires = "Thu, 01 Jan 1970 00:00:00 UTC";

                    if (url.includes('gclid')) {
                        document.cookie = "RKT=true; expires=" + expires + "; path=/; Domain=" + window.location.hostname;
                    }
                    else if(url.includes('utm_source')) {
                        if(url.includes('utm_source=Rakuten')) {
                            var d = new Date();
                            var dias = 30;
                            d.setTime(d.getTime() + (dias*24*60*60*1000));
                            expires = "expires="+d.toGMTString();
                        }
                        document.cookie = "RKT=true; expires=" + expires + "; path=/; Domain=" + window.location.hostname;
                    }
                `}
            </script>
            <script>
                {`
                    (function (url) {
                        /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
                        window.rakutenDataLayer = false; // Set custom DataLayer name if 'DataLayer' conflicts
                    var dln = window.rakutenDataLayer || 'DataLayer';
                    if(!window[dln]){
                        window[dln] = {};
                        }
                        if(!window[dln].events){
                        window[dln].events = {};
                        }
                        window[dln].events.SPIVersion = window[dln].events.SPIVersion || "v3.4.1";
                
                        var loc, ct = document.createElement("script");
                        ct.type = "text/javascript";
                        ct.async = true; ct.src = url; loc = document.getElementsByTagName('script')[0];
                        loc.parentNode.insertBefore(ct, loc);
                        }(document.location.protocol + "//tag.rmp.rakuten.com/125514.ct.js")
                    );
            `}
            </script>
        </Helmet>
    );
}
