import React from "react";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import { getBreakpointBackgroundImage, getBreakpointValue, getColor } from "./functions";

export const PageContainer = (props) => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

	const containerProps = {
        style: {
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',
			position: 'relative'
        }
    };

    const backgroundImage = getBreakpointBackgroundImage(props, breakpoint);
    const backgroundColor = getColor(getBreakpointValue(props, breakpoint, 'background', 'color'));

    if(backgroundImage) {
        containerProps.style.backgroundImage = backgroundImage;
        containerProps.style.backgroundSize = getBreakpointValue(props, breakpoint, 'background', 'size');
        containerProps.style.backgroundRepeat = getBreakpointValue(props, breakpoint, 'background', 'repeat');
        containerProps.style.backgroundPosition = getBreakpointValue(props, breakpoint, 'background', 'position');
    }

    if(backgroundColor) {
        containerProps.style.backgroundColor = backgroundColor;
    }

	return (
		<Box
			{...containerProps}
		>
			<Box
				maxWidth="1920px"
				style={{
					width: '100%'
				}}
			>
				{props.children}
			</Box>
		</Box>
	)
}