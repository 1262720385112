import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import personIcon from 'src/theme/scss/icons/person.svg';
import { makeStyles, withStyles, Button, Box, Container, Grid, MenuItem, Tooltip } from '@material-ui/core';
import { logout } from 'src/actions';
import { LoginModal } from '../../Login/Modal';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#222222',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        maxHeight: '24px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    boxStatusGroup: {
        position: 'relative',
    },
    boxOrderStatus: {
        position: 'absolute',
        left: 0,
        zIndex: 9,
    },
    button: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        color: '#ffffff',
        fontSize:  '12px',
        textTransform: 'none',
        fontFamily: 'Gilroy-Extrabold, sans-serif',
        '&:hover': {
            color: '#ffffff',
        },
        '&:last-child::before': {
            content: "''",
            display: 'block',
            height: '4px',
            width: '4px',
            borderRadius: '50%',
            backgroundColor: '#ed1c24',
            position: 'absolute',
            left: '-8px',
        }
    },
    orderStatus: {
        '& svg': {
            marginLeft: '10px',
        },
        '&:after': {
            display: 'none !important',
        },
    },
    logoutIconBox: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    logoutIcon: {
        marginLeft: 20,
        height: 21,
    },
    userIcon: {
        marginRight: '13px',
        height: '21px',
    },
    userIconBox: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1,
        color: '#ffffff'
    },
    loginBlock: {
        position: 'absolute',
        left: 0,
        zIndex: 9999,
        '@media (max-width: 960px)': {
            left: 'initial',
            right: 0,
        },
    },
}));

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: 0,
        margin: 4,
    },
}))(Tooltip);

function BlogTopMenu() {
    const classes = useStyles();
    const isHomePage = window.location.pathname === '/';
    const dispatch = useDispatch();
    let history = useHistory();
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showLoginBlock, setShowLoginBlock] = useState(false);
    const handleClickMenu = event => {
        if (anchorMenu !== event.currentTarget) {
            setAnchorMenu(event.currentTarget);
        }
    };

    const getFirstName = () => {
        return userAccount.name.split(' ')[0];
    };

    const userAccount = useSelector(state => state.account.user);

    const logoutUser = async () => {
        dispatch(logout());
    };

    return (
        <Box className={classes.root}>
            {userAccount != null ? (
                <>
                    <Button className={classes.button}>
                        <Box
                            className={classes.userIconBox}
                        >
                            <svg className={classes.userIcon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 16C14.7 16 17.8 17.29 18 18H6C6.23 17.28 9.31 16 12 16ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="#ffffff"/>
                            </svg>
                            Olá, {getFirstName()}
                        </Box>
                    </Button>
                    <Button className={classes.button} onClick={logoutUser}>
                       Logout
                    </Button>
                </>
            ) : (
                <>
                    <Button component={RouterLink} to="/onestepcheckout/customer/register" className={classes.button}>
                        Cadastre-se
                    </Button>
                    <Box style={{ display: 'inline-block', position: 'relative' }}>
                        <Button id="js-login-button" onClick={() => setShowLoginBlock(!showLoginBlock)} className={classes.button}>
                            Entrar
                        </Button>
                        {showLoginBlock && (
                            <Box className={classes.loginBlock}>
                                <LoginModal close={() => setShowLoginBlock(false)} />
                            </Box>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}

BlogTopMenu.propTypes = {
    className: PropTypes.string,
};

export default BlogTopMenu;
