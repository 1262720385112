import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    Container,
    List,
    ListItem,
    ListItemText,
    Grid,
    Box
} from '@material-ui/core';
import { logout } from 'src/actions';

import PromoBanner from 'src/components/PromoBanner';
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import MainLayout from 'src/layouts/MainLayout';

const useStyles = makeStyles((theme) => ({
    root: {},
    sidebarLink: {
        fontSize: '20px',
    }
}));

const routeList = {
    "/customer/account/addresses": { "label": "Meus endereços" },
    "/customer/account/edit": { "label": "Minha conta" },
    "/customer/account/orders": { "label": "Meus pedidos" },
    "/customer/account/update-password": { "label": "Alterar senha" }
}

function CustomerAccountLayout({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [translate, i18n] = useTranslation();

    const [path, setPath] = useState('');

    const logoutUser = () => {
        dispatch(logout());
        history.push('/');
    }

    useEffect(() => {
        getBreadcrumb()
    }, [window.location.pathname])

    const getBreadcrumb = () => {
        let label = routeList[window.location.pathname]?.label || 'Minha conta';
        setPath(label)
    }

    return (
        <MainLayout>
            <Box className={classes.root}>
                <PromoBanner />
                <Breadcrumb
                    textLink="Página Inicial"
                    textTypography={path}
                    textBreadcrumb={path}
                    linkToBreadcrumb={path}
                />
                <Container maxWidth={false}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid style={{ marginTop: '150px' }} item md={2} xs={12}>
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem button component={RouterLink} to="/customer/account">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographyMyAccountHome')}
                                    />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/customer/account/edit">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={"Meus Dados"}
                                    />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/customer/account/orders">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographyMyOrdersTitle')}
                                    />
                                </ListItem>
                                {/* <ListItem button component={RouterLink} to="/customer/account/coupons">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographyCouponsTitle')}
                                    />
                                </ListItem> */}
                                <ListItem button component={RouterLink} to="/customer/account/wallpapers">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographyWallpapersTitle')}
                                    />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/customer/account/uploads">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographyUploadsTitle')}
                                    />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/customer/account/wishlist">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographyWishlistTitle')}
                                    />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/customer/account/support">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographySupportTitle')}
                                    />
                                </ListItem>
                                <ListItem button component={RouterLink} to="/customer/account/user-guide">
                                    <ListItemText
                                        classes={{ primary: classes.sidebarLink }}
                                        primary={translate('typographyUserGuideTitle')}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid style={{ marginTop: '50px' }} item md={10} xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </MainLayout>
    );
}

CustomerAccountLayout.propTypes = {
    children: PropTypes.any
};

export default CustomerAccountLayout;
