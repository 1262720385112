import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ManagerApi from 'src/services/managerApi';
import ProductCard from 'src/components/ProductCard';
import { ProductSlider } from 'src/components/Content/ProductSlider';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '32px',
    },
    title: {
        fontSize: '16px',
        marginBottom: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            marginBottom: '12px',
        },
    },
    products: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
}));

function CartCrossSellProducts() {
    const classes = useStyles();
    const [products, setProducts] = useState([]);
    const order = useSelector(state => state.cart.order);
    const theme = useTheme();
    const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

    async function fetchProducts() {
        try {
            const updatedProducts = [];
            const orderItems = order.items.filter(item => !item.parent);
            console.log(orderItems);
            for (let item of orderItems) {
                const crossSellProductsApi = new ManagerApi(
                    `/catalog/product/${item.productId}/crossSellProducts`,
                );
                const response = await crossSellProductsApi.getList();
                if (response.data.success) {
                    const crossSellProducts = response.data.content.map(
                        item => item.crossSell,
                    );
                    crossSellProducts.forEach(crossSellProduct => {
                        const productIndex = updatedProducts.findIndex(
                            product => product.id === crossSellProduct.id,
                        );
                        if (productIndex === -1) {
                            updatedProducts.push(crossSellProduct);
                        }
                    });
                }
            }
            setProducts(updatedProducts);
        } catch {
            console.error('Erro ao obter venda cruzada.');
        }
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    if (products.length === 0) {
        return <></>;
    }

    return (
        <Box className={classes.root}>
            <Typography variant="h3" className={classes.title}>
                Achamos que você também pode gostar desses produtos
            </Typography>
            {mobileSize ? (
                <Box className={classes.products}>
                    {products.map(product => (
                        <ProductCard product={product} mobileVariant="horizontal" />
                    ))}
                </Box>
            ) : (
                <ProductSlider products={products} slidesPerView={2} />
            )}
        </Box>
    );
}

export default CartCrossSellProducts;
