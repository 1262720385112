import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    makeStyles
} from '@material-ui/core';

import Footer from 'src/layouts/CheckoutLayout/Footer';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    checkoutContent: {
        backgroundColor: '#f4f4f4',
    },
    logo: {
        height: '70px',
        maxWidth: '100%',
        padding: theme.spacing(2),
        margin: '0 auto',
        display: 'block'
    },
    divider: {
        width: 1,
        height: '100%',
    },
    colorBox: {
        background: theme.palette.background.dark,
        height: '100%'
    }
}));

function TradeinCheckoutLayout({ children }) {
    const classes = useStyles();

    return (
        <Box className={classes.checkoutContent}>
            {children}
            <Footer />
        </Box>
    );
}

TradeinCheckoutLayout.propTypes = {
    children: PropTypes.any
};

export default TradeinCheckoutLayout;
