import React, { useState, useEffect, lazy } from 'react';

import Page from 'src/components/Page';
import DynamicHome from './DynamicHome';

import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, removeLoadingGlobal } from 'src/actions';

import ManagerApi from 'src/services/managerApi';

export default function Index() {
    const [homeContent, setHomeContent] = useState({});
    const [isFixedHome, setIsFixedHome] = useState(false);

    const dispatch = useDispatch();

    const FixedHome = lazy(() => import('./FixedHome'));

    const routerApi = new ManagerApi('/router');

    async function fetchHome() {
        const requestId = uuidv4();
        dispatch(addLoadingGlobal(requestId));

        try {
            const response = await routerApi.getList({ url: 'home' }).catch(error => {
                throw error;
            });

            if (response.data?.content?.content) {
                return setHomeContent(response.data.content.content);
            }

            setIsFixedHome(true);
        } catch (error) {
            setIsFixedHome(true);
        } finally {
            dispatch(removeLoadingGlobal(requestId));
        }
    };

    useEffect(() => {
        fetchHome();
    }, []);

    return (
        <Page
            title=""
        >
            {isFixedHome ?
                <FixedHome />
                : homeContent.id ?
                    <DynamicHome content={homeContent} />
                    : <></>
            }
        </Page>
    )
}
