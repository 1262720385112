import databaseManager from 'src/services/databaseManager';
import moment from 'moment';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

export const ADD_LOADING_GLOBAL = 'ADD_LOADING_GLOBAL';
export const REMOVE_LOADING_GLOBAL = 'REMOVE_LOADING_GLOBAL';

export const ADD_CATEGORIES_MENU = 'ADD_CATEGORIES_MENU';

export const ADD_ATTRIBUTES_MENU = 'ADD_ATTRIBUTES_MENU';

export const ADD_ATTRIBUTES_CUSTOMER = 'ADD_ATTRIBUTES_CUSTOMER';

export const SET_STORE = 'SET_STORE';

export const FETCH_FAVORITES = 'FETCH_FAVORITES';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';

export const TOGGLE_COMPARISON = 'TOGGLE_COMPARISON';
export const ADD_COMPARISON = 'ADD_COMPARISON';
export const REMOVE_COMPARISON = 'REMOVE_COMPARISON';

export const ADD_FAVORITE_LOCALSTORAGE = 'ADD_FAVORITE_LOCALSTORAGE';

export const OPEN_DIALOG_GLOBAL = 'OPEN_DIALOG_GLOBAL';
export const CLOSE_DIALOG_GLOBAL = 'CLOSE_DIALOG_GLOBAL';

export const TOGGLE_DIALOG_COMPARISON = 'TOGGLE_DIALOG_COMPARISON';

export const loadLocalStorageFavorites = () => async (dispatch) => {
  const favorites = JSON.parse(window.localStorage.getItem('favorites')) || [];
  return dispatch({
    type: ADD_FAVORITE_LOCALSTORAGE,
    payload: favorites
  })
};

export const loadFavorites = () => async (dispatch) => {
  const favorites = await databaseManager.getAllFavorites();
  return dispatch({
    type: ADD_FAVORITE,
    payload: favorites
  })
};

export const addMessage = (message, severity) => (dispatch) => dispatch({
  type: ADD_MESSAGE,
  message: message,
  severity: severity
});

export const removeMessage = () => (dispatch) => dispatch({
  type: REMOVE_MESSAGE,
});

export const addLoading = (id) => (dispatch) => dispatch({
  type: ADD_LOADING,
  id: id
});

export const removeLoading = (id) => (dispatch) => dispatch({
  type: REMOVE_LOADING,
  id: id
});

export const addLoadingGlobal = (id) => (dispatch) => dispatch({
  type: ADD_LOADING_GLOBAL,
  id: id
});

export const removeLoadingGlobal = (id) => (dispatch) => dispatch({
  type: REMOVE_LOADING_GLOBAL,
  id: id
});

export const addCategoriesMenu = (payload) => (dispatch) => dispatch({
  type: ADD_CATEGORIES_MENU,
  payload: payload
});

export const addAttributesMenu = (payload) => (dispatch) => dispatch({
  type: ADD_ATTRIBUTES_MENU,
  payload: payload
});

export const addAttributesCustomer = (payload) => (dispatch) => dispatch({
  type: ADD_ATTRIBUTES_CUSTOMER,
  payload: payload
});

export const setStore = (payload) => (dispatch) => dispatch({
  type: SET_STORE,
  payload: payload
});

export const addFavorite = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ADD_FAVORITE,
      payload
    });
  }
};

export const removeFavorite = (id) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FAVORITE,
      id: id
    });
  }
};

export const fetchFavorites = (favorites) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_FAVORITES,
      favorites,
    });
  }
};

export const openDialogGlobal = (id) => {

  let weekday = moment().format('d');
  let time = moment().format('HH:mm');

  // Dentro do horário de atendimento abre o chat

  let hour = (
    weekday == '0' ||
    weekday == '6' && ((time >= '09:00' && time <= '12:30') && (time >= '13:30' && time <= '17:30')) ||
    (time < '09:00' || time > '18:30')
  );

  if (id == 'dialog-vendas' && !hour) {
    id = false;
    window.open('https://static.teclia.com/api/chat/web_messengers.html?token=2poPU4MdO4IoRfyP6Dfe&fullscreen=true', 'Chat', 'location=yes,height=570,width=520,top=50,location=no,status=yes,scrollbars=yes,resizable=yes');
  }

  if (id == 'dialog-0800' && hour) {
    id = 'dialog-vendas'
  }

  return (dispatch) => {
    dispatch({
      type: OPEN_DIALOG_GLOBAL,
      dialogGlobal: id
    });
  }
};

export const closeDialogGlobal = (id) => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_DIALOG_GLOBAL,
      dialogGlobal: id,
    });
  }
};

export const toggleComparison = (product) => (dispatch) => dispatch({
  type: TOGGLE_COMPARISON,
  product: product
});

export const addComparisonProduct = (product) => (dispatch) => dispatch({
  type: ADD_COMPARISON,
  product: product
});

export const removeComparisonProduct = (productId) => (dispatch) => dispatch({
  type: REMOVE_COMPARISON,
  productId: productId
});

export const toggleDialogComparison = () => (dispatch) => dispatch({
  type: TOGGLE_DIALOG_COMPARISON
});
