import React from 'react';

import { PageContainer } from 'src/components/Content/PageContainer';
import { Container } from 'src/components/Content/Container';
import { Button } from 'src/components/Content/Button';
import { Card } from 'src/components/Content/Card';
import { Text } from 'src/components/Content/Text';
import { GridContainer } from 'src/components/Content/Grid';
import { GridItem } from 'src/components/Content/Grid';
import { SliderBanner } from 'src/components/Content/SliderBanner';
import { SliderProduct } from 'src/components/Content/SliderProduct';
import { GridProducts } from 'src/components/Content/GridProducts';
import { Image } from 'src/components/Content/Image';
import { Box } from 'src/components/Content/Box';
import { InfoCard } from 'src/components/Content/InfoCard';
import { VideoBox } from 'src/components/Content/VideoBox';
import { Video } from 'src/components/Content/Video';
import { Image360 } from 'src/components/Content/Image360';
import { PanoramicImage } from 'src/components/Content/PanoramicImage';
import { Iframe } from 'src/components/Content/Iframe';
import { SliderBannerCustom, SliderBannerCustomItem } from 'src/components/Content/SliderBannerCustom';
import { Editor, Frame } from "@craftjs/core";

export function ContentView({ json, onRender }) {
    return (
        <Editor
            resolver={{
                PageContainer,
                Container,
                Button,
                Text,
                GridProducts,
                SliderBanner,
                SliderProduct,
                Card,
                Container,
                GridItem,
                GridContainer,
                Box,
                Image,
                InfoCard,
                VideoBox,
                Video,
                Image360,
                PanoramicImage,
                SliderBannerCustom,
                SliderBannerCustomItem,
                Iframe
            }}
            onRender={onRender}
        >
            <Frame data={ json } />
        </Editor>
    )
}