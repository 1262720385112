import React, { useState, useEffect } from 'react';

const useMobile = props => {

    const mediaNumber = props?.mediaNumber;

    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const minNumber = mediaNumber ? mediaNumber : 600

    const handleWindowSizeChange = _ => setWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(_ => {
        setIsMobile(Boolean((width <= minNumber)));
    }, [width])

    return {
        isMobile,
        windowWidth: width
    };

}

export default useMobile;