import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { removeMessage } from 'src/actions';
import { useSnackbar } from 'notistack';

// function Alert(props) {
//     return <MuiAlert elevation={2} variant="filled" {...props} />;
// }

export default function GlobalMessage() {
    const dispatch = useDispatch();

    const propsAlert = useSelector((state) => state.interface.globalMessage);
    const handleSnackbarClose = () => {
        dispatch(removeMessage());
    };

    const { enqueueSnackbar } = useSnackbar();

    const { open, severity, message } = propsAlert;

    open && enqueueSnackbar(message, {
        variant: severity || 'success',
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }
    });

    return (
        <></>
        // <Snackbar
        //     open={open}
        //     autoHideDuration={40000}
        //     onClose={handleSnackbarClose}
        // >
        //     {/* {severity !== '' ? */}
        //         <Alert onClose={handleSnackbarClose} severity={severity}>
        //             {message}
        //         </Alert>
        //      {/* : null} */}
        // </Snackbar>
    );
}