import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonAddToCart } from './ButtonAddToCart';

import {
    Box,
    Button,
    Checkbox
} from '@material-ui/core';

import { toggleComparison, toggleDialogComparison, addComparisonProduct } from 'src/actions';
import useFavorites from 'src/hooks/useFavorites';
import heart from 'src/theme/scss/icons/pink-heart.svg';
import heartChecked from 'src/theme/scss/icons/pink-heart-checked.svg';
import { Link as RouterLink } from "react-router-dom";
import { FeaturedPrice } from './ProductPrice';
import { ProductActions } from './ProductActions';

export function Footer({ product, hasDiscount }) {
    return (
        <Box>
            <FeaturedPrice
                product={product}
            />
            <ProductActions product={product} hasDiscount={hasDiscount} />
        </Box>
    )
}
