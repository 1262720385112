import React, { useEffect, useRef, useState } from 'react';
import { Link, Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    makeStyles,
    Button,
    Box,
    Container,
    Grid,
    useMediaQuery,
    useTheme,
    List,
    ListItem,
    ListItemText,
    Collapse,
    TextField,
    IconButton,
    Typography
} from '@material-ui/core';

import descubra from 'src/theme/scss/images/descobrir.png';
import mouse from 'src/theme/scss/images/vulcano1.png';
import cases from 'src/theme/scss/images/case.png';
import notebook from 'src/theme/scss/images/a65liv.png';
import hyb from 'src/theme/scss/images/hyb.png';
import mouses from 'src/theme/scss/images/mouses.png';
import redRightArrow from 'src/theme/scss/icons/red-right-arrow.svg';
import { ArrowForward, ExpandLess, ExpandMore } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    rootContainer: {
        padding: "0 !important"
    },
    divSubMenu: {
        // display: 'flex',
        // justifyContent: 'center',
        // top: '155px',
        // left: '0',
        // width: '100%',
    },
    divSubMenuContainer: {
        backgroundColor: '#222',
        boxShadow: '40px 0px rgb(0 0 0 / 15%)',
        left: '0',
        right: '0',
        overflow: 'hidden',
        position: 'absolute',
        top: '94px',
        width: '100%',
    },
    divSubMenuContainerFixed: {
        backgroundColor: '#222',
        boxShadow: '40px 0px rgb(0 0 0 / 15%)',
        left: '0',
        right: '0',
        overflow: 'hidden',
        position: 'absolute',
        top: '84px',
        width: '100%',
    },
    divSubMenuContainerOffers: {
        backgroundColor: 'transparent',
        boxShadow: '40px 0px rgb(0 0 0 / 15%)',
        left: '0',
        right: '0',
        overflow: 'hidden',
        position: 'absolute',
        top: '70px',
        width: '100%',
    },
    divSubMenuContainerOffersFixed: {
        backgroundColor: 'transparent',
        boxShadow: '40px 0px rgb(0 0 0 / 15%)',
        left: '0',
        right: '0',
        overflow: 'hidden',
        position: 'absolute',
        top: '62px',
        width: '100%',
    },
    offersBox: {
        margin: '0 auto',
        backgroundColor: '#222222',
        padding: '24px'
    },
    boxSubMenuContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    menuItems: {
        padding: '1.5rem 4rem 1.5rem 7.5rem',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            padding: '1.5rem 2rem 1.5rem 2rem',
        },
        ['@media (max-width:1749px)']: {
            padding: '1.5rem 8rem',
        },
        ['@media (max-width:1349px)']: {
            padding: '1.5rem 4rem',
        },
        ['@media (max-width:1199px)']: {
            padding: '1.5rem 2rem',
        },
        ['@media (max-width:1024px)']: {
            padding: '1.5rem',
        }
    },
    subItems: {
        paddingBottom: '1.5rem',
        paddingTop: '1.5rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 'calc(100vh - 176px)'
    },
    subItemsList: {
        padding: 0,
        '& > *': {
            padding: 0,
            paddingLeft: '26px',
            color: '#ffffff'
        },
        '& svg': {
            color: '#ff367d', // "#ed1c24"
        },
        '& a': {
            display: 'block',
            marginTop: '4px',
            marginBottom: '4px',
            color: '#ffffff',
            fontSize: '14px',
            textDecoration: 'none',
        }
    },
    collapse: {
        paddingBottom: '16px'
    },
    gridMenuItems: {
        flex: '1',
        justifyContent: 'space-between'
    },
    boxFeaturedImage: {

    }
}));

function Categories() {
    const classes = useStyles();
    const [translate, i18n] = useTranslation();
    const store = useSelector((state) => state.interface.store);
    const categories = useSelector((state) => state.interface.globalData.categoriesMenu);
    const attributes = useSelector((state) => state.interface.globalData.attributesMenu);
    const theme = useTheme();
    const mobileSize = (useMediaQuery(theme.breakpoints.up('md')));
    const showBoxFeaturedImage = (useMediaQuery('(min-width: 1750px)'));
    const isHomePage = window.location.pathname === '/';
    const [isHeaderActive, setIsHeaderActive] = useState(false);

    const [menu, setMenu] = useState(null);
    const [open, setOpen] = useState(null);

    const history = useHistory();

    const [gpuSubmenuIsOpen, setGpuSubmenuIsOpen] = useState(false);
    const [priceSubmenuIsOpen, setPriceSubmenuIsOpen] = useState(false);
    const [cpuSubmenuIsOpen, setCpuSubmenuIsOpen] = useState(false);
    const [screenSubmenuIsOpen, setScreenSubmenuIsOpen] = useState(false);

    const gpuGroup = attributes.find(attribute => attribute.code.includes('placa-de-video') && attribute.filterable);
    const priceGroup = attributes.find(attribute => attribute.code.includes('preco') && attribute.filterable);
    const cpuGroup = attributes.find(attribute => attribute.code.includes('processador-filtro') && attribute.filterable);
    const screenGroup = attributes.find(attribute => attribute.code.includes('tela') && attribute.filterable);

    const menuItemsRef = useRef();

    const handleOpen = (event, category) => {
        setOpen(category.id);
    };

    const handleClose = (event) => {
        setOpen(null);
    };

    function handleScroll(event) {
        const pagePosition = event.target.scrollTop
        setIsHeaderActive(pagePosition > 50);
    }

    useEffect(() => {
        const pageContent = document.getElementById('page-content');
        pageContent.addEventListener('scroll', handleScroll, true);
        return () => {
            pageContent.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function handleMouseLeaveCategory(event) {
        const hoveredElement = document.elementFromPoint(event.clientX, event.clientY);
        const isHoveringASubmenu = hoveredElement.isSameNode(menuItemsRef.current);
        if (!isHoveringASubmenu) {
            handleClose();
        }
    }

    const generateMenu = () => {
        let items = [];

        for (let key in menu) {
            let item = null
            let menuItem = menu[key];

            if (menuItem.url.indexOf('http') == 0) {
                item = (
                    <Grid item key={key}>
                        <Button
                            href={menuItem.url}
                            target="_blank"
                            className={`level-0 ${!isHomePage ? 'level-0--black' : ''}`}
                            onMouseEnter={(e) => { handleOpen(e, menuItem) }}
                            onMouseLeave={(e) => handleMouseLeaveCategory(e)}
                        >
                            {menuItem.name}
                        </Button>
                    </Grid>
                );
            }
            else {
                item = (
                    <Grid item key={key}>
                        <Button component={RouterLink}
                            to={menuItem.url}
                            className={`level-0 ${!isHomePage ? 'level-0--black' : ''}`}
                            onMouseEnter={(e) => { handleOpen(e, menuItem) }}
                            onMouseLeave={(e) => handleMouseLeaveCategory(e)}
                        >
                            {menuItem.name}
                        </Button>
                    </Grid>
                );
            }

            items.push(item);
        }
        return items;
    }

    const featureadImages = {
        notebooks: {
            image: hyb,
            url: '/notebooks'
        },
        acessorios: {
            image: mouses,
            url: '/acessorios/mouses'
        },
    }

    function handleSelectAttribute(attributeId, optionId) {
        const attributes = {};
        attributes[attributeId] = optionId;

        let facet_filters = Buffer.from(JSON.stringify({ attributes, page: 1, sortOrder: { finalPrice: "ASC" } })).toString('base64');

        history.push(
            `/notebooks?facet_filters=${facet_filters}&from_menu=true`
        );
    }

    const hiddenItems = ['youtubers', 'youtuber', 'mochilas', 'pen-drive', 'mesa-digitalizadora', 'mouse-pad', 'limpa-tela'];

    const generateSubMenu = () => {
        if (open === null) {
            return (<></>)
        }

        let items = [];
        let menuItem = menu[open];

        if (!menuItem || !menuItem.children.length) {
            return (<></>)
        }

        if (menuItem.slug === 'notebooks') {

            menuItem.children.map((child) => {

                let subItems = [];

                child.children.map((subItem) => {
                    if (!hiddenItems.includes(subItem.slug)) {
                        subItems.push(
                            <Grid item xs={12}>
                                <Button component={RouterLink}
                                    to={`/${subItem.url}`}
                                    className="level-2"
                                >
                                    {subItem.name}
                                </Button>
                            </Grid>
                        );
                    }
                });

                items.push(
                    <Grid item className="grid-level-1" xs={3}>
                        <Button component={RouterLink}
                            to={`/${child.url}`}
                            className="level-1"
                        >
                            <img src={redRightArrow} className="level-1-arrow" />
                            <div>{child.name}</div>
                        </Button>
                        <Box className={classes.subItems}>
                            <Grid container spacing={1}>
                                {subItems}
                            </Grid>
                        </Box>
                    </Grid>
                );
            });

            items.push(
                <Grid item className="grid-level-1" xs={3}>
                    <Button component={RouterLink} to={`/`} className="level-1">
                        <img src={redRightArrow} className="level-1-arrow" />
                        <div>Por Características</div>
                    </Button>
                    <Box className={classes.subItems}>
                        <List className={classes.subItemsList}>

                            {gpuGroup?.options.length > 0 && (
                                <>
                                    <ListItem button onClick={() => setGpuSubmenuIsOpen(!gpuSubmenuIsOpen)}>
                                        <ListItemText>Placa de vídeo</ListItemText>
                                        {gpuSubmenuIsOpen ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                                    </ListItem>
                                    <Collapse in={gpuSubmenuIsOpen} timeout="auto" unmountOnExit className={classes.collapse}>
                                        <List component="div" className={classes.subItemsList}>
                                            {gpuGroup?.options.map(option => (
                                                <ListItem button key={option.id}>
                                                    <ListItemText onClick={() => handleSelectAttribute(gpuGroup.id, option.id)}>{option.name}</ListItemText>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}

                            {priceGroup?.options.length > 0 && (
                                <>
                                    <ListItem button onClick={() => setPriceSubmenuIsOpen(!priceSubmenuIsOpen)}>
                                        <ListItemText>Preço</ListItemText>
                                        {priceSubmenuIsOpen ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                                    </ListItem>
                                    <Collapse in={priceSubmenuIsOpen} timeout="auto" unmountOnExit className={classes.collapse}>
                                        <List component="div" className={classes.subItemsList}>
                                            {priceGroup?.options.map(option => (
                                                <ListItem button key={option.id}>
                                                    <ListItemText onClick={() => handleSelectAttribute(priceGroup.id, option.id)}>{option.name}</ListItemText>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}

                            {cpuGroup?.options.length > 0 && (
                                <>
                                    <ListItem button onClick={() => setCpuSubmenuIsOpen(!cpuSubmenuIsOpen)}>
                                        <ListItemText>Processador</ListItemText>
                                        {cpuSubmenuIsOpen ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                                    </ListItem>
                                    <Collapse in={cpuSubmenuIsOpen} timeout="auto" unmountOnExit className={classes.collapse}>
                                        <List component="div" className={classes.subItemsList}>
                                            {cpuGroup?.options.map(option => (
                                                <ListItem button key={option.id}>
                                                    <ListItemText onClick={() => handleSelectAttribute(cpuGroup.id, option.id)}>{option.name}</ListItemText>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}

                            {screenGroup?.options.length > 0 && (
                                <>
                                    <ListItem button onClick={() => setScreenSubmenuIsOpen(!screenSubmenuIsOpen)}>
                                        <ListItemText>Tamanho de tela</ListItemText>
                                        {screenSubmenuIsOpen ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                                    </ListItem>
                                    <Collapse in={screenSubmenuIsOpen} timeout="auto" unmountOnExit className={classes.collapse}>
                                        <List component="div" className={classes.subItemsList}>
                                            {screenGroup?.options.map(option => (
                                                <ListItem button key={option.id}>
                                                    <ListItemText onClick={() => handleSelectAttribute(screenGroup.id, option.id)}>{option.name}</ListItemText>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                </>
                            )}


                        </List>
                    </Box>
                </Grid>
            );

            items.push(
                <Grid item className="grid-level-1" xs={3}>
                    {/* <Box mb={2}>
                        <Link to="/wizard">
                            <img src={descubra} className="descubra" />
                        </Link>
                    </Box> */}
                    <Button component={RouterLink}
                        to={`/${menuItem.slug}`}
                        className="level-1"
                    >
                        <img src={redRightArrow} className="level-1-arrow" />
                        <div>Veja todos notebooks</div>
                    </Button>
                </Grid>
            );
        }
        else if (menuItem.slug === 'acessorios') {
            let subItems = [];
            let products = [];

            menuItem.children.map((subItem) => {
                if (menuItem.slug === 'acessorios' && !hiddenItems.includes(subItem.slug)) {
                    subItems.push(
                        <Grid item xs={12}>
                            <Button component={RouterLink}
                                to={`/${subItem.url}`}
                                className="level-2"
                            >
                                {subItem.name}
                            </Button>
                        </Grid>
                    );

                    if (subItem.slug == 'mouses') {
                        products.push(
                            <Box item className="product" xs={3}>
                                <RouterLink to={`/${subItem.url}`}>
                                    <img src={mouse} className="product-image" />
                                </RouterLink>
                                <Button component={RouterLink}
                                    to={`/${subItem.url}`}
                                    className="level-1"
                                >
                                    <div>{subItem.name}</div>
                                </Button>
                            </Box>
                        );
                    }

                    if (subItem.slug == 'cases') {
                        products.push(
                            <Box item className="product" xs={3}>
                                <img src={cases} className="product-image" />
                                <Button component={RouterLink}
                                    to={`/${subItem.url}`}
                                    className="level-1"
                                >
                                    <div>{subItem.name}</div>
                                </Button>
                            </Box>
                        );
                    }
                }
            });

            items.push(
                <Grid item className="grid-level-1" xs={3}>
                    <Button component={RouterLink}
                        to={`/${menuItem.url}`}
                        className="level-1"
                    >
                        <img src={redRightArrow} className="level-1-arrow" />
                        <div>{menuItem.name}</div>
                    </Button>
                    <Box className={classes.subItems}>
                        <Grid container spacing={1}>
                            {subItems}
                        </Grid>
                    </Box>
                </Grid>
            );

            items.push(
                <Grid className="grid-level-1 products">
                    {products}
                </Grid>
            );
        }
        else {
            items.push(
                <Box className={classes.offersBox}>
                    {menuItem.children.map(child => (
                        <Box key={child.slug}>
                            <Button component={RouterLink}
                                to={`/${child.url}`}
                                className="level-1"
                            >
                                <img src={redRightArrow} className="level-1-arrow" />
                                <div>{child.name}</div>
                            </Button>
                        </Box>
                    ))}
                </Box>
            );
        }

        const menuItems = (
            <>
                <Box className={classes.menuItems} ref={menuItemsRef}>
                    <Grid
                        className={classes.gridMenuItems}
                        container
                    >
                        {items}
                    </Grid>
                </Box>
                {featureadImages[menuItem.slug] && showBoxFeaturedImage &&
                    <Box className={classes.boxFeaturedImage}>
                        <RouterLink to={featureadImages[menuItem.slug].url}>
                            <img src={featureadImages[menuItem.slug].image} className="featuredImage" />
                        </RouterLink>
                    </Box>
                }
            </>
        );

        let submenuContainerClassName = isHeaderActive ? classes.divSubMenuContainerFixed : classes.divSubMenuContainer;

        if (menuItem.slug === 'ofertas') {
            submenuContainerClassName = isHeaderActive ? classes.divSubMenuContainerOffersFixed : classes.divSubMenuContainerOffers;
        }

        return (
            <div className={classes.divSubMenu} onMouseLeave={handleClose}>
                <div className={submenuContainerClassName}>
                    <Box className={menuItem.slug}>
                        <Box className={classes.boxSubMenuContainer}>
                            {menuItems}
                        </Box>
                    </Box>
                </div>
            </div>
        );
    }

    useEffect(() => {
        let menu = [];

        categories.map((category, index) => {
            if (category.url !== 'notebooks') return;

            if (!menu[category.id] && !category.parent) {
                menu[category.id] = {
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    slug: category.slug,
                    url: '/' + category.url,
                    children: []
                };
            }
        });
        categories.map((category, index) => {
            if (category.parent && menu[category.parent.id]) {
                menu[category.parent.id]['children'].push({
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    slug: category.slug,
                    url: '/' + category.url,
                    children: category.children
                });
            }
        });
        for (let key in menu) {
            const category = menu[key];
            const categoryItems = category.children;
            const sortByName = (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            if (categoryItems) {
                categoryItems.sort(sortByName);
                categoryItems.forEach(categoryItem => {
                    const subcategoryItem = categoryItem.children;
                    if (subcategoryItem) {
                        subcategoryItem.sort(sortByName)
                    }
                })
            }
        }

        store.menuItem.forEach(item => {

            if (item.url.indexOf('http') < 0 && item.url.indexOf('/') !== 0) {
                item.url = '/' + item.url;
            }

            menu[item.id] = {
                id: item.id,
                name: item.title,
                description: '',
                slug: item.id,
                url: item.url,
                children: []
            };

            item.children.forEach(itemChild => {

                if (itemChild.url.indexOf('http') < 0 && itemChild.url.indexOf('/') !== 0) {
                    itemChild.url = '/' + itemChild.url;
                }

                menu[item.id].children.push({
                    id: itemChild.id,
                    name: itemChild.title,
                    description: '',
                    slug: itemChild.id,
                    url: itemChild.url,
                    children: []
                });
            })
        });

        setMenu(menu);
    }, [categories]);

    return (
        <Box className="menu-categories">
            {
                mobileSize ? (
                    <Box className={classes.root}>
                        <Container maxWidth="lg" className={classes.rootContainer}>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                            >
                                {generateMenu()}
                            </Grid>
                        </Container>
                        {generateSubMenu()}
                    </Box>
                ) : (
                    <></>
                )
            }
        </Box>
    );
}

Categories.propTypes = {
    className: PropTypes.string
};

export default Categories;
