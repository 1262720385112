import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Menu as MenuIcon,
} from 'react-feather';
import {
    AppBar,
    Box,
    Container,
    Divider,
    Toolbar,
    makeStyles,
    IconButton,
    useTheme,
    useMediaQuery,
    Grid,
    Button,
    Drawer,
    ListItem,
    ListItemText
} from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { logout, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import logo from 'src/theme/scss/icons/logoNewWhite.svg';
import Close from '@material-ui/icons/Close';
import BlogTopMenu from './Menu/BlogTopMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        padding: '30px 0',
        background: 'linear-gradient(180deg, #000000 -22.67%, rgba(0, 0, 0, 0) 92.67%) !important',
        marginTop: '24px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
        }
    },
    toolbar: {
        width: '100%',
        height: 70,
        padding: 0,
        justifyContent: "space-between",
    },
    toolbarMobile: {
        justifyContent: "center",
        padding: 0,
    },
    logoContainer: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1
        },
    },
    logoImage: {
        marginRight: theme.spacing(2),
        height: 44,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 100,
        },
    },
    divider: {
        width: 1,
        height: 64,
    },
    searchForm: {
        flex: 1,
        marginRight: theme.spacing(2)
    },
    searchInput: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        padding: '8px',
        borderRadius: '10px',
        width: '100%',
    },
    iconButton: {
        color: '#ffffff',
        padding: 10,
        background: 'transparent',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 50
        },
    },
    iconLogin: {
        borderRadius: '0%',
        height: 64,
    },
    poper: {
        zIndex: '99999999',
        width: '200px'
    },
    dividerAccount: {
        width: '100%',
    },
    styleIconButton: {
        borderRadius: '0',
        width: '100%'
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    mobileContainer: {
        paddingRight: 0
    },
    menu: {
        width: '100%',
        maxHeight: '400px',
        overflow: 'auto',
        zIndex: '99999',
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0px 1px 5px 1px #DDD'
    },
    typographyMenu: {
        textDecoration: 'none',
        color: '#263238',
    },
    divMenu: {
        width: '100%'
    }
}));

function BlogMenu({ className, ...rest }) {
    const store = useSelector((state) => state.interface.store);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const cartRef = useRef(null);

    const [openDrawer, setOpenDrawer] = useState(null);

    const [openMenu, setOpenMenu] = useState(false);

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [searchCategories, setSearchCategories] = useState([]);
    const [searchProducts, setSearchProducts] = useState([]);
    const [translate, i18n] = useTranslation();

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const userAccount = useSelector((state) => state.account.user);
    const cartOrder = useSelector((state) => state.cart.order);

    let quantity = 0

    if (cartOrder.items.length > 1) {
        quantity = cartOrder.items.map(item => parseInt(item.quantity));
    } else if (cartOrder.items.length === 1) {
        quantity = cartOrder.items.map(item => item.quantity);
    }

    let quantityOrderCart = quantity.length > 0 ? quantity.reduce((acc, current) => acc + current) : 0;

    const showMenuAccount = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const logoutUser = () => {
        dispatch(logout());
        setOpen(false);
        history.push('/');
    }

    const handleCloseMenuAccount = () => {
        setOpen(false);
    }

    const handleDrawerOpen = (drawerId) => {
        if (openDrawer === drawerId) {
            drawerId = null
        }
        setOpenDrawer(drawerId)
    };

    const handleDrawerClose = () => {
        setOpenDrawer(null);
    };

    const handleModalOpen = () => {
        !openModal ? setOpenModal(true) : setOpenModal(false);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {

        if (quantityOrderCart > 0) {
            if (cartRef.current) {
                cartRef.current.className = cartRef.current.className + ' shopping-cart';
            }
        }

        setTimeout(() => {
            if (cartRef.current) {
                cartRef.current.className = 'MuiBadge-root';
            }
        }, 1000);

    }, [cartOrder.items]);

    return (
        <>
            <BlogTopMenu />
            <AppBar
                className={clsx(classes.root, className)}
                color="default"
                {...rest}
            >
                <Box className="main-header">
                    <Container maxWidth={false}>
                        <Grid 
                            className={mobileSize ? classes.mobileContainer : null} 
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Toolbar className={classes.toolbar}>
                                <Box className={classes.logoContainer}>
                                    <RouterLink to="/blog">
                                        <img
                                            alt={ store.name }
                                            src={ logo }
                                            className={classes.logoImage}
                                        />
                                    </RouterLink>
                                </Box>
                                {mobileSize ? (
                                    <></>
                                ) : (
                                    <Box className="blog-menu">
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Button component={RouterLink} to="/blog">
                                                    BLOG
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button component={RouterLink} to="/">
                                                    NOSSO SITE
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button component={RouterLink} to="/central-de-vendas">
                                                    FALE CONOSCO
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                {mobileSize && (
                                    <>
                                        <IconButton onClick={() => handleDrawerOpen('menu-itens')} className={classes.iconButton + ' ' + classes.iconLogin}>
                                            <MenuIcon size='24px' />
                                        </IconButton>
                                        <Drawer 
                                            anchor="right"
                                            open={openDrawer}
                                            onClose={handleDrawerClose}
                                            className="drawer-blog">
                                            <Box className={classes.drawerContainer}>
                                                <Box
                                                    className="drawerHeader"
                                                    width="100%"
                                                    display="flex"
                                                    alignItems="right"
                                                >
                                                    <Box mr={1} className="box-close">
                                                        <IconButton onClick={handleDrawerClose}>
                                                            {openDrawer ? <Close style={{ color: 'white' }} /> : <Close style={{ color: 'white' }} />}
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                                <Box className="menu-categories mobile">
                                                    <Grid container> 
                                                        <Grid item xs={12} className="grid-item links">
                                                        <ListItem
                                                                button
                                                                component={RouterLink}
                                                                to="/blog"
                                                                onClick={handleDrawerClose}
                                                                className="list-item"
                                                            >
                                                                <ListItemText primary="BLOG" className='level-0' />
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                component={RouterLink}
                                                                to="/"
                                                                onClick={handleDrawerClose}
                                                                className="list-item"
                                                            >
                                                                <ListItemText primary="NOSSO SITE" className='level-0' />
                                                            </ListItem>
                                                            <ListItem
                                                                button
                                                                component={RouterLink}
                                                                to="/central-de-vendas"
                                                                onClick={handleDrawerClose}
                                                                className="list-item"
                                                            >
                                                                <ListItemText primary="FALE CONOSCO" className='level-0' />
                                                            </ListItem>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Drawer>
                                    </>
                                )}
                            </Toolbar>
                        </Grid>
                    </Container>
                </Box>
            </AppBar>
        </>
    );
}

BlogMenu.propTypes = {
    className: PropTypes.string
};

export default BlogMenu;
