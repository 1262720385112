import React from "react";
import { Button as MaterialButton, Box, useTheme, useMediaQuery } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';

import { getBoxMarginProps, getBreakpointValue, getColor } from './functions';

export const Button = (props) => {

	const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

    const {
        size,
        variant,
        color,
        text,
        customColors={}
    } = props;

    const buttonProps = {
		size: getBreakpointValue(props, breakpoint, 'size'),
		variant,
		color,
	}

    if(getBreakpointValue(props, breakpoint, 'maxContent', null, false)) {
        buttonProps.style = {
            width: '100%'
        }
    }

	if(Object.keys(customColors).length) {
        if(!buttonProps.style) {
            buttonProps.style = {};
        }

		if(Object.entries(customColors)) {
            if(customColors.color)
                buttonProps.style.color = getColor(customColors.color);
            if(customColors.backgroundColor) {
                if(variant === 'contained') {
                    buttonProps.style.backgroundColor = getColor(customColors.backgroundColor);
                }
                buttonProps.style.borderColor = getColor(customColors.backgroundColor);
            }
        }
	}

	if(props.href || props.link) {
		const href = props.href || props.link;
		
		const isRouterUrl = (href.indexOf("://") < 0);
		if(isRouterUrl) {
			buttonProps.component = RouterLink;
			buttonProps.to = href;
		}
		else {
			buttonProps.href = href;
			buttonProps.target = '_blank';
		}
	}

    return (
        <Box
            {...getBoxMarginProps(props, breakpoint)}
            textAlign={getBreakpointValue(props, breakpoint, 'textAlign')}
        >
            <MaterialButton {...buttonProps}>
                {text}
            </MaterialButton>
        </Box>
    );
};