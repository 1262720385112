import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    makeStyles,
} from '@material-ui/core';
import { clearCart } from 'src/actions';
import BlogMenu from 'src/layouts/MainLayout/Topbar/BlogMenu';
import Footer from 'src/layouts/MainLayout/Footer';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f4f4f4',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    contentContainer: {
        display: 'fle;x',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
    }
}));

function MainLayout({ children }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const store = useSelector((state) => state.interface.store);
    const cart = useSelector((state) => state.cart);

    if (cart.order.status !== 'new') {
        dispatch(clearCart());
    };

    var customStyle = {};

    if (store.name == "Vinícola Vivalti") {
        customStyle = { backgroundImage: "url('/static/vivalti/bg.jpg')" }
    }

    return (
        <div className={classes.root}>
            <BlogMenu />
            <div className={clsx(classes.wrapper, (store.interface.hasCategoriesMenu ? classes.wrapperTwoLines : null))}>
                <div className={classes.contentContainer}>
                    <div id="page-content" className={classes.content} style={customStyle}>
                        {children}
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

MainLayout.propTypes = {
    children: PropTypes.any
};

export default MainLayout;
