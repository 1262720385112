import React from "react";

import {
  Box,
  useTheme,
  useMediaQuery
} from "@material-ui/core";

import { Pannellum } from "pannellum-react";
import { getBoxMarginProps, getBreakpointValue } from "./functions";

export const PanoramicImage = (props) => {

  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

  return (
    <Box 
      {...getBoxMarginProps(props, breakpoint)}
    >
      {props.image.url &&
        <Pannellum
          width="100%"
          height={`${getBreakpointValue(props, breakpoint, 'height')}px`}
          image={props.image.url}
          pitch={10}
          yaw={180}
          hfov={110}
          autoLoad
        />
      }
    </Box>
  )
};