import React from 'react';
import { useSelector } from 'react-redux';
import logo from 'src/theme/scss/icons/logoNewWhite.svg';

function Logo(props) {

    const store = useSelector((state) => state.interface.store);

    if(!store.name) {
        return <></>;
    }

    return (
        // <img
        //     alt={ store.name }
        //     src={ store.logo }
        //     {...props}
        // />
        <img
            alt={ store.name }
            src={ logo }
            {...props}
        />
    );
}

export default Logo;
