import Dexie from 'dexie';

class DatabaseManager {
    db = null;

    constructor() {
        this.db = new Dexie('avell');
        this.db.version(1).stores({
            favorites: "++id, productId, productName, price"
        });
    }

    addFavoriteProduct(product) {
        return this.db.favorites.put({
            productId: product.id,
            productName: product.name,
            price: product.finalPrice,
            product
        });
    }

    removeFavoriteByProductId(productId) {
        return this.db.favorites.where({ productId }).delete();
    }

    removeFavoriteById(id) {
        return this.db.favorites.where({ id }).delete();
    }

    getFavoritesById(id) {
        return this.db.favorites.where({ id }).first();
    }
    getFavoriteByProductId(productId) {
        return this.db.favorites.where({ productId }).first();
    }

    getFavoriteByProductIds(ids) {
        return this.db.favorites.where("productId").anyOf(ids).toArray();
    }

    getAllFavorites() {
        return this.db.favorites.toArray();
    }

    getAllFavoritesByFilter(filter) {
        if (filter.sortOrder && filter.sortOrder === 'ascName') {
            return this.db.favorites.toCollection().sortBy('productName');

        } else if (filter.sortOrder && filter.sortOrder === 'descName') {
            return this.db.favorites.toCollection().reverse().sortBy('productName');

        } else if (filter.sortOrder && filter.sortOrder === 'ascPrice') {
            return this.db.favorites.toCollection().reverse().sortBy('price');

        } else if (filter.sortOrder && filter.sortOrder === 'descPrice') {
            return this.db.favorites.toCollection().sortBy('price');

        }
        return this.db.favorites.toArray();
    }

    async verifyBrowserSuport() {
        try {
            const db = new Dexie('test');
            await db.version(1).stores({
                images: "++id, image"
            });
            await db.images.add({
                image: 'test'
            });
            await db.delete();

            return true;

        }
        catch (e) {
            return false;
        }
    }
}

export default (new DatabaseManager());