import { getCartBundlePrice } from 'src/utils/functions';

function formatProduct(source, product, quantity) {
    if (source === 'google') {
        product = {
            item_id: product.sku,
            item_name: product.name,
            quantity,
            price: product.price
        };
    }
    if (source === 'facebook') {
        product = {
            content_name: product.name,
            content_ids: [product.sku],
            content_type: 'product',
            value: product.price,
            currency: 'BRL'
        }
    }
    if (source === 'criteo') {
        product = {
            id: product.sku,
            price: String(product.price),
            quantity: String(quantity)
        }
    }
    return product;
}

function trackGoogleAnalytics(...args) {
    if (process.env.NODE_ENV !== 'production' || !window.hasGAnalytics) {
        return;
    }
    if (!window.gtag) {
        setTimeout(() => {
            trackGoogleAnalytics(...args);
        }, 500);
        return;
    }
    window.gtag(...args);
}

export function trackDataLayer(...args) {
    if (process.env.NODE_ENV !== 'production' || !window.dataLayer) {
        return;
    }
    window.dataLayer.push(...args)
}

function trackCriteo({ customer = {}, args }) {
    // Avell solicitou a inativação da Criteo no código
    return;
    if (process.env.NODE_ENV !== 'production' || !window.criteo_q) {
        return;
    }

    const deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";

    customer = {
        email: customer.email ?? '',
        zipcode: Array.isArray(customer.addresses) && customer.addresses.length ? customer.addresses[0].zipcode : ''
    }

    window.criteo_q.push(
        { event: "setAccount", account: 80169 },
        {
            event: "setEmail",
            email: customer.email,
            hash_method: "none"
        },
        { event: "setSiteType", type: deviceType },
        {
            event: "setZipcode",
            zipcode: customer.zipcode
        },
        ...args
    );
}

function trackFacebookPixel(...args) {
    if (process.env.NODE_ENV !== 'production' || !window.hasFPixel) {
        return;
    }
    if (!window.fbq) {
        setTimeout(() => {
            trackFacebookPixel(...args);
        }, 500);
        return;
    }

    window.fbq(...args);
}

export function trackLogin(method) {
    trackGoogleAnalytics('event', 'login', {
        method: method
    });
}

export function trackSignUp(method) {
    trackGoogleAnalytics('event', 'sign_up', {
        method: method
    });
}

export function trackSearch(term) {
    trackGoogleAnalytics('event', 'search', {
        search_term: term
    });
}

export function trackPageview({ title, path, ...params }) {
    const customer = params?.customer ?? {};

    trackGoogleAnalytics('event', 'page_view', {
        page_title: title,
        page_path: path,
        customer: {
            email: customer.email ?? ''
        }
    });

    trackFacebookPixel('track', 'PageView');

    const isHomePage = path === '/' || path === '' || path === '/home';

    trackCriteo({
        customer,
        args: [
            { event: isHomePage ? "viewHome" : "viewPage" }
        ]
    })
}

export function trackWishlist(product) {
    const productData = formatProduct('google', product, 1);
    const categories = product.categories;
    if (categories.length) {
        categories.map((category, index) => {
            const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
            productData[categoryName] = category.category.name;
        });
    }
    productData['item_brand'] = 'Avell';

    trackGoogleAnalytics('event', 'add_to_wishlist', {
        currency: "BRL",
        value: product.price,
        items: [productData]
    });
}

export function trackCartAction({ action, product, quantity, ...params }) {
    const productData = formatProduct('google', product, quantity);
    productData['item_brand'] = 'Avell';

    if (action == 'add_to_cart') {
        const categories = product.categories;
        if (categories.length) {
            categories.map((category, index) => {
                const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                productData[categoryName] = category.name ?? category.category.name;
            });
        }

        trackGoogleAnalytics('event', 'add_to_cart', {
            currency: "BRL",
            value: params.order.totalPrice ?? params.order.totalFinal,
            items: [productData]
        });

        trackFacebookPixel('track', 'AddToCart', formatProduct('facebook', product, quantity))

        trackCriteo({
            customer: params.customer ?? {},
            args: [
                {
                    event: "addToCart", item: [
                        { id: product.sku, price: product.price, quantity }
                    ]
                }
            ]
        })
    }

    if (action == 'remove_from_cart') {
        const categories = product.categories;
        if (categories.length) {
            categories.map((category, index) => {
                const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                productData[categoryName] = category.name;
            });
        }

        trackGoogleAnalytics('event', 'remove_from_cart', {
            currency: "BRL",
            value: params.order.totalPrice ?? params.order.totalFinal,
            items: [productData]
        });
    }
}

export function trackBeginCheckout(order) {
    const products = order.items ?? order.products;
    const items = products
        .filter(item => !item.parent)
        .map(item => {
            const categories = item.categories;

            const product = formatProduct('google', item, item.quantity);

            product['item_brand'] = 'Avell';

            if (categories.length) {
                categories.map((category, index) => {
                    const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                    product[categoryName] = category.name;
                });
            }

            let price = product.price;

            if (item.type == 'bundle')
                price = getCartBundlePrice(item, products);

            product.price = price;

            return product;
        });

    trackGoogleAnalytics('event', 'begin_checkout', {
        currency: "BRL",
        value: order.totalPrice ?? order.totalFinal,
        coupon: order.coupon,
        items: items
    });
}

export function trackShippingCheckout(order, method) {
    const products = order.items ?? order.products;
    const items = products
        .filter(item => !item.parent)
        .map(item => {
            const categories = item.categories;

            const product = formatProduct('google', item, item.quantity);

            product['item_brand'] = 'Avell';

            if (categories.length) {
                categories.map((category, index) => {
                    const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                    product[categoryName] = category.name;
                });
            }

            let price = product.price;

            if (item.type == 'bundle')
                price = getCartBundlePrice(item, products);

            product.price = price;

            return product;
        });

    trackGoogleAnalytics('event', 'add_shipping_info', {
        currency: "BRL",
        value: method.total,
        coupon: order.coupon,
        items: items
    });
}

export function trackShippingEstimate() {
    trackGoogleAnalytics('event', 'estimate_shipping');
    trackFacebookPixel('track', 'EstimateShipping');
}

export function trackPaymentCheckout(order, paymentType) {
    const products = order.items ?? order.products;
    const items = products
        .filter(item => !item.parent)
        .map(item => {
            const categories = item.categories;

            const product = formatProduct('google', item, item.quantity);

            product['item_brand'] = 'Avell';

            if (categories.length) {
                categories.map((category, index) => {
                    const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                    product[categoryName] = category.name;
                });
            }

            let price = product.price;

            if (item.type == 'bundle')
                price = getCartBundlePrice(item, products);

            product.price = price;

            return product;
        });

    trackGoogleAnalytics('event', 'add_payment_info', {
        currency: "BRL",
        value: order.totalFinal ?? order.totalPrice,
        coupon: order.coupon,
        payment_type: paymentType,
        items: items
    });
}

export function trackPurchase(order) {
    const products = order.items ?? order.products;

    const items = products
        .filter(item => !item.parent)
        .map(item => {
            const categories = item.categories;

            const product = formatProduct('google', item, item.quantity);

            product['item_brand'] = 'Avell';

            if (categories.length) {
                categories.map((category, index) => {
                    const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                    product[categoryName] = category.name;
                });
            }

            let price = product.price;

            if (item.type == 'bundle')
                price = getCartBundlePrice(item, products);

            product.price = price;

            return product;
        });

    trackGoogleAnalytics('event', 'purchase', {
        currency: "BRL",
        transaction_id: order.id,
        value: order.totalFinal ?? order.totalPrice,
        tax: order.totalTax,
        // email: order.customerEmail,
        shipping: order.totalShipping ?? order.shipment.total,
        items: items,
        // customer: {
        //   email: order.customer.email
        // }
    });

    trackFacebookPixel('track', 'Purchase', {
        currency: "BRL",
        value: order.totalPrice ?? order.totalFinal,
    });

    trackCriteo({
        customer: order.customer,
        args: [
            {
                event: "trackTransaction",
                id: order.id,
                item: products.map(item => (formatProduct('criteo', item, item.quantity)))
            }
        ]
    })
}

export function trackProductView({ product, ...params }) {
    const productData = formatProduct('google', product, 1);
    const categories = product.categories;
    if (categories.length) {
        categories.map((category, index) => {
            const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
            productData[categoryName] = category.category.name;
        });
    }
    productData['item_brand'] = 'Avell';

    trackGoogleAnalytics('event', 'view_item', {
        currency: "BRL",
        value: product.price,
        items: [productData]
    });

    trackFacebookPixel('track', 'ViewContent', formatProduct('facebook', product, 1));

    trackCriteo({
        customer: params?.customer ?? {},
        args: [
            { event: "viewItem", item: product.id }
        ]
    })
}

export function trackProductListView(products, itemListId, itemListName) {
    let items = [];
    if (products) {
        items = products
            .filter(item => !item.parent)
            .map(item => {
                const categories = item.categories;

                const product = formatProduct('google', item, item.quantity);

                product['item_brand'] = 'Avell';

                if (categories.length) {
                    categories.map((category, index) => {
                        const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                        product[categoryName] = category.category.name;
                    });
                }

                let price = product.price;

                if (item.type == 'bundle')
                    price = getCartBundlePrice(item, products);

                product.price = price;

                return product;
            });
    }

    trackGoogleAnalytics('event', 'view_item_list', {
        item_list_id: itemListId,
        item_list_name: itemListName,
        items: items
    });
}

export function trackViewCart({ order, customer }) {
    const products = order.items ?? order.products;

    const itemsGoogle = products
        .filter(item => !item.parent)
        .map(item => {
            const categories = item.categories;

            const product = formatProduct('google', item, item.quantity);

            product['item_brand'] = 'Avell';

            if (categories.length) {
                categories.map((category, index) => {
                    const categoryName = index == 0 ? 'item_category' : `item_category${index}`;
                    product[categoryName] = category.name;
                });
            }

            let price = product.price;

            if (item.type == 'bundle')
                price = getCartBundlePrice(item, products);

            product.price = price;

            return product;
        });

    const items = products
        .filter(item => !item.parent)
        .map(item => {
            let product = {};
            let price = 0;

            product = formatProduct('criteo', item, item.quantity)

            if (item.type == 'bundle') {
                price = getCartBundlePrice(item, products);
                product.price = price;
            }

            return product;
        });

    trackGoogleAnalytics('event', 'view_cart', {
        currency: "BRL",
        value: order.totalPrice ?? order.totalFinal,
        items: itemsGoogle
    });

    trackCriteo({
        customer: customer ?? {},
        args: [
            {
                event: "viewBasket",
                item: items
            }
        ]
    })
}
