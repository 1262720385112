import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  makeStyles,
  Box,
  Typography,
  Input,
  FormControl,
  FormLabel,
  Switch,
  FormHelperText,
  InputAdornment,
  Divider,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { login } from 'src/actions/accountActions';
import { addMessage, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import ClassUserLogin from 'src/models/UserLoginModels';
import { useMask } from 'src/hooks/useMask';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Icon } from '@material-ui/core';

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

import imgFacebook from "src/theme/scss/icons/social/facebook.svg";
import imgGoogle from "src/theme/scss/icons/social/google.svg";
import { trackLogin } from 'src/utils/analytics';

let classUserLogin = new ClassUserLogin();

const defaultFormShape = classUserLogin.getObjects;

const useStyles = makeStyles(theme => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#333333',
    width: 500,
    padding: 32,
    '@media (max-width: 900px)': {
      width: '100%'
    },
    '& .MuiButtonBase-root:after': {
      content: 'initial !important'
    }
  },
  divider: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    marginTop: '-10px'
  },
  socialMediaTitle: {
    fontSize: 12,
    color: '#FFF',
    display: 'inline-block',
    backgroundColor: '#333333',
    padding: '0 10px'
  },
  loginOption: {
    padding: 4,
    border: '2px solid #ffffff',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    '& p': {
      color: 'inherit',
      marginTop: '-2px'
    },
    '& .MuiButtonBase-root': {
      position: 'absolute !important'
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#e4e4e4 !important'
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#808080 !important'
    }
  },
  form: {
    '& .MuiFormLabel-root': {
      color: '#C9C9C9'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#db1842'
    },
    '& .btnForgetPass': {
      fontSize: 12,
      cursor: 'pointer'
    },
    '& .MuiButtonBase-root': {
      fontSize: '12px !important',
      textTransform: 'uppercase !important',
      fontFamily: 'Roboto, sans-serif !important'
    },
    '& .btnLogin': {
      width: '100%',
      padding: 8,
      border: '2px solid #ffffff',
      borderRadius: 5,
      '& span': {
        color: '#ffffff',
      }
    },
    '& .registerAction': {
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiButtonBase-root': {
        fontSize: '12px !important',
        textTransform: 'none'
      },
      '& span': {
        color: '#ffffff',
      }
    }
  },
  buttonResetPassword: {
    color: '#db1842',
    borderColor: '#db1842'
  }
}));

export function LoginModal({ close }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const recoveryPasswordApi = new ManagerApi('/customer/recovery');
  const [recoverPassword, setRecoverPassword] = useState(false);
  const [loginByDocument, setLoginByDocument] = useState(false);
  const [translate] = useTranslation();
  const store = useSelector((state) => state.interface.store);
  const history = useHistory();

  const { dinamicMaskDocument } = useMask();

  const [showPassword, setShowPassword] = useState(false);
  const handleToglePasswordVisibility = () => setShowPassword(!showPassword);

  const facebookAuthAppId = store.socialLogin?.facebookApp?.app_id;
  const showfacebookAuth = store.socialLogin?.facebookApp.app_id != 'null' ? true : false
  const googleAuthAppId = store.socialLogin?.googleClientId;

  const validationSchemaLogin = Yup.object().shape({
    email: Yup.string()
      .email(translate('errorEmailLogin'))
      .max(255)
      .required(translate('errorEmailCart')),
    password: Yup.string()
      .max(255)
      .required(translate('errorPasswordLogin'))
  });

  const validationSchemaLoginByDocument = Yup.object().shape({
    document: Yup.string()
      .min(14)
      .max(18)
      .required(translate('errorDocumentLogin')),
    password: Yup.string()
      .max(255)
      .required(translate('errorPasswordLogin'))
  });

  const validationSchemaRecoverPassword = Yup.object().shape({
    recoverPassword: Yup.string().required(translate('errorRecoverPassword'))
  });

  function handleLoginWithFacebook(response) {
    handleLoginWithSocialMedia(response.accessToken, 'accessTokenFacebook')
  }

  function handleLoginWithGoogle(response) {
    console.log(response);
    if (response.error) {
      return;
    }
    handleLoginWithSocialMedia(response.tokenId, 'accessTokenGoogle')
  }

  async function handleLoginWithSocialMedia(token, type) {
    const responseAPI = await dispatch(login({
      [type]: token
    }));

    if (responseAPI) {
      const { isCustomerDataComplete } = responseAPI;

      if (isCustomerDataComplete) {
        trackLogin('Facebook');
        dispatch(addMessage(translate('alertSucessLogin'), 'success'));
        return handleCloseLoginModal();
      }

      dispatch(addMessage(translate('alertSucessLoginWithIncompleteData'), 'success'));
      handleRedirectToAccount();
    } else {
      dispatch(addMessage(translate('alertSocialMediaErrorLogin'), 'error'));
    }
  }

  const handleRedirectToAccount = () => {
    history.push('/customer/account/edit')
  };

  const handleCloseLoginModal = () => {
    close();
  };

  return (
    <>
      <Box className={classes.root}>
        <Formik
          initialValues={defaultFormShape}
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={
            !recoverPassword
              ? loginByDocument
                ? validationSchemaLoginByDocument
                : validationSchemaLogin
              : validationSchemaRecoverPassword
          }
          onSubmit={async (values, { resetForm }) => {
            if (
              values.recoverPassword !== '' &&
              values.recoverPassword !== undefined
            ) {
              const requestId = uuidv4();
              dispatch(addLoadingGlobal(requestId));
              try {

                const response = await recoveryPasswordApi
                  .post({ email: values.recoverPassword })
                  .catch(error => {
                    throw error;
                  });

                if (response) {
                  dispatch(
                    addMessage(
                      'Visualize seu email para recuperação de senha.',
                      'success'
                    )
                  );
                  resetForm();
                  handleCloseLoginModal();
                }
              } catch (error) {
                dispatch(addMessage(error.response.data.message, 'error'));
              }
              dispatch(removeLoadingGlobal(requestId));
            } else {
              const requestId = uuidv4();
              dispatch(addLoadingGlobal(requestId));

              let response = null;

              if (loginByDocument) {
                response = await dispatch(
                  login({
                    document: values.document,
                    password: values.password
                  })
                );
              } else {
                response = await dispatch(
                  login({
                    email: values.email,
                    password: values.password
                  })
                );
              }

              if (response) {
                trackLogin('Account');
                dispatch(addMessage(translate('alertSucessLogin'), 'success'));
                handleCloseLoginModal();
              } else {
                dispatch(addMessage(translate('alertErrorLogin'), 'error'));
              }

              dispatch(removeLoadingGlobal(requestId));
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setValues
          }) => {
            const handleRecoverPassword = () => {
              recoverPassword
                ? setRecoverPassword(false)
                : setRecoverPassword(true);

              if (recoverPassword) {
                setValues({ email: '', password: '', document: '' });
              } else {
                setValues({ recoverPassword: '' });
              }
            };

            const handleLoginOption = () => {
              loginByDocument
                ? setLoginByDocument(false)
                : setLoginByDocument(true);
            };

            return (
              <Box>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Form onSubmit={handleSubmit} className={classes.form}>
                      <Box>
                        {!recoverPassword ? (
                          <Grid container>
                            <Grid item xs={12}>
                              <Box className={classes.loginOption}>
                                <Box
                                  className="switch"
                                  onClick={handleLoginOption}
                                >
                                  <Switch />
                                </Box>
                                <Typography>Logar com meu CPF/CNPJ</Typography>
                              </Box>
                              {loginByDocument ? (
                                <InputMask
                                  mask={dinamicMaskDocument(values.document)}
                                  maskChar={null}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.document}
                                >
                                  {() => (
                                    <Box mb={2}>
                                      <FormControl>
                                        <FormLabel>Seu CPF/CNPJ</FormLabel>
                                        <Input
                                          error={Boolean(
                                            touched.document && errors.document
                                          )}
                                          helperText={
                                            touched.document && errors.document
                                          }
                                          fullWidth
                                          name="document"
                                          placeholder="Qual seu CPF/CNPJ?"
                                        />
                                      </FormControl>
                                      <FormHelperText error>
                                        {errors.document}
                                      </FormHelperText>
                                    </Box>
                                  )}
                                </InputMask>
                              ) : (
                                <Box mb={2}>
                                  <FormControl>
                                    <FormLabel>Seu e-mail</FormLabel>
                                    <Input
                                      error={Boolean(
                                        touched.email && errors.email
                                      )}
                                      helperText={touched.email && errors.email}
                                      fullWidth
                                      autoFocus
                                      name="email"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      type="email"
                                      value={values.email}
                                      placeholder="Qual seu e-mail?"
                                    />
                                  </FormControl>
                                  <FormHelperText error>
                                    {errors.email}
                                  </FormHelperText>
                                </Box>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Box mb={2}>
                                <FormControl>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={1}
                                  >
                                    <FormLabel>Senha</FormLabel>
                                    <span
                                      onClick={handleRecoverPassword}
                                      className="btnForgetPass"
                                    >
                                      {recoverPassword
                                        ? translate('returnToLoginModal')
                                        : translate('forgotPassword')}
                                    </span>
                                  </Box>
                                  <Input
                                    error={Boolean(
                                      touched.password && errors.password
                                    )}
                                    helperText={
                                      touched.password && errors.password
                                    }
                                    fullWidth
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    placeholder="Informe sua senha:"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Icon
                                          style={{
                                            height: '8',
                                            cursor: 'pointer'
                                          }}
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleToglePasswordVisibility
                                          }
                                        >
                                          {showPassword ? (
                                            <Visibility
                                              style={{ color: '#E4E4E4' }}
                                            />
                                          ) : (
                                            <VisibilityOff
                                              style={{ color: '#E4E4E4' }}
                                            />
                                          )}
                                        </Icon>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container>
                            <Grid item xs={12}>
                              <Box mb={2}>
                                <FormControl>
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={1}
                                  >
                                    <FormLabel>Seu e-mail</FormLabel>
                                    <span
                                      onClick={handleRecoverPassword}
                                      className="btnForgetPass"
                                    >
                                      {recoverPassword
                                        ? translate('returnToLoginModal')
                                        : translate('forgotPassword')}
                                    </span>
                                  </Box>
                                  <Input
                                    error={Boolean(
                                      touched.recoverPassword &&
                                      errors.recoverPassword
                                    )}
                                    helperText={
                                      touched.recoverPassword &&
                                      errors.recoverPassword
                                    }
                                    label={translate('labelForgotPassword')}
                                    name="recoverPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="recoverPassword"
                                    placeholder="Qual seu e-mail?"
                                  />
                                </FormControl>
                                <FormHelperText error>
                                  {errors.recoverPassword}
                                </FormHelperText>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                      {!recoverPassword ? (
                        <Box>
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            className="btnLogin"
                          >
                            {translate('buttonLogIn')}
                          </Button>
                          <Box className="registerAction">
                            Ainda não tem cadastro?
                            <Button
                              component={RouterLink}
                              to="/onestepcheckout/customer/register"
                              className="btnLink"
                            >
                              Cadastre-se agora
                            </Button>
                          </Box>
                          <Box mt={2}>
                            {showfacebookAuth || googleAuthAppId && (
                              <Box
                                textAlign="center"
                              >
                                <Typography className={classes.socialMediaTitle}>
                                  Ou entre com:
                                </Typography>
                                <Divider className={classes.divider} />
                              </Box>
                            )}
                            <Box
                              mt={3}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              gridGap={6}
                            >
                              {facebookAuthAppId && showfacebookAuth && (
                                <FacebookLogin
                                  cssClass="btn btn-social-media"
                                  appId={facebookAuthAppId}
                                  autoLoad={false}
                                  fields="name,email,picture"
                                  callback={handleLoginWithFacebook}
                                  icon={<img src={imgFacebook} alt="" />}
                                  textButton=''
                                />
                              )}
                              {googleAuthAppId && (
                                <GoogleLogin
                                  clientId={googleAuthAppId}
                                  autoLoad={false}
                                  render={renderProps => (
                                    <button onClick={renderProps.onClick} className="btn btn-social-media" disabled={renderProps.disabled}>
                                      <img src={imgGoogle} alt="" />
                                    </button>
                                  )}
                                  buttonText=""
                                  onSuccess={handleLoginWithGoogle}
                                  onFailure={handleLoginWithGoogle}
                                  cookiePolicy={'single_host_origin'}
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box>
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            className="btnLogin"
                          >
                            {translate('buttonForgotPassword')}
                          </Button>
                          <Box className="registerAction">
                            Ainda não tem cadastro?
                            <Button
                              component={RouterLink}
                              to="/onestepcheckout/customer/register"
                              className="btnLink"
                            >
                              Cadastre-se agora
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Form>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}
