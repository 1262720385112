import React from 'react';
import Vertical from './Vertical';
import Horizontal from './Horizontal';

function MobileProductCard({ product, variant = 'vertical' }) {
    const variants = {
        vertical: <Vertical product={product} />,
        horizontal: <Horizontal product={product} />,
    };
    return variants[variant] || null;
}

export default MobileProductCard;
