import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import { apiWpBlog } from 'src/utils/apiWP';
import { makeStyles } from '@material-ui/core/styles';

import {
    Container,
    Grid,
    Box,
    Typography,
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import arrowRightBlack from 'src/theme/scss/icons/arrow-right-black.svg';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    bigTitle: {
        color: '#EBEBEB',
        textAlign: 'center',
        marginBottom: '-90px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
            marginBottom: '-74px',
        },
    },
}));

const HeroSliderConfigs = {
    containerClass: 'swiper-container post-slider',
    parallax: true,
    loop: true,
    effect: 'slide',
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
    },
    rebuildOnUpdate: true,
    slidesPerView: 'auto',
    spaceBetween: 15,
}

export default function Slider() {
    const classes = useStyles();
    const [parallaxSwiper, setParallaxSwiper] = useState(null);
    const [posts, setPosts] = useState(null);

    const createMarkup = (content) => {
        return {
            __html: content
        };
    };

    const fetchPosts = async () => {
        apiWpBlog.get(`https://blog.avell.com.br/wp-json/wp/v2/posts?_embed&page=1&per_page=3&search=`).then((response) => {
            if (response.request.status === 200) {
                setPosts(response.data);
            }
        }).catch((error) => {
        }).then(() => {
        });
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    const swiperItems = posts && posts.map((post) => {
        const imagePost = post['_embedded']['wp:featuredmedia'] !== undefined && post['_embedded']['wp:featuredmedia'][0]['source_url'];
        return (
            <div className="swiper-slide swiper-container">
                <RouterLink to={{ pathname: `/blog/${post.slug}`, state: { entityId: post.id, name: post.name, wpTerm: post['_embedded']['wp:term'] } }} className="card">
                    <div className="card-image" style={{ backgroundImage: 'url("'+ imagePost +'")' }}>
                    </div>
                    <div className="card-info">
                        <Typography variant="caption" className="caption">
                            <span className={classes.spanCaption}>{ moment(post.date).format('LL') }</span>
                            <span className={classes.spanCaption}>{post['_embedded']['wp:term'] !== undefined && ( post['_embedded']['wp:term'][0][0].name )}</span>
                        </Typography>
                        <Typography variant="h3" className="title-3" dangerouslySetInnerHTML={createMarkup(post.title.rendered)}></Typography>
                        <Typography variant="body1" className="text-1" dangerouslySetInnerHTML={createMarkup(post.excerpt.rendered)}></Typography>
                        <div className="btn btn-8">
                            Leia mais
                            <img src={arrowRightBlack} className="icon" />
                        </div>
                    </div>
                </RouterLink>
            </div>
        )
    });

    return (
        <>
            <Box className="blog-items">
                <Container maxWidth={false}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={8}
                    >
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography className={classes.bigTitle} variant="overline">
                                fique por dentro
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className='posts-slider-home'>
                                <Swiper {...HeroSliderConfigs} getSwiper={setParallaxSwiper}>
                                    {swiperItems}
                                </Swiper>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}