import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    ChevronRight as ChevronRightIcon
} from 'react-feather';

import {
    Button,
    CircularProgress,
    makeStyles,
    darken
} from '@material-ui/core';

import { addProductCart } from 'src/actions';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonAddToCart: {
        fontFamily: 'Manrope',
        fontSize: '16px',
        // fontWeight: 800,
        color: '#ffffff',
        padding: '8px',
        width: '100%',
        backgroundColor: '#0085f8', // '#DB1842',
        '&:hover': {
            backgroundColor: darken('#0085f8', 0.1)
        }
    },
    loading: {
        color: '#FFFFFF',
        marginRight: '4px'
    }
}));

export function ButtonAddToCart({ product }) {

    const classes = useStyles();

    const dispatch = useDispatch();

    let history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const handleAddCart = async (product) => {
        if (isLoading) return;

        setIsLoading(true);

        if (product.type === 'simple') {
            const response = await dispatch(addProductCart({
                data: product,
                quantity: 1
            }));
            if (response) {
                return history.push('/onestepcheckout/carrinho');
            }
        } else {
            return history.push(product.url);
        }

        setIsLoading(false);
    }


    return (
        <Button
            onClick={() => handleAddCart(product)}
            variant="contained"
            className={classes.buttonAddToCart}
            disabled={!product.saleable}
            size="small"
        >
            {/* {isLoading ?
                <CircularProgress
                    size={18}
                    className={classes.loading}
                />
                :
                <ChevronRightIcon
                    size={18}
                    className="icon"
                    style={{
                        marginRight: '4px'
                    }}
                />
            } */}
            {product.saleable ? 'Comprar' : 'Indisponível'}
        </Button>
    )
}