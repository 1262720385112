import React from 'react';
import { Helmet } from 'react-helmet';

export function Meliuz() {
    return (
        <Helmet>
            <script>
                {`
                    function meliuzCookie() {
                        var ismeliuz;
                        expirationTime = 2592000000;
                        var date = new Date();
                        var dateTimeNow = date.getTime();
                        date.setTime(dateTimeNow + expirationTime);
                        var date = date.toUTCString();

                        if (location.search.includes("gclid=")) {
                            ismeliuz = 0;
                            document.cookie = "isMz="+ismeliuz+"; expires="+date+"; path=/; Secure;SameSite=None; domain="+location.hostname.replace(/^www\./i,"")
                        } else if(location.search.includes("utm_source=")) {
                            if (location.search.includes("utm_source=meliuz")) {
                                ismeliuz = 1;
                                var cookieValue = location.search.match(/xtra=([^&]+)/)[0].split('=')[1];
                                document.cookie = "meliuzCookie="+cookieValue+"; expires="+date+"; path=/; Secure;SameSite=None; domain="+location.hostname.replace(/^www\./i,"");
                            } else {
                                ismeliuz = 0;
                            }
                            document.cookie = "isMz="+ismeliuz+"; expires="+date+"; path=/; Secure;SameSite=None; domain="+location.hostname.replace(/^www\./i,"")
                        }
                    }
                    meliuzCookie();
                `}
            </script>
        </Helmet>
    );
}
