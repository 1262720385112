import React from 'react';
import { Box as BoxMaterial } from '@material-ui/core';
import Page from 'src/components/Page';
import { ContentView } from 'src/components/Content';

import { useHistory } from 'react-router-dom';

export default function ContentIndex({ content, onRender }) {
    const history = useHistory();

    if (!content.visible) {
        history.push('/404');
    }

    let metaTitle = content.name;

    let metaKeywords = '';

    let metaDescription = '';

    if (content.metaTitle)
        metaTitle = content.metaTitle

    if (content.metaKeywords)
        metaKeywords = content.metaKeywords

    if (content.metaDescription)
        metaDescription = content.metaDescription

    return (
        content.visible && (
            <Page
                title={metaTitle}
                metaKeywords={metaKeywords}
                metaDescription={metaDescription}
            >
                <BoxMaterial mb={5}>
                    <ContentView json={content.json} onRender={onRender} />
                </BoxMaterial>
            </Page>
        )
    )
}