const isHomolog = window.location.hostname.includes('homolog');
const homologPassword = '@Avell1!';

function init() {
  const password = prompt('Senha: ');
  if (password !== homologPassword) {
    init();
  }
}

if (isHomolog) {
  init();
}
