import React, {
  forwardRef,
  useEffect, useCallback, useState
} from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { trackPageview } from 'src/utils/analytics';

const Page = forwardRef(({
  title,
  metaKeywords,
  metaDescription,
  children,
  ...rest
}, ref) => {
  const location = useLocation();
  const store = useSelector((state) => state.interface.store);
  const customer = useSelector((state) => state.account.user);

  const [metaTags, setMetaTags] = useState({
    title: '',
    description: '',
    metaKeywords: ''
  });

  const initialize = useCallback(() => {
    let newTitle = store.name;
    if (title && title.length) {
      newTitle = title + ' - ' + newTitle;
    }

    let newMetaKeywords = store?.metaKeywords;
    if (metaKeywords && metaKeywords.length) {
      newMetaKeywords = metaKeywords;
    }

    setMetaTags({
      title: newTitle,
      description: metaDescription,
      metaKeywords: newMetaKeywords,
    });

    trackPageview({
      title: newTitle,
      path: location.pathname,
      customer: customer ?? {}
    });
  }, [location]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
        <meta name="keywords" content={metaTags.metaKeywords} />
        <link rel="shortcut icon" href={store.favicon}></link>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
