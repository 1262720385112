import React from "react";
import { makeStyles } from "@material-ui/core";
import Swiper from 'react-id-swiper';
import ProductCard from 'src/components/ProductCard';

export const ProductSlider = ({ products, slidesPerView }) => {

    const swiperSizesBySlidersPerView = {
        1: '90% !important',
        2: '45% !important',
        3: '30% !important',
        4: '20% !important',
        5: '15% !important',
        6: '10% !important',
    }

    const useStyles = makeStyles((theme) => ({
        swiperMainContainer: {
            '& .swiper-container': {
                padding: '0 8px 20px',
                [theme.breakpoints.down('sm')]: {
                    padding: '0 12px 20px 0',
                },
                // marginRight: '-16px',
                '& .swiper-slide': {
                    margin: '0 !important',
                    width: 'calc((100% / ' + slidesPerView + ') + 4px) !important',
                    // width: slidesPerView === 1 ? '100%' : swiperSizesBySlidersPerView[slidesPerView],
                    height: 'auto',
                },
                // '& .swiper-slide-active': {
                //     width: swiperSizesBySlidersPerView[slidesPerView]
                // },
                '& .swiper-pagination': {
                    display: ((products && products.length) > slidesPerView ? 'block' : 'none')
                },
                '& .swiper-pagination-bullets': {
                    bottom: '8px',
                    paddingBottom: '4px',
                    alignItems: 'center',
                    '& .swiper-pagination-bullet': {
                        // backgroundColor: '#C9C9C9',
                    },
                    '& .swiper-pagination-bullet-active': {
                        backgroundColor: 'transparent',
                        border: '2px solid #FF367D',
                        height: '12px',
                        width: '12px',
                        transform: 'scale(1) translateY(2px)',
                    },
                    [theme.breakpoints.up('sm')]: {
                        '& .swiper-pagination-bullet': {
                            height: '16px',
                            width: '16px',
                        },
                        '& .swiper-pagination-bullet-active': {
                            height: '16px',
                            width: '16px',
                            transform: 'scale(1)',
                        }
                    }
                }
            }
        }
    }));

    const classes = useStyles();

    let params = {
        containerClass: 'swiper-container',
        parallax: true,
        loop: false,
        effect: 'slide',
        rebuildOnUpdate: true,
        spaceBetween: 16,
        slidesPerView: slidesPerView,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        }
    };

    return (
        <div className={classes.swiperMainContainer}>
            <Swiper
                {...params}
            >
                {products.map(prodcut => (
                    <div className="swiper-slide swiper-container" key={prodcut.id}>
                        <ProductCard fixedSimpleVersion product={prodcut} />
                    </div>
                ))}
            </Swiper>
        </div>
    )
}