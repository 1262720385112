/* eslint-disable react/no-array-index-key */
import React, {
    lazy,
    Suspense,
    Fragment
} from 'react';
import {
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout/index';
import BlogLayout from 'src/layouts/MainLayout/indexBlog';
import ForumLayout from 'src/layouts/MainLayout/indexForum';
import CustomerAccountLayout from 'src/layouts/CustomerAccountLayout/index';
import CheckoutLayout from 'src/layouts/CheckoutLayout/index';
import HomeView from 'src/views/Home';
import ContentView from 'src/views/Content';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import TradeinCheckoutLayout from './layouts/Tradein/Checkout';
import GuestOrderViewLayout from './layouts/Guest/Order/View';

const routesConfig = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/Error404'))
    },
    // {
    //     exact: true,
    //     path: [
    //         '/blog',
    //         '/blog/page/:page',
    //         '/blog/categorias/:categorySlug',
    //         '/blog/tags/:tagSlug',
    //         '/blog/categorias/:slug/page/:page'
    //     ],
    //     component: lazy(() => import('src/views/Blog/List'))
    // },
    {
        path: '/customer/account',
        guard: AuthGuard,
        layout: CustomerAccountLayout,
        routes: [
            {

                exact: true,
                path: '/customer/account',
                component: lazy(() => import('src/views/Customer/Account/Dashboard'))
                // component: () => <Redirect to="/customer/account/edit" />
            },
            {
                exact: true,
                path: '/customer/account/orders',
                component: lazy(() => import('src/views/Customer/Account/Orders/List'))
            },
            {
                exact: true,
                path: '/customer/account/orders/:id',
                component: lazy(() => import('src/views/Customer/Account/Orders/View'))
            },
            {
                exact: true,
                path: '/customer/account/edit',
                component: lazy(() => import('src/views/Customer/Account/Edit'))
            },
            {
                exact: true,
                path: '/customer/account/addresses',
                component: lazy(() => import('src/views/Customer/Account/Addresses/List'))
            },
            {
                exact: true,
                path: '/customer/account/update-password',
                component: lazy(() => import('src/views/Customer/Account/Edit/Partials/UpdatePasswordForm.js'))
            },
            {
                exact: true,
                path: [
                    '/customer/account/addresses/new',
                    '/customer/account/addresses/:id'
                ],
                component: lazy(() => import('src/views/Customer/Account/Addresses/View'))
            },
            {
                exact: true,
                path: '/customer/account/reviews',
                component: lazy(() => import('src/views/Customer/Account/Reviews/List'))
            },
            {
                exact: true,
                path: '/customer/account/wallpapers',
                component: lazy(() => import('src/views/Customer/Account/Wallpapers'))
            },
            {
                exact: true,
                path: '/customer/account/wishlist',
                component: lazy(() => import('src/views/Customer/Account/Wishlist'))
            },
            {
                exact: true,
                path: '/customer/account/uploads',
                component: lazy(() => import('src/views/Customer/Account/Uploads'))
            },
            {
                exact: true,
                path: '/customer/account/support',
                component: lazy(() => import('src/views/Customer/Account/Support'))
            },
            {
                exact: true,
                path: '/customer/account/user-guide',
                component: lazy(() => import('src/views/Customer/Account/UserGuide'))
            },
            {
                exact: true,
                path: [
                    // '/customer/account/addresses/new',
                    '/customer/account/reviews/:id'
                ],
                component: lazy(() => import('src/views/Customer/Account/Reviews/View'))
            }
        ]
    },
    {
        path: '/tradein',
        layout: TradeinCheckoutLayout,
        routes: [
            {
                exact: true,
                path: '/tradein/:transactionId',
                component: lazy(() => import('src/views/Tradein'))
            }
        ]
    },
    {
        path: '/guest',
        layout: GuestOrderViewLayout,
        routes: [
            {
                exact: true,
                path: '/guest/order',
                component: lazy(() => import('src/views/Guest/Order/View'))
            }
        ]
    },
    {
        path: '/onestepcheckout',
        layout: CheckoutLayout,
        routes: [
            {
                guard: AuthGuard,
                exact: true,
                path: '/onestepcheckout',
                component: lazy(() => import('src/views/Checkout/index.jsx'))
            },
            {
                exact: true,
                path: '/onestepcheckout/carrinho',
                component: lazy(() => import('src/views/Checkout/Cart/Cart.jsx'))
            },
            {
                exact: true,
                path: '/onestepcheckout/login',
                component: lazy(() => import('src/layouts/MainLayout/Login/index.jsx'))
            },
            {
                exact: true,
                path: '/onestepcheckout/customer/register',
                component: lazy(() => import('src/views/Customer/Register'))
            },
            // {
            //     exact: true,
            //     path: '/onestepcheckout/success',
            //     component: lazy(() => import('src/views/Success.jsx'))
            // },
        ]
    },
    // {
    //     path: '/forum',
    //     layout: ForumLayout,
    //     routes: [
    //         {
    //             exact: true,
    //             path: [
    //                 '/forum',
    //                 '/forum/page/:page',
    //             ],
    //             component: lazy(() => import('src/views/Forum/index.jsx'))
    //         },
    //         {
    //             exact: true,
    //             path: '/forum/topico',
    //             component: lazy(() => import('src/views/Forum/Topic/View'))
    //         },
    //         {
    //             exact: true,
    //             path: '/forum/meu-perfil',
    //             component: lazy(() => import('src/views/Forum/Profile/Profile'))
    //         },
    //         {
    //             exact: true,
    //             path: '/forum/meus-topicos',
    //             component: lazy(() => import('src/views/Forum/Profile/MyTopics'))
    //         },
    //         {
    //             exact: true,
    //             path: '/forum/respostas-criadas',
    //             component: lazy(() => import('src/views/Forum/Profile/Answers'))
    //         },
    //         {
    //             exact: true,
    //             path: '/forum/topicos-favoritados',
    //             component: lazy(() => import('src/views/Forum/Profile/FavoriteTopics'))
    //         },
    //         {
    //             guard: AuthGuard,
    //             exact: true,
    //             path: '/forum/novo-topico',
    //             component: lazy(() => import('src/views/Forum/Topic/NewTopic'))
    //         },
    //         {
    //             guard: AuthGuard,
    //             exact: true,
    //             path: '/forum/topics/:id',
    //             component: lazy(() => import('src/views/Forum/Topic/View'))
    //         },
    //     ]
    // },
    {
        path: '/blog',
        layout: BlogLayout,
        routes: [
            {
                exact: true,
                path: '/blog/busca',
                component: lazy(() => import('src/views/Blog/List/searchResult.jsx'))
            },
            {
                exact: true,
                path: '/blog/categoria',
                component: lazy(() => import('src/views/Blog/List/categoryResult.jsx'))
            },
            {
                exact: true,
                path: '/blog/:slug',
                component: lazy(() => import('src/views/Blog/Post'))
            },
            {
                exact: true,
                path: [
                    '/blog',
                    '/blog/page/:page',
                    '/blog/categorias/:categorySlug',
                    '/blog/tags/:tagSlug',
                    '/blog/categorias/:slug/page/:page'
                ],
                component: lazy(() => import('src/views/Blog/List'))
            },
        ]
    },
    {
        path: '/wizard',
        layout: MainLayout,
        exact: true,
        component: lazy(() => import('src/views/Content/Wizard'))
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: HomeView
            },
            {
                exact: true,
                path: '/wishlist',
                component: lazy(() => import('src/views/Customer/MyFavorites'))
            },
            {
                exact: true,
                path: '/style-guide',
                component: lazy(() => import('src/views/StyleGuide'))
            },
            {
                exact: true,
                path: '/odontologia',
                component: lazy(() => import('src/views/Segment/odontology.jsx'))
            },
            {
                exact: true,
                path: '/estudos',
                component: lazy(() => import('src/views/Segment/studies.jsx'))
            },
            {
                exact: true,
                path: '/apresentacao-do-produto',
                component: lazy(() => import('src/views/Catalog/Product/ProductPresentation.jsx'))
            },
            {
                exact: true,
                path: '/sobre-a-avell',
                component: lazy(() => import('src/views/Content/SobreNos'))
            },
            {
                exact: true,
                path: '/politica-de-privacidade',
                component: lazy(() => import('src/views/Content/PoliticaDePrivacidade'))
            },
            {
                exact: true,
                path: '/termos-e-condicoes',
                component: lazy(() => import('src/views/Content/TermosECondicoes'))
            },
            {
                exact: true,
                path: '/perguntas-frequentes',
                component: lazy(() => import('src/views/Content/Faq'))
            },
            {
                exact: true,
                path: '/politica-de-garantia',
                component: lazy(() => import('src/views/Content/PoliticaDeGarantia'))
            },
            {
                exact: true,
                path: '/central-de-vendas',
                component: lazy(() => import('src/views/Content/CentralDeVendas'))
            },
            {
                exact: true,
                path: '/suporte-tecnico',
                component: lazy(() => import('src/views/Content/Suporte'))
            },
            {
                exact: true,
                path: '/ticket',
                component: lazy(() => import('src/views/Content/Ticket'))
            },
            // {
            //     exact: true,
            //     path: '/abertura-de-chamado',
            //     component: lazy(() => import('src/views/Content/Ticket/OpenTicket'))
            // },
            {
                exact: true,
                path: '/trocas-e-devolucoes',
                component: lazy(() => import('src/views/Content/TrocasEDevolucoes'))
            },
            {
                exact: true,
                path: '/drivers',
                component: lazy(() => import('src/views/Content/DriversEManuais'))
            },
            {
                exact: true,
                path: '/contato/central-de-vendas',
                component: lazy(() => import('src/views/Content/ContatoCentralDeVendas'))
            },
            {
                exact: true,
                path: '/contato/suporte-tecnico',
                component: lazy(() => import('src/views/Content/ContatoSuporteTecnico'))
            },
            {
                exact: true,
                path: '/nvidia-brand-store',
                component: lazy(() => import('src/views/Content/NvidiaBrandStore'))
            },
            {
                exact: true,
                path: '/cart/share/:id',
                component: lazy(() => import('src/views/Cart/Share/index.jsx'))
            },
            {
                exact: true,
                path: '/customer/register/success',
                component: lazy(() => import('src/views/Customer/RegisterSuccess'))
            },
            {
                exact: true,
                path: '/user/recovery/:token',
                component: lazy(() => import('src/views/Customer/RecoveryPassword'))
            },
            {
                exact: true,
                path: '/search',
                component: lazy(() => import('src/views/Catalog/Search'))
            },
            {
                exact: true,
                path: '/catalogo',
                component: lazy(() => import('src/views/Catalog/All/index.jsx'))
            },
            {
                exact: true,
                path: '/contato',
                component: lazy(() => import('src/views/Contact/index.jsx'))
            },
            {
                exact: false,
                path: '/comparacao-produtos',
                component: lazy(() => import('src/views/Catalog/Product/Comparison'))
            },
            {
                exact: false,
                path: '/cadastroblackfriday',
                component: lazy(() => import('src/views/Newsletter'))
            },
            {
                exact: false,
                path: '/seja-revendedor',
                component: lazy(() => import('src/views/Content/SejaRevendedor'))
            },
            {
                exact: false,
                // path: '/(:id)',
                component: lazy(() => import('src/views/Catalog/index.jsx'))
            }
        ]
    },

];

const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (<Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                        <Guard>
                            <Layout>
                                {route.routes
                                    ? renderRoutes(route.routes)
                                    : <Component {...props} />}
                            </Layout>
                        </Guard>
                    )}
                />);
            })}
        </Switch>
    </Suspense>
) : null);

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
