import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
    IconButton,
    Box,
    ClickAwayListener,
    Grid,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PersonOutlineOutlined as UserIcon, InsertEmoticon as InsertEmoticonIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/actions';
import Auth from './Auth';

const useStyles = makeStyles({
    content: {
        position: 'fixed',
        bottom: props => props.menuIsVisible ? '80px' : '8px',
        left: '8px',
        right: '8px',
        backgroundColor: '#333333',
        padding: '16px',
        borderRadius: '8px',
        opacity: '0',
        visibility: 'hidden',
        transition: 'all .3s',
        '&.show': {
            opacity: '1',
            visibility: 'visible',
        },
    },
    authentucated: {
       color: '#FFFFFF',
       '& a': {
           color: '#FFFFFF',
       }
    },
    authButton: {
        width: '100%',
        backgroundColor: '#444444',
        color: '#FFFFFF',
        borderRadius: '8px',
        color: '#fff',
        fontSize: '12px',
        lineHeight: '14px',
        padding: '8px 16px',
        '&:hover': {
            backgroundColor: '#444444',
            color: '#FFFFFF',
        },
    },
});

function BottomNavUser({ menuIsVisible }) {
    const classes = useStyles({ menuIsVisible });
    const dispatch = useDispatch();
    const [showUserMenu, setShowUserMenu] = useState(false);
    const ref = useRef();
    const history = useHistory();
    const userAccount = useSelector(state => state.account.user);

    function closeUserMenu() {
        setShowUserMenu(false);
    }

    async function logoutUser() {
        dispatch(logout());
        history.push('/');
        closeUserMenu();
    }

    useEffect(() => {
        if (showUserMenu) {
            ref.current.classList.add('show');
        } else {
            ref.current.classList.remove('show');
        }
    }, [showUserMenu]);

    return (
        <ClickAwayListener onClickAway={closeUserMenu}>
            <Box>
                <IconButton onClick={() => setShowUserMenu(!showUserMenu)}>
                    <UserIcon />
                </IconButton>
                <Box ref={ref} className={classes.content}>
                    {userAccount != null ? (
                        <Box className={classes.authentucated}>
                            <Grid item xs={12} className="grid-item links customer-links">
                                <ListItem
                                    button
                                    component={Link}
                                    to="/customer/account"
                                    onClick={closeUserMenu}
                                    className="list-item"
                                >
                                    <ListItemText
                                        primary={'Olá, ' + userAccount.name.split(' ')[0]}
                                        className="level-0"
                                    />
                                </ListItem>
                                <ListItem
                                    component={Link}
                                    to="/customer/account"
                                    className="list-item"
                                    onClick={closeUserMenu}
                                >
                                    <ListItemText primary="Minha conta" className="level-0" />
                                </ListItem>
                                <ListItem
                                    component={Link}
                                    to="/customer/account/orders"
                                    className="list-item"
                                    onClick={closeUserMenu}
                                >
                                    <ListItemText primary="Meus pedidos" className="level-0" />
                                </ListItem>
                                <ListItem
                                    button
                                    component={Link}
                                    to="/customer/account/addresses"
                                    className="list-item"
                                    onClick={closeUserMenu}
                                >
                                    <ListItemText primary="Meus endereços" className="level-0" />
                                </ListItem>
                                <ListItem
                                    button
                                    component={Link}
                                    to="/customer/account/update-password"
                                    className="list-item"
                                    onClick={closeUserMenu}
                                >
                                    <ListItemText primary="Alterar minha senha" className="level-0" />
                                </ListItem>
                                <ListItem component={Link} onClick={logoutUser} to="#" className="list-item">
                                    <ListItemText primary="Sair" className="level-0" />
                                </ListItem>
                            </Grid>
                        </Box>
                    ) : (
                        <>
                            <Auth onClose={closeUserMenu} />
                        </>
                    )}
                </Box>
            </Box>
        </ClickAwayListener>
    );
}

export default BottomNavUser;
