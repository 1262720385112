import axios from 'src/utils/axios';

import ManagerApi from 'src/services/managerApi'

class AuthService {

    handleAuthentication() {
        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return;
        }

        if (this.isValidToken(accessToken)) {
            this.setSession(accessToken);
        } else {
            this.setSession(null);
        }
    }

    loginWithEmailAndPassword = (email, password, cart) => new Promise((resolve, reject) => {
        const managerApi = new ManagerApi('/customer/auth');
        managerApi.post({ login: email, password, cart })
            .then((response) => {
                if (response.data.content && response.data.content.customer && response.data.content.role === "CUSTOMER") {
                    this.setSession(response.data.content.token);
                    resolve(response.data.content);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    })

    loginWithDocumentAndPassword = (document, password, cart) => new Promise((resolve, reject) => {
        const managerApi = new ManagerApi('/customer/auth');
        managerApi.post({ document: document, password, cart })
            .then((response) => {
                if (response.data.content && response.data.content.customer && response.data.content.role === "CUSTOMER") {
                    this.setSession(response.data.content.token);
                    resolve(response.data.content);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    })

    loginInWithToken = () => new Promise((resolve, reject) => {
        const managerApi = new ManagerApi('/customer/auth');
        managerApi.get('me')
            .then((response) => {
                if (response.data.content && response.data.content.customer && response.data.content.role === "CUSTOMER") {
                    this.setSession(response.data.content.token);
                    resolve(response.data.content);
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                reject(error);
            });
    })

    loginWithSocialMedia = (accessToken, type) => new Promise((resolve, reject) => {
      const managerApi = new ManagerApi('/customer/auth');
      managerApi.post({ [type]: accessToken})
          .then((response) => {
              if (response.data.content && response.data.content.customer && response.data.content.role === "CUSTOMER") {
                  this.setSession(response.data.content.token);
                  resolve(response.data.content);
              } else {
                  reject(response.data.error);
              }
          })
          .catch((error) => {
              reject(error);
          });
    })

    logout = () => {
        this.setSession(null);
    }

    setSession = (accessToken) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        } else {
            localStorage.removeItem('accessToken');
            delete axios.defaults.headers.common.Authorization;
        }
    }

    getAccessToken = () => localStorage.getItem('accessToken');

    isValidToken = (accessToken) => {
        if (!accessToken) {
            return false;
        }

        return true;

        // const decoded = jwtDecode(accessToken);
        // const currentTime = Date.now() / 1000;
        //
        // return decoded.exp > currentTime;
    }

    isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
