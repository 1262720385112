import React from "react";

import {
  Box,
  Typography
} from "@material-ui/core";

export const InfoCard = ({
  icon,
  title,
  description,
  card,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight
}) => {
  return (
    <Box
      marginTop={Number(marginTop)}
      marginRight={Number(marginRight)}
      marginBottom={Number(marginBottom)}
      marginLeft={Number(marginLeft)}
      style={{
        borderWidth: `${card.borderWidth}px`,
        borderStyle: 'solid',
        borderColor: card.borderColor,
        borderRadius: `${card.borderRadius}px`,
        padding: `${card.padding}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: icon.align
      }}
    >

      <Box>
        {icon.url &&
          <img src={icon.url} style={{ width: `${icon.size}${icon.sizeMeasurement}` }} />
        }
      </Box>

      <Box style={{
        width: '100%'
      }}>
        <Box
          textAlign={title.align}
        >
          <Typography 
            variant={title.variant}
            style={{
              color: title.color
            }}
          >
            {
              title.text && title.text
            }
          </Typography>
        </Box>

        <Box
          textAlign={description.align}
        >
          <Typography 
            variant={description.variant}
            style={{
              color: description.color
            }}
          >
            {
              description.text && description.text
            }
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
