import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const paymentsWithInitializeScripts = {
    'pagseguro': ['pagseguroQrCode', 'pagseguroBillet', 'pagseguroCreditCard'],
    'mercadopago': ['mercadopagoCreditCard']
};

const PaymentMethodScripts = memo(() => {
    const { paymentOptions } = useSelector((state) => state.interface.store);

    function getPaymentWithScript(paymentName) {
        const payment = Object.entries(paymentOptions)
            .find(paymentOptionName => paymentsWithInitializeScripts[paymentName].includes(paymentOptionName[0]));

        return payment ? payment[1] : {};
    }

    function generatePagseguroScript() {
        const paymentPagSeguro = getPaymentWithScript('pagseguro');

        if (!paymentPagSeguro.active) {
            return null
        }

        return (<script src="https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js"></script>);
    }

    function generateMercadoPagoScript() {
        const paymentMercadoPago = getPaymentWithScript('mercadopago');

        if (!paymentMercadoPago.active) {
            return null
        }

        return (<script src="https://sdk.mercadopago.com/js/v2"></script>);
    }

    function generatePaymentsScritps() {
        return [
            generatePagseguroScript(),
            generateMercadoPagoScript()
        ];
    }

    return (
        <Helmet>
            {generatePaymentsScritps()}
        </Helmet>
    );
})

export default PaymentMethodScripts;