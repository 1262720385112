import React from 'react';
import { Helmet } from 'react-helmet';

export function Criteo() {
    return (
        <Helmet>
            <script type="text/javascript" src="//dynamic.criteo.com/js/ld/ld.js?a=80169" async="true"></script>
        </Helmet>
    );
}
