import React from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";

import { getBoxMarginProps, getBreakpointValue, getColor } from "./functions";

export const Iframe = (props) => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

	return (
		<Box>
            <iframe frameBorder="no"
                style={{
                    minHeight: `${getBreakpointValue(props, breakpoint, 'minHeight')}px`,
                    height: '100%',
                    width: '100%'
                }}
                src={props.url}
            >
            </iframe>
        </Box>
	)
}