class ClassUserLogin {

    constructor() {
        this.getObjects = this.getObjects();
    }

    getObjects = () => {
        return {
            email: '',
            document: '',
            password: '',
            recoverPassword: '',
        }
    }
}

export default ClassUserLogin;