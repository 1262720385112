import React, { useState } from 'react';

import {
    Box,
    Container,
    makeStyles
} from '@material-ui/core';

import ContentView from 'src/views/Content';

import Page from 'src/components/Page';
import BlogItems from './Blog/BlogItems';
import SliderPosts from './Blog/SliderPosts';
import { Help } from './Help';
import { Strands } from './Strands';
import { Features } from './Features';
import { CategoriesBlock } from './CategoriesBlock';
import { RenderCategories } from './RenderCategories';
import { NewsletterDialog } from './NewsletterDialog';

const useStyles = makeStyles((theme) => ({
    hiddenMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    showMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    }
}));

export default function DynamicHome(props) {
    const classes = useStyles();
    // const isNewsletterSubscribed = localStorage.getItem('@avell/newsletterSubscribed');
    const [isNewsletterDialogOpen, setIsNewsletterDialogOpen] = useState(false);//useState(isNewsletterSubscribed ? false : true);

    const {
        content
    } = props;

    return (
        <Page
            title="Avell - Home"
        >
            <Box>
                <>
                    <ContentView
                        content={content}
                        onRender={RenderCategories}
                    />

                    {/* <CategoriesBlock /> */}

                    <Box
                        className={classes.hiddenMobile}
                    >
                        <BlogItems />
                    </Box>
                    {/*<Box className={classes.showMobile}>
                        <SliderPosts />
                    </Box>*/}

                    <NewsletterDialog
                        isOpen={isNewsletterDialogOpen}
                        onClose={() => setIsNewsletterDialogOpen(false)}
                    />

                    <Help />
                    {/* <Strands /> */}
                    <Features />
                </>
            </Box>
        </Page>
    )
}
