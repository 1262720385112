/* eslint-disable import/prefer-default-export */
export const THEMES = {
    STORE: 'STORE',
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN'
};

export const Languages = {
    pt: 'pt',
    en: 'en'
}