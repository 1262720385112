import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, addMessage, removeLoadingGlobal } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import ModalEntry from './ModalEntry';
import ModalExit from './ModalExit';

export default function ContentModals() {
    const [modals, setModals] = useState({});
    const dispatch = useDispatch();
    const contentApi = new ManagerApi('/content');
    const types = {
        'modal-entry': <ModalEntry />,
        'modal-exit': <ModalExit />
    };

    const fetchContents = async () => {
        const requestId = uuidv4();
        dispatch(addLoadingGlobal(requestId));
        contentApi.getList({
            types: ['modal-entry', 'modal-exit']
        }).then((response) => {
            if (response.data.success) {
                setModals(response.data.content);
            }
        }).catch((error) => {
            dispatch(addMessage(error.response.data.message, 'error'))
            dispatch(removeLoadingGlobal(requestId));
        }).then(() => {
            dispatch(removeLoadingGlobal(requestId));
        });
    };

    useEffect(() => {
        fetchContents();
    }, []);

    return (
        <>
            {
                modals.length > 0 && modals.map((modal) => {
                    return (
                        <React.Fragment key={modal.id}>
                            {typeof types[modal.type] === 'object' ? (
                                React.cloneElement(types[modal.type], {
                                    id: modal.id,
                                    json: modal.json
                                })
                            ) : <></>}
                        </React.Fragment>
                    )
                })
            }
        </>
    );
}
