import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import { DebounceInput } from 'react-debounce-input';
import {
    ShoppingCart as ShoppingCartIcon,
    User as UserIcon,
    Search as SearchIcon,
    Box as BoxIcon,
    LogOut as LogOutIcon,
    MapPin as MapPinIcon,
    Menu as MenuIcon,
    X as CloseIcon
} from 'react-feather';


import {
    styled,
    AppBar,
    Box,
    Container,
    Toolbar,
    makeStyles,
    withStyles,
    IconButton,
    InputAdornment,
    Popper,
    Paper,
    Fade,
    Typography,
    Avatar,
    Badge,
    MenuList,
    MenuItem,
    ClickAwayListener,
    useTheme,
    useMediaQuery,
    Grid,
    TextField,
    Hidden,
    Button,
    // Drawer,
} from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { logout, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import Logo from 'src/components/Logo';
import LogoWhite from 'src/components/LogoWhite';
import ProductCardMenu from 'src/components/ProductCardMenu';
import DrawerCart from 'src/layouts/MainLayout/Cart';
import Login from 'src/layouts/MainLayout/Login';
import TopMenu from 'src/layouts/MainLayout/Topbar/Menu/TopMenu';
import Categories from 'src/layouts/MainLayout/Topbar/Menu/Categories';
import DrawerCategories from 'src/layouts/MainLayout/Topbar/Menu/DrawerCategories';
import DrawerSearch from 'src/layouts/MainLayout/Topbar/Menu/DrawerSearch';
import ManagerApi from 'src/services/managerApi';

import iconSearch from 'src/theme/scss/icons/iconSearch.svg';
import iconCart from 'src/theme/scss/icons/iconCart.svg';
import iconMenu from 'src/theme/scss/icons/iconMenu.svg';

import { HeaderMessage } from './HeaderMessage';
import { trackSearch } from 'src/utils/analytics';

function getHeaderColor(isHomePage, isHeaderActive) {
    if (isHomePage) {
        return isHeaderActive ? '#2e2e2e' : 'linear-gradient(180deg, #000000 -22.67%, rgba(0, 0, 0, 0) 92.67%)';
    }
    return '#ffffff';
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    searchBlockA: {
        backgroundColor: '#000000',
        padding: 50
    },
    header: {
        background: props => getHeaderColor(props.isHomePage, props.isHeaderActive),
        boxShadow: props => props.isHeaderActive ? '1px 1px 5px 0px #CCCCCC' : 'initial',
        transition: 'background .3s',
    },
    toolbar: {
        width: '100%',
        padding: '10px 0',
        justifyContent: "space-between",
        transition: '0.2s',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    toolbarFixed: {
        width: '100%',
        padding: 0,
        justifyContent: "space-between",
        transition: '0.2s',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 0',
        },
    },
    toolbarMobile: {
        width: '100%',
        justifyContent: "space-between",
        padding: '5px 0',
    },
    logoContainer: {
        width: 186,
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1
        },
    },
    logoImage: {
        marginRight: theme.spacing(2),
        maxHeight: 44,
        transition: '0.2s',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 100,
        },
    },
    logoImageSmall: {
        marginRight: theme.spacing(2),
        maxHeight: 36,
        transition: '0.2s',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 100,
        },
    },
    divider: {
        width: 1,
        height: 64,
    },
    searchForm: {
        flex: 1,
        marginRight: theme.spacing(2)
    },
    searchField: {
        flex: 1,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            position: 'inherit'
        },
    },
    notchedOutline: {
        borderWidth: 0,
        borderColor: "",
        "&:hover": {
            backgroundColor: 'rgb(7, 177, 77, 0.42)'
        }
    },
    inputSearchHidden: {
        position: 'absolute',
        top: '5px',
        right: '120px',
        display: 'none',
        opacity: 0
    },
    inputSearchOpen: {
        position: 'absolute',
        top: '5px',
        left: '50%',
        marginLeft: -320,
        display: 'block',
        opacity: 2,
        width: '350px',
        zIndex: 9,
        [theme.breakpoints.down('sm')]: {
            top: 60,
            right: 0,
            left: 0,
            marginLeft: 0,
            width: 'auto',
            backgroundColor: '#333333',
            padding: '30px 20px 40px 20px',
            boxShadow: '0 50px 80px rgba(0, 0, 0, 0.06)',
            borderRadius: '0 0 8px 8px',
        },
    },
    searchInput: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        padding: '8px',
        borderRadius: '10px',
        width: '100%',
    },
    showIconSearch: {
        visibility: 'visible',
        [theme.breakpoints.down('sm')]: {
            width: 58,
            borderRadius: '8px 8px 0 0',
        }
    },
    openIconSearch: {
        [theme.breakpoints.down('sm')]: {
            width: 58,
            borderRadius: '8px 8px 0 0 !important',
            backgroundColor: '#333333 !important',
            '& path': {
                fill: '#ffffff !important',
            }
        }
    },
    iconsContainer: {
        display: 'flex',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            gap: '1rem',
        }
    },
    iconButton: {
        color: '#ffffff',
        padding: 10,
        background: 'transparent',
        position: 'relative',
        '& + &': {
            '&::before': {
                content: "''",
                display: 'block',
                height: '4px',
                width: '4px',
                borderRadius: '50%',
                backgroundColor: '#ff367d', // '#ed1c24',
                position: 'absolute',
                left: '-1rem',
            }
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: "inherit",
            paddingRight: "10px !important",
            paddingLeft: "10px !important"
        },
    },
    searchActionDesktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    searchActionMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'inherit'
        },
    },
    closingIconButton: {
        color: '#ffffff',
        padding: 0,
        background: 'transparent',
        paddingRight: 0,
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    searchIconField: {
        color: '#757575',
    },
    searchIconFieldMobile: {
        color: '#ed1c24',
    },
    iconLogin: {
        borderRadius: '0%',
        height: 64,
    },
    icon: {
        height: 21,
        '& path': {
            fill: props => props.isHomePage ? '#ffffff' : '#333333'
        }
    },
    poper: {
        zIndex: '99999999',
        width: '200px'
    },
    dividerAccount: {
        width: '100%',
    },
    styleIconButton: {
        borderRadius: '0',
        width: '100%'
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    menu: {
        width: '100%',
        maxHeight: '400px',
        overflow: 'auto',
        zIndex: '99999',
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0px 1px 5px 1px #DDD'
    },
    typographyMenu: {
        textDecoration: 'none',
        color: '#263238',
    },
    divMenu: {
        width: '100%'
    }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        top: 0,
        color: '#FFFFFF',
        backgroundColor: '#ff367d', // '#db1842'
    },
}))(Badge);

const SearchTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'red',
        },
        '&:hover fieldset': {
            borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
});

function TopBar({ className, ...rest }) {
    const isHomePage = window.location.pathname === '/';
    const [isHeaderActive, setIsHeaderActive] = useState(false);

    const store = useSelector((state) => state.interface.store);
    const classes = useStyles({ isHomePage, isHeaderActive });
    const history = useHistory();
    const dispatch = useDispatch();
    const cartRef = useRef(null);

    const categoryApi = new ManagerApi('/catalog/category');
    const productApi = new ManagerApi('/catalog/product');

    const [openDrawer, setOpenDrawer] = useState(null);

    const [openMenu, setOpenMenu] = useState(false);

    const [showSearchField, setShowSearchField] = useState(false);

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [searchCategories, setSearchCategories] = useState([]);
    const [searchProducts, setSearchProducts] = useState([]);
    const [translate, i18n] = useTranslation();

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('md')));

    const userAccount = useSelector((state) => state.account.user);
    const cartOrder = useSelector((state) => state.cart.order);

    let quantity = 0

    if (cartOrder.items.length > 1) {
        quantity = cartOrder.items.map(item => {
            if (item.parent && item.type != 'addon') {
                return 0;
            }
            return parseInt(item.quantity);
        });
    } else if (cartOrder.items.length === 1) {
        quantity = cartOrder.items.map(item => item.quantity);
    }

    let quantityOrderCart = quantity.length > 0 ? quantity.reduce((acc, current) => acc + current) : 0;

    function handleScroll(event) {
        const pagePosition = event.target.scrollTop
        setIsHeaderActive(pagePosition > 50);
    }

    useEffect(() => {
        const pageContent = document.getElementById('page-content');
        pageContent.addEventListener('scroll', handleScroll, true);
        return () => {
            pageContent.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const showMenuAccount = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const logoutUser = () => {
        dispatch(logout());
        setOpen(false);
        history.push('/');
    }

    const handleCloseMenuAccount = () => {
        setOpen(false);
    }

    const handleDrawerOpen = (drawerId) => {
        if (openDrawer === drawerId) {
            drawerId = null
        }
        setOpenDrawer(drawerId)
    };

    const handleDrawerClose = () => {
        setOpenDrawer(null);
    };

    const handleModalOpen = () => {
        !openModal ? setOpenModal(true) : setOpenModal(false);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {

        if (quantityOrderCart > 0) {
            if (cartRef.current) {
                cartRef.current.className = cartRef.current.className + ' shopping-cart';
            }
        }

        setTimeout(() => {
            if (cartRef.current) {
                cartRef.current.className = 'MuiBadge-root';
            }
        }, 1000);

    }, [cartOrder.items]);

    return (
        <>
            <AppBar
                id='topHeader'
                className={clsx(classes.header, className)}
                color="default"
                {...rest}
            >
                <HeaderMessage />
                <TopMenu />
                <Box className='main-header'>
                    <Container maxWidth={false}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Toolbar className={isHeaderActive ? classes.toolbarFixed : classes.toolbar}>
                                <Box className={classes.logoContainer}>
                                    <RouterLink to="/">
                                        {isHomePage ? <LogoWhite className={isHeaderActive ? classes.logoImageSmall : classes.logoImage} /> : <Logo className={isHeaderActive ? classes.logoImageSmall : classes.logoImage} />}
                                    </RouterLink>
                                </Box>
                                {store.interface.hasCategoriesMenu && (<Categories />)}

                                {/* {store.interface.hasSearchBar && (
                                    <>
                                        {mobileSize ? (
                                            <>
                                                <Divider className={classes.divider} />
                                                <IconButton onClick={() => handleDrawerOpen('search')} className={classes.iconButton + ' ' + classes.iconLogin}>
                                                    <SearchIcon size='24px' />
                                                </IconButton>
                                            </>
                                        ) : (
                                            <Box className={classes.searchForm}>
                                                <Formik
                                                    initialValues={{ term: '' }}
                                                    enableReinitialize
                                                    onSubmit={async (values) => {
                                                        history.push('/search?query=' + values.term);
                                                    }}
                                                >
                                                    {({
                                                        handleBlur,
                                                        handleSubmit,
                                                        setValues,
                                                        values,
                                                    }) => {

                                                        const handleOpen = () => {
                                                            setOpenMenu(true);
                                                        };

                                                        const handleClose = () => {
                                                            setOpenMenu(false);

                                                            setValues({ ...values, term: '' });
                                                        };

                                                        const fetchData = async (term) => {

                                                            if (term.length < 3) {
                                                                return;
                                                            }

                                                            const requestId = uuidv4();
                                                            dispatch(addLoadingGlobal(requestId));
                                                            let response;

                                                            const filtersProducts = {
                                                                search: term,
                                                                paginator: {
                                                                    pageItemsPerPage: 4,
                                                                    sortOrder: {
                                                                        price: 'ASC',
                                                                        saleable: 'DESC',
                                                                    }
                                                                }
                                                            };
                                                            response = await productApi.getList(filtersProducts);
                                                            if (response.data.success) {
                                                                setSearchProducts(response.data.content);
                                                            }

                                                            const filtersCategories = { name: term };
                                                            response = await categoryApi.getList(filtersCategories)
                                                            if (response.data.success) {
                                                                setSearchCategories(response.data.content);
                                                            }

                                                            dispatch(removeLoadingGlobal(requestId));
                                                            handleOpen();
                                                        };

                                                        const generateMenu = () => {

                                                            // if (searchProducts.length === 0 && searchCategories.length === 0) {
                                                            //     return <></>;
                                                            // }

                                                            if (!openMenu) {
                                                                return (<></>);
                                                            }

                                                            return (
                                                                <Box boxShadow={2} onMouseLeave={handleClose}>
                                                                    <Container maxWidth="md" className={classes.menu}>

                                                                        {searchCategories.length == 0 && searchProducts.length == 0 && (
                                                                            <>
                                                                                <Box
                                                                                    display='flex'
                                                                                    flexDirection='column'
                                                                                    justifyContent='center'
                                                                                    mb={3}
                                                                                    mt={3}
                                                                                >
                                                                                    <Typography
                                                                                        align="center"
                                                                                        variant="subtitle1"
                                                                                    >
                                                                                        Infelizmente não encontramos nenhum produto para a sua busca.
                                                                                    </Typography>
                                                                                </Box>
                                                                            </>
                                                                        )}

                                                                        {searchCategories.length > 0 && (
                                                                            <>
                                                                                <Box pt={3} pb={3}>
                                                                                    <Grid container spacing={1} >
                                                                                        {searchCategories.map(item => {
                                                                                            let url = item?.url;
                                                                                            let urlParent = item?.parent?.url;

                                                                                            url = url?.replace('marcas/', `catalogo?marca=`);

                                                                                            if (item?.parent?.url === 'marcas') {
                                                                                                urlParent = url;
                                                                                            }

                                                                                            return (
                                                                                                item !== undefined &&
                                                                                                <>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Typography className={classes.typographyMenu} component={RouterLink} to={`/${url}`}>
                                                                                                            <div className={classes.divMenu}>{item.name} em Todos os departamentos</div>
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    {item.parent !== null && (
                                                                                                        <Grid item xs={12}>
                                                                                                            <Typography className={classes.typographyMenu} component={RouterLink} to={`/${urlParent}`}>
                                                                                                                <div className={classes.divMenu}>{item.name} em {item.parent.name}</div>
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                    )}
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                    </Grid>
                                                                                </Box>
                                                                                <Divider />
                                                                            </>
                                                                        )}
                                                                        {searchProducts.length > 0 && (
                                                                            <Box pt={3} pb={3} style={{ maxHeight: '400px' }}>
                                                                                <Grid container spacing={1} >
                                                                                    {searchProducts.map(item =>
                                                                                        item !== undefined &&
                                                                                        <Grid item xs={12}>
                                                                                            <ProductCardMenu product={item} />
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Box>
                                                                        )}
                                                                    </Container>
                                                                </Box>
                                                            )
                                                        }

                                                        const handleChangeTerm = async (e) => {
                                                            const { value } = e.target;

                                                            setValues({ ...values, term: value });

                                                            fetchData(value);
                                                        };

                                                        return (
                                                            <Form
                                                                onSubmit={handleSubmit}
                                                            >
                                                                <DebounceInput
                                                                    element={InputBase}
                                                                    minLength={3}
                                                                    debounceTimeout={500}
                                                                    onChange={(e) => { handleChangeTerm(e); }}
                                                                    onBlur={handleBlur}
                                                                    name="term"
                                                                    value={values.term}
                                                                    className={classes.searchInput}
                                                                    variant="outlined"
                                                                    placeholder={translate('titleSearchTopBar')}
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    size="small"
                                                                    endAdornment={(
                                                                        <InputAdornment>
                                                                            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                                                                <SearchIcon />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )}
                                                                />
                                                                {generateMenu()}
                                                            </Form>
                                                        )
                                                    }}
                                                </Formik>
                                            </Box>
                                        )}
                                    </>
                                )} */}

                                <Popper
                                    className={classes.poper}
                                    open={open}
                                    anchorEl={anchorEl}
                                    placement={placement}
                                    transition
                                >
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleCloseMenuAccount}>
                                                    <MenuList>
                                                        <MenuItem component={RouterLink} to="/customer/account" onClick={handleCloseMenuAccount}>
                                                            <UserIcon size='18px' />
                                                            <Typography component={Box} pl={1}>{translate('typographyMyAccount')}</Typography>
                                                        </MenuItem>
                                                        <MenuItem component={RouterLink} to="/customer/account/orders" onClick={handleCloseMenuAccount}>
                                                            <BoxIcon size='18px' />
                                                            <Typography component={Box} pl={1}>{translate('typographyMyOrders')}</Typography>
                                                        </MenuItem>
                                                        <MenuItem component={RouterLink} to="/customer/account/addresses" onClick={handleCloseMenuAccount}>
                                                            <MapPinIcon size='18px' />
                                                            <Typography component={Box} pl={1}>{translate('titleAddressesListCustomer')}</Typography>
                                                        </MenuItem>
                                                        <MenuItem onClick={logoutUser}>
                                                            <LogOutIcon size='18px' />
                                                            <Typography component={Box} pl={1}>{translate('typographyLogout')}</Typography>
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                                <Box className={classes.root}>
                                    <Box className={classes.searchField}>
                                        <Formik
                                            initialValues={{ term: '' }}
                                            enableReinitialize
                                            onSubmit={async (values) => {
                                                setShowSearchField(!showSearchField);
                                                trackSearch(values.term);
                                                history.push('/search?q=' + values.term);
                                                values.term = '';
                                            }}
                                        >
                                            {({ handleBlur,
                                                handleSubmit,
                                                setValues,
                                                values }) => {

                                                const handleChangeTerm = async (e) => {
                                                    const { value } = e.target;

                                                    setValues({ ...values, term: value });

                                                    // fetchData(value); @TODO: re-ativar apenas se aplicar debounce, paginar requisição.
                                                };

                                                return (
                                                    <>
                                                        <Form onSubmit={handleSubmit}>
                                                            <SearchTextField
                                                                onBlur={handleBlur}
                                                                onChange={handleChangeTerm}
                                                                value={values.term}
                                                                id="input-with-icon-textfield"
                                                                className={showSearchField ? classes.inputSearchOpen + ' inputSearchBlock' : classes.inputSearchHidden}
                                                                label="Buscar por produto"
                                                                placeholder="O que você procura?"
                                                                InputLabelProps={{
                                                                    style: {
                                                                        color: '#757575'
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <Button onClick={() => { handleSubmit() }} className={classes.searchActionDesktop}>
                                                                            <SearchIcon className={classes.searchIconField} />
                                                                        </Button>
                                                                    ),
                                                                    endAdornment: (
                                                                        <>
                                                                            <Button onClick={() => { setShowSearchField(!showSearchField) }} className={classes.closingIconButton}>
                                                                                <CloseIcon className={classes.searchIconField} />
                                                                            </Button>
                                                                            <Button onClick={() => { handleSubmit() }} className={classes.searchActionMobile}>
                                                                                <SearchIcon className={classes.searchIconFieldMobile} />
                                                                            </Button>
                                                                        </>
                                                                    ),
                                                                    classes: {
                                                                        notchedOutline: classes.notchedOutline
                                                                    }
                                                                }}
                                                                variant="outlined"
                                                                autoFocus
                                                            />
                                                        </Form>
                                                    </>
                                                );
                                            }}

                                        </Formik>

                                    </Box>
                                    <Box className={classes.iconsContainer}>
                                        {!mobileSize && (
                                            <IconButton
                                                className={!showSearchField ? classes.showIconSearch + ' ' + classes.iconButton + ' ' + classes.iconLogin : classes.openIconSearch + ' ' + classes.iconButton + ' ' + classes.iconLogin}
                                                onClick={() => { setShowSearchField(!showSearchField) }}
                                            >
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.icon}>
                                                    <path d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z" fill="white" />
                                                </svg>
                                            </IconButton>
                                        )}
                                        {!mobileSize && (
                                            <IconButton
                                                onClick={() => handleDrawerOpen('cart')}
                                                className={classes.iconButton + ' ' + classes.iconLogin}
                                            >
                                                <StyledBadge
                                                    badgeContent={quantityOrderCart}
                                                    ref={cartRef}
                                                >
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.icon}>
                                                        <path d="M7.58161 19.472C6.38995 19.472 5.42578 20.4456 5.42578 21.6356C5.42578 22.8256 6.38995 23.7992 7.58161 23.7992C8.77328 23.7992 9.74828 22.8256 9.74828 21.6356C9.74828 20.4456 8.77328 19.472 7.58161 19.472Z" fill="white" />
                                                        <path d="M18.4136 19.472C17.222 19.472 16.2578 20.4456 16.2578 21.6356C16.2578 22.8256 17.222 23.7992 18.4136 23.7992C19.6053 23.7992 20.5803 22.8256 20.5803 21.6356C20.5803 20.4456 19.6053 19.472 18.4136 19.472Z" fill="white" />
                                                        <path d="M16.8445 14.0631C17.657 14.0631 18.372 13.6196 18.7404 12.9489L22.6187 5.92816C23.0195 5.21419 22.4995 4.32713 21.6762 4.32713H5.64286L4.62453 2.16357H1.08203V4.32713H3.2487L7.1487 12.5378L5.6862 15.1774C4.89536 16.6269 5.93536 18.3902 7.58203 18.3902H20.582V16.2267H7.58203L8.7737 14.0631H16.8445ZM6.67203 6.49069H19.8345L16.8445 11.8996H9.23953L6.67203 6.49069Z" fill="white" />
                                                    </svg>
                                                </StyledBadge>
                                            </IconButton>
                                        )}
                                        {mobileSize && (
                                            <IconButton onClick={() => handleDrawerOpen('categories')} className={classes.iconButton + ' ' + classes.iconLogin}>
                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.icon}>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.33335C0 0.979723 0.120405 0.640581 0.334726 0.390529C0.549048 0.140478 0.839731 0 1.14283 0H14.8568C15.1599 0 15.4505 0.140478 15.6649 0.390529C15.8792 0.640581 15.9996 0.979723 15.9996 1.33335C15.9996 1.68698 15.8792 2.02612 15.6649 2.27617C15.4505 2.52622 15.1599 2.6667 14.8568 2.6667H1.14283C0.839731 2.6667 0.549048 2.52622 0.334726 2.27617C0.120405 2.02612 0 1.68698 0 1.33335ZM0 8.00056C0 7.64693 0.120405 7.30779 0.334726 7.05774C0.549048 6.80768 0.839731 6.66721 1.14283 6.66721H14.8568C15.1599 6.66721 15.4505 6.80768 15.6649 7.05774C15.8792 7.30779 15.9996 7.64693 15.9996 8.00056C15.9996 8.35418 15.8792 8.69332 15.6649 8.94337C15.4505 9.19343 15.1599 9.3339 14.8568 9.3339H1.14283C0.839731 9.3339 0.549048 9.19343 0.334726 8.94337C0.120405 8.69332 0 8.35418 0 8.00056ZM7.19495 13.724C6.98062 13.9741 6.86022 14.3132 6.86022 14.6668C6.86022 15.0205 6.98062 15.3596 7.19495 15.6097C7.40927 15.8597 7.69995 16.0002 8.00305 16.0002H14.86C15.1631 16.0002 15.4538 15.8597 15.6681 15.6097C15.8824 15.3596 16.0028 15.0205 16.0028 14.6668C16.0028 14.3132 15.8824 13.9741 15.6681 13.724C15.4538 13.474 15.1631 13.3335 14.86 13.3335H8.00305C7.69995 13.3335 7.40927 13.474 7.19495 13.724Z" fill="white" />
                                                </svg>
                                            </IconButton>
                                        )}
                                    </Box>
                                </Box>
                            </Toolbar>
                        </Grid>
                    </Container>
                </Box>
            </AppBar>

            <DrawerCart handleDrawerClose={handleDrawerClose} openDrawer={openDrawer === 'cart'} order={cartOrder} />
            <DrawerCategories handleDrawerClose={handleDrawerClose} openDrawer={openDrawer === 'categories'} />
            <DrawerSearch handleDrawerClose={handleDrawerClose} openDrawer={openDrawer === 'search'} />

        </>
    );
}

TopBar.propTypes = {
    className: PropTypes.string
};

export default TopBar;
