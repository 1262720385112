import React, { useState, useEffect } from 'react';
import { apiWpBlog } from 'src/utils/apiWP';
import { makeStyles } from '@material-ui/core/styles';

import {
    Box,
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import arrowRightBlack from 'src/theme/scss/icons/arrow-right-black.svg';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    bigTitle: {
        color: '#EBEBEB',
        textAlign: 'center',
        marginBottom: '-90px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
            marginBottom: '-74px',
        },
    },
}));

export default function BlogItems() {
	const classes = useStyles();
    const [posts, setPosts] = useState(null);

    const createMarkup = (content) => {
        return {
            __html: content
        };
    };

    const fetchPosts = async () => {
        apiWpBlog.get(`https://blog.avell.com.br/wp-json/wp/v2/posts?_embed&page=1&per_page=3&search=`).then((response) => {
            if (response.request.status === 200) {
                setPosts(response.data);
            }
        }).catch((error) => {
        }).then(() => {
        });
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    return (
        <>
            <Box className="blog-items">
                <Container maxWidth={false}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={8}
                    >
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography className={classes.bigTitle} variant="overline">
                                fique por dentro
                            </Typography>
                        </Grid>
                        {posts && posts.map((post) => {
                            const imagePost = post['_embedded']['wp:featuredmedia'] !== undefined && post['_embedded']['wp:featuredmedia'][0]['source_url'];
                            return (
                                <Grid item md={4} sm={12} xs={12}>
                                    <RouterLink to={{ pathname: `/blog/${post.slug}`, state: { entityId: post.id, name: post.name, wpTerm: post['_embedded']['wp:term'] } }} className="card">
                                        <div className="card-image" style={{ backgroundImage: 'url("'+ imagePost +'")' }}>
                                        </div>
                                        <div className="card-info">
                                            <Typography variant="caption" className="caption">
                                                <span className={classes.spanCaption}>{ moment(post.date).format('LL') }</span>
                                                <span className={classes.spanCaption}>{post['_embedded']['wp:term'] !== undefined && ( post['_embedded']['wp:term'][0][0].name )}</span>
                                            </Typography>
                                            <Typography variant="h3" className="title-3" dangerouslySetInnerHTML={createMarkup(post.title.rendered)}></Typography>
                                            <Typography variant="body1" className="text-1" dangerouslySetInnerHTML={createMarkup(post.excerpt.rendered)}></Typography>
                                            <div className="btn btn-8">
                                                Leia mais
                                                <img src={arrowRightBlack} className="icon" />
                                            </div>
                                        </div>
                                    </RouterLink>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Box>
        </>
    )
}