import React from "react";

import {
  Box,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { getBoxMarginProps, getBreakpointValue } from "./functions";

export const Video = (props) => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

  const {
    url,
    height,
    width
  } = props;

  return (
    <Box
      {...getBoxMarginProps(props, breakpoint)}
      textAlign={getBreakpointValue(props, breakpoint, 'textAlign')}
    >
      <iframe
        width={width}
        height={height}
        src={url}
      >
      </iframe>
    </Box>
  )
}
