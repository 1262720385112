import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Typography,
    IconButton,
    ButtonGroup,
    Button
} from '@material-ui/core';
import {
    Plus as PlusIcon,
    Minus as MinusIcon
} from 'react-feather';
import { deleteItemCart, updateItemCart, addMessage } from 'src/actions';
import DialogComponent from 'src/components/Dialog/Dialog';
import deleteIcon from 'src/theme/scss/icons/delete.svg';
import arrowIcon from 'src/theme/scss/icons/blueArrow.svg';
import ConfigurationList from 'src/layouts/Catalog/Product/Bundle/ConfigurationList';
import Price from 'src/layouts/Catalog/Product/Bundle/Price';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        padding: '15px 0',
    },
    productMedia: {
        width: '80px;',
        height: '80px;',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    btnGroupQty: {
        marginRight: 20
    },
    btnQty: {
        fontFamily: 'Gilroy-ExtraBold',
        fontWeight: 100,
        border: 0,
        backgroundColor: '#f4f4f4',
        padding: '7px 12px',
        minWidth: 'auto'
    },
    settingsButton: {
        fontFamily: 'Manrope',
        fontSize: 12,
        textTransform: 'none',
        color: '#0084F4',
        padding: '4px 0 12px',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    hiddenMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    showMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    }
}));

export default function Item({ item, items, editCart }) {
    const [translate, i18n] = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [stateDialog, setStateDialog] = useState(false);
    const [idItem, setIdItem] = useState('');
    const store = useSelector((state) => state.interface.store);

    const theme = useTheme();

    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const handleDeleteItemCart = (id) => {
        setIdItem(id);
        setStateModal();

        if (stateDialog)
            dispatch(deleteItemCart(idItem));
    };

    const setStateModal = () => {
        stateDialog ? setStateDialog(false) : setStateDialog(true);
    };

    const handleClick = (e) => {
        let newCount = item.quantity;
        let bundleData = [];

        items.map(product => {
            if (item.id == product.parent) {
                bundleData.push({
                    id: product.id,
                    product: product.productId,
                    quantity: (product.quantity / item.quantity)
                });
            }
        });

        if (e.currentTarget.id === "increment") {
            newCount++;
        }
        else {
            newCount--;
            if (newCount == 0) {
                handleDeleteItemCart(item);
                return;
            }
        }
        dispatch(updateItemCart(item, newCount, bundleData));
    };

    let productImage = store.productPlaceholder;
    if (item.mediaUrl !== '') {
        productImage = item.mediaUrl;
    }

    const [openSettings, setOpenSettings] = useState(false);

    return (
        <>
            <Box
                key={item.id}
                className={classes.root}
            >
                <Box display='flex' mr={2}>
                    <Box className={classes.productMedia} style={{ backgroundImage: 'url("' + productImage + '")' }} />
                </Box>
                <Box display="flex">
                    <Box flexGrow={1} mr={2}>
                        <Typography variant="h6">{item.name}</Typography>
                        <Box className={classes.hiddenMobile}>
                            <Button className={classes.settingsButton} onClick={() => setOpenSettings(!openSettings)}>
                                Ver detalhes do produto
                                <img src={arrowIcon} style={{ marginLeft: 10 }} />
                            </Button>
                            {openSettings && (<ConfigurationList item={item} items={items} />)}
                        </Box>
                    </Box>
                </Box>
                <Box flexGrow={1}>
                    <Box display="flex" alignItems={mobileSize ? "flex-start" : "center"} flexDirection={mobileSize ? "column" : "row"}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" flexGrow={mobileSize ? 0 : 1}>
                            {/* <Box mr={2}>
                            <Typography variant="caption" color="textSecondary">{translate('labelQuantity')}:</Typography>
                        </Box> */}
                            <Box display="flex" >
                                {editCart ? (
                                    <>
                                        <ButtonGroup size="small" aria-label="outlined primary button group" className={classes.btnGroupQty}>
                                            <Button
                                                id='decrement'
                                                onClick={handleClick}
                                                className={classes.btnQty}
                                            >
                                                <MinusIcon size='14px' />
                                            </Button>
                                            <Button className={classes.btnQty}>{item.quantity}</Button>
                                            <Button
                                                id='increment'
                                                onClick={handleClick}
                                                className={classes.btnQty}
                                            >
                                                <PlusIcon size='14px' />
                                            </Button>
                                        </ButtonGroup>
                                    </>
                                ) : (<Typography variant="caption" color="textSecondary"> {item.quantity} </Typography>)}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Price item={item} items={items} />
                    {editCart ? (
                        <Box>
                            <IconButton className={classes.deleteItemCart} onClick={() => handleDeleteItemCart(item)}>
                                <img src={deleteIcon} alt="Remover" />
                            </IconButton>
                        </Box>
                    ) : null}
                </Box>
                <Box className={classes.showMobile}>
                    <Button className={classes.settingsButton} onClick={() => setOpenSettings(!openSettings)}>
                        Ver detalhes do produto
                        <img src={arrowIcon} style={{ marginLeft: 10 }} />
                    </Button>
                    {openSettings && (<ConfigurationList item={item} items={items} />)}
                </Box>
                {stateDialog ?
                    <DialogComponent
                        state={stateDialog}
                        title=''
                        text={translate('textAskConfirmDeleteAction')}
                        buttonClose={translate('buttonCancel')}
                        buttonSubmit={translate('buttonConfirm')}
                        handleClose={() => setStateModal()}
                        handleSubmit={() => handleDeleteItemCart()}
                    /> : null}
            </Box>
        </>
    )
}
