import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, darken, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    buttonSubmit: {
        backgroundColor: '#ff367d',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: darken('#ff367d', 0.1),
        }
    },
    dialogContent: {
        padding: '24px 16px',
        '& h4': {
            fontSize: '18px',
        },
    },
    dialogActions: {
        '& button': {
            fontSize: '14px',
            alignSelf: 'stretch',
            flexBasis: '50%',
        },
    },
});

export default function DialogComponent({ state, text, title, buttonClose, buttonSubmit, handleClose, handleSubmit }) {
    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={state}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                {title && (
                    <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                )}
                <DialogContent dividers className={classes.dialogContent}>
                    <Typography variant="h4">{text}</Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button fullWidth onClick={handleClose}>
                        {buttonClose}
                    </Button>
                    <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                        className={classes.buttonSubmit}
                    >
                        {buttonSubmit}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
