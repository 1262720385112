import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addFavorite, addMessage, loadLocalStorageFavorites } from 'src/actions';
import databaseManager from 'src/services/databaseManager';
import { trackWishlist } from 'src/utils/analytics';
import { getDefaultProductPrices } from 'src/utils/functions';

const useFavorites = _ => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stateFavoritesList = useSelector((state) => state.interface.favorites);
  const loadingFavorites = useSelector((state) => state.interface.loadingFavorites);
  const [favoritesList, setFavoritesList] = useState([]);
  const [browserHasSuport, setBrowserHasSuport] = useState(true);

  useEffect(_ => {
    if (browserHasSuport) {
      if (loadingFavorites === false) {
        if (!Array.isArray(stateFavoritesList) || stateFavoritesList?.length === 0) {
          setFavoritesList(null)
          return
        }
        setFavoritesList(stateFavoritesList)
      }
    }
  }, [stateFavoritesList, loadingFavorites]);

  useEffect(_ => {
    databaseManager.verifyBrowserSuport().then(result => setBrowserHasSuport(result));
  }, []);

  const addProductToFavorites = async (product) => {
    try {
      if (!browserHasSuport) {
        const favorites = JSON.parse(window.localStorage.getItem('favorites')) || [];
        if (favorites.find(item => item === product.id)) return;
        favorites.push(product.id);
        window.localStorage.setItem('favorites', JSON.stringify(favorites));
        return dispatch(loadLocalStorageFavorites());
      }

      await databaseManager.addFavoriteProduct(product);

      await updateReduxState();

      dispatch(addMessage('Produto adicionado com sucesso', 'success'));

      const { finalPrice } = getDefaultProductPrices(product);
      product.price = finalPrice;
      trackWishlist(product);
    } catch (e) {
      dispatch(addMessage('Ocorreu um erro ao adicionar o produto aos favoritos', 'error'));
      console.error('Ocorreu um erro ao adicionar o produto aos favoritos', e);
    }
  }

  const removeProductFromFavorite = async (productId) => {
    try {
      if (!browserHasSuport) {
        const favorites = JSON.parse(window.localStorage.getItem('favorites')) || [];
        const newFavorites = favorites.filter(item => item !== productId);
        window.localStorage.setItem('favorites', JSON.stringify(newFavorites));
        return dispatch(loadLocalStorageFavorites());
      }

      await databaseManager.removeFavoriteByProductId(productId);

      await updateReduxState();

      dispatch(addMessage('Produto removido dos favoritos', 'error'));
    } catch (e) {
      dispatch(addMessage('Ocorreu um erro ao tentar remover o produto dos favoritos', 'error'));
      console.error('Ocorreu um erro ao tentar remover o produto dos favoritos', e);
    }
  }

  const favoritesByFilter = async (filter) => {
    try {
      // if (!browserHasSuport) {
      //     const favorites = JSON.parse(window.localStorage.getItem('favorites')) || [];
      //     const newFavorites = favorites.filter(item => item !== productId);
      //     window.localStorage.setItem('favorites', JSON.stringify(newFavorites));
      //     return dispatch(loadLocalStorageFavorites());
      // }

      const favorites = await databaseManager.getAllFavoritesByFilter(filter);
      dispatch(addFavorite(favorites));
    } catch (e) {
      // dispatch(addMessage('Ocorreu um erro ao tentar remover o produto dos favoritos', 'error'));
      console.error('Ocorreu um erro ao tentar filtrar os produtos dos favoritos', e);
    }
  }

  const updateReduxState = async _ => {
    const favorites = await databaseManager.getAllFavorites();
    dispatch(addFavorite(favorites));
  }

  return {
    addProductToFavorites,
    removeProductFromFavorite,
    favoritesByFilter,
    favoritesList,
    browserHasSuport
  }
}

export default useFavorites;
