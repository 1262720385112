import React from 'react';
import {
    makeStyles,
    Box,
    Typography
} from '@material-ui/core';
import { formatPrice } from 'src/utils/functions.js';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    infoPrice: {
        marginTop: '8px',
    },
    priceText: {
        color: '#333333'
    },
    oldPrice: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-end",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    oldPriceText: {
        color: '#000',
        fontSize: '12px',
        fontWeight: 800,
    },
    infoInstallment: {
        color: '#ff367d',
        lineHeight: '1',
    },
    infoInstallmentCaption: {
        fontSize: '12px',
    },
    priceNow: {
        color: '#0085f8', //'#db1842',
        display: 'flex',
        alignItems: 'baseline',
        gap: '4px',
        [theme.breakpoints.down('sm')]: {
            '& h3': {
                fontSize: '18px',
            },
            '& h5': {
                fontSize: '14px',
            },
        },
    },
    infoDiscount: {
        fontSize: 9,
        fontWeight: 800,
        textTransform: 'uppercase',
        color: '#000', //'#db1842',
        backgroundColor: 'rgba(57, 217, 80, 1)',
        borderRadius: '4px',
        width: 'max-content',
        padding: '5px 10px',
        // marginLeft: 'auto',
        marginBottom: 5,
        [theme.breakpoints.down('xs')]: {
            margin: '0 auto 5px'
        },
    },
    installment: {
        fontSize: '12px'
    },
    discountPercent: {
        display: 'flex',
        alignItems: 'center',
    },
    priceText: {
        fontSize: '10px',
        marginRight: '5px',
    }
}));

export default function AddonListPrice({ price }) {
    const classes = useStyles();

    let total = Number(price);
    let discount = 0;

    let startPrice = Number(price);

    // const installmentValue = formatPrice(Number(total) / 12);
    const installmentTax = 1; //1.06284582;
    const installmentValue = formatPrice(Number(total * installmentTax) / 12);

    let totalDiscount = total - (total * (discount / 100));

    return (
        <Box className={classes.infoPrice}>
            {/* <Typography variant="body1" className={classes.oldPriceText}>
                {formatPrice(startPrice)}
            </Typography> */}
            <Box className={classes.discount}>
                <Box className={classes.discountPercent}>
                    <Typography variant="caption" className={classes.priceText}>
                        No PIX
                    </Typography>
                    {/* <Box className={classes.infoDiscount}>
                        {discount}% de  desconto
                    </Box> */}
                </Box>
                <Box className={classes.priceNow}>
                    <Typography variant="h5">
                        R$
                    </Typography>
                    <Typography variant="h3">
                        {totalDiscount.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.infoInstallment}>
                <Typography variant="caption" className={classes.infoInstallmentCaption}>
                    {/* ou Parcela Fácil Itaú em até 60x. */}
                    ou até 12 X de {installmentValue} {/* <span>sem juros</span> */}
                </Typography>
            </Box>
        </Box>
    );
}
