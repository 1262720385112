/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { colors, createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import { THEMES } from '../constants';
import { Italic } from 'react-feather';
import { red } from '@material-ui/core/colors';

const baseConfig = {
  direction: 'ltr',
  typography,
  palette: {
    primary: {
      main: '#db1842',
    },
    secondary: {
      main: '#000000',
    },
    tertiary: {
      main: '#ED1C24',
    },
    success: {
      main: '#76b900'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1500,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      outlined: {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px'
        }
      },
      outlinedPrimary: {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px'
        }
      },
      outlinedSecondary: {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px'
        }
      },
      root: {
        padding: '10px 25px',
        fontSize: '1.05rem',
        fontWeight: '800'
      },
      sizeLarge: {
        padding: '16px 50px',
        fontSize: '1.05rem',
        fontWeight: '800',
        '@media (max-width: 600px)': {
          padding: '15px'
        }
      }
    },
    MuiTypography: {
      h6: {
        fontFamily: 'Gilroy-Light',
        fontWeight: 200,
        lineHeight: 1
      },
      overline: {
        '@media (max-width: 600px)': {
          fontSize: '60px'
        }
      },
      body2: {
        lineHeight: 1.5
      }
    },
    MuiContainer: {
      maxWidthLg: {
        '@media (min-width: 1280px)': {
          maxWidth: '1500px'
        }
      }
    },
    // MuiInputBase: {
    //   input: {
    //     fontSize: '16px',
    //   },
    // },
  },
  shadows: softShadows
};

const themeConfigs = [
  {
    name: THEMES.STORE,
    components: {
      page: {
        title1: {

        }
      },
      productCard: {
        priceOld: {
          textDecoration: 'line-through',
          fontSize: '1rem',
          color: '#546e7a'
        },
        priceFinal: {
          fontWeight: '500',
          fontSize: '1.5rem',
        },
        discountTag: {
          backgroundColor: '#e51b23'
        }
      },
      productView: {
        priceOld: {
          textDecoration: 'line-through',
          fontSize: '1rem',
          color: '#546e7a'
        },
        priceFinal: {
          fontWeight: '500',
          fontSize: '2rem',
        },
        discountTag: {
          backgroundColor: '#e51b23'
        },
        buttonAddToCart: {

        }
      },
    }
  }
];

export function createTheme(settings = {}, storeTheme = {}) {

  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  themeConfig = storeTheme;

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction }
    )
  );

  if (settings.responsiveFontSizes) {
    // theme = responsiveFontSizes(theme);
  }

  return theme;
}
