import React from "react";
import {
    Box as MaterialBox,
} from "@material-ui/core";
import { useNode } from "@craftjs/core";

export const Box = ({ mt, mb, ml, mr, children }) => {
    const { connectors: { connect, drag } } = useNode();
    
    return (
        <MaterialBox mt={mt} mb={mb} ml={ml} mr={mr} ref={ref => connect(drag(ref))}>
            <div style={{ minWidth: '50px', minHeight: '50px', backgroundColor: '#fff', border: '1px dashed #bbb' }}>
                {children}
            </div>
        </MaterialBox>
    )
};