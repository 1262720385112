import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    Box,
    Grid,
    Card,
    CardContent,
    TextField,
    Button,
    IconButton
} from '@material-ui/core';
import {
    Mail as MailIcon,
    Facebook as FacebookIcon,
    Link as LinkIcon,
    XCircle as XCircleIcon,
} from 'react-feather';
import { addMessage, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import ShareCartEmail from './ShareCartEmail';

const useStyles = makeStyles((theme) => ({
    shareLinks: {
        color: '#fff',
        display: 'block',
        fontSize: '36px',
        marginTop: '15px',
        position: 'relative',
        background: theme.palette.primary.main,
        textAlign: 'center',
        textDecoration: 'none',
        paddingTop: '20px',
        paddingBottom: '20px',
        cursor: 'pointer',
        "&:active": {
        },
        "&.focused": {
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 5,
    },
}));

function ShareCart({ openModal, handleModalClose }) {
    const classes = useStyles();
    const [shareUrl, setShareUrl] = useState('');
    const [buttonCopyLabel, setButtonCopyLabel] = useState('Copiar');
    const [selected, setSelected] = useState(null);
    const ref = useRef(null);
    const [translate, i18n] = useTranslation();

    const order = useSelector((state) => state.cart.order);

    const dispatch = useDispatch();

    const saleQuoteApi = new ManagerApi('/sale/quote');

    const fetchShareUrl = async (data) => {
        const requestId = uuidv4();
        let shareUrl = '';

        dispatch(addLoadingGlobal(requestId));

        let response = await saleQuoteApi.post(data);

        dispatch(removeLoadingGlobal(requestId));
            
        if (response.data.success) {
            shareUrl = response.data.content.url;
        }
        
        return shareUrl;
    }

    const handleChangeType = async (type) => {
        setSelected(type);

        if(type !== 'email') {
            let data = {
                type: type,
                order: order.id
            };
            let shareUrl = await fetchShareUrl(data);
            setShareUrl(shareUrl);
            if(type === 'facebook') {
                window.open("https://www.facebook.com/dialog/share?app_id=2721589504818819&display=popup&href="+shareUrl);
            }
        }

    }

    const handleFormSubmit = async (values) => {
        let data = {
            ...values,
            type: 'email',
            order: order.id
        };
        let shareUrl = await fetchShareUrl(data);
        if(shareUrl !== '') {
            dispatch(addMessage("Carrinho compartilhado com sucesso!", 'success'));
            setSelected(null);
            handleModalClose();
        }
    }

    const copyText = () => {
        var copyText = ref.current;
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        setButtonCopyLabel("Copiado");
        setTimeout(() => {
            setButtonCopyLabel("Copiar");
        },2000);
    }

    return (
        <Dialog open={openModal} onClose={handleModalClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle onClose={handleModalClose}>
                {translate('dialogTitleShareCart')}
                {handleModalClose ? (
                    <IconButton onClick={handleModalClose} className={classes.closeButton}>
                        <XCircleIcon size='24px' />
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={4}>
                        <Card className={classes.shareLinks} onClick={() => handleChangeType('email')}>
                            <CardContent>
                                <MailIcon />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={classes.shareLinks} onClick={() => handleChangeType('facebook')}>
                            <CardContent>
                                <FacebookIcon />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={classes.shareLinks} onClick={() => handleChangeType('link')}>
                            <CardContent>
                                <LinkIcon />
                            </CardContent>
                        </Card>
                    </Grid>
                    {selected === 'email' && (
                        <Grid
                            item
                            md={12}
                            xs={12}
                        >
                            <ShareCartEmail onSubmit={handleFormSubmit} />
                        </Grid>
                    )}
                    {selected === 'link' && (
                        <>
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >
                                <TextField
                                    margin='dense'
                                    label={translate('Compartilhe')}
                                    name="copyURL"
                                    value={shareUrl}
                                    variant="outlined"
                                    fullWidth
                                    inputRef={ref}
                                />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <Box mr={2} mt={1}>
                                    <Button
                                        color='primary'
                                        variant='outlined'
                                        fullWidth
                                        style={{ padding: '7px 16px' }}
                                        onClick={copyText}
                                    >
                                        {buttonCopyLabel}
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

ShareCart.propTypes = {
    openModal: PropTypes.bool.isRequired,
    handleModalClose: PropTypes.func.isRequired
};

export default ShareCart;
