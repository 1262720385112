import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addLoadingGlobal, fetchCart, removeLoadingGlobal } from 'src/actions';
import ManagerApi from 'src/services/managerApi';

function Utm() {
    const dispatch = useDispatch();
    const order = useSelector((state) => state.cart.order);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const utm = Cookies.get('utm') ? JSON.parse(Cookies.get('utm')) : {
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
        utmContent: '',
        utmTerm: ''
    };

    async function handleUpdateUtm(data) {
        const requestId = uuidv4();
        try {
            dispatch(addLoadingGlobal(requestId));
            const response = await updateUtm(data);
            if (response) {
                dispatch(fetchCart(order.id));
            }
        } catch (error) {
            console.log(error.response.data.message);
        } finally {
            Cookies.set('utm', JSON.stringify({
                utmSource: '',
                utmMedium: '',
                utmCampaign: '',
                utmContent: '',
                utmTerm: ''
            }));
            dispatch(removeLoadingGlobal(requestId));
        }
    }

    async function updateUtm(data) {
        const saleOrderApi = new ManagerApi('/sale/order');
        const response = await saleOrderApi.put(order.id, data);
        if (response.data && !response.data.success) {
            return false;
        }
        return response;
    }

    useEffect(() => {
        const utmSource = params.get('utm_source');
        const utmMedium = params.get('utm_medium');
        const utmCampaign = params.get('utm_campaign');
        const utmContent = params.get('utm_content');
        const utmTerm = params.get('utm_term');
        let hasParams = false;

        if (utmSource) {
            hasParams = true;
        }

        if (utmMedium) {
            hasParams = true;
        }

        if (utmCampaign) {
            hasParams = true;
        }

        if (utmContent) {
            hasParams = true;
        }

        if (utmTerm) {
            hasParams = true;
        }

        if (hasParams) {
            utm.utmSource = utmSource ?? '';
            utm.utmMedium = utmMedium ?? '';
            utm.utmCampaign = utmCampaign ?? '';
            utm.utmContent = utmContent ?? '';
            utm.utmTerm = utmTerm ?? '';
        }

        Cookies.set('utm', JSON.stringify(utm));
    }, [location]);

    useEffect(() => {
        const isEmpty = Object.values(utm).every(property => property === null || property === '');
        if (order.id && isEmpty === false) {
            let data = new FormData();

            data.append('utm[utmSource]', utm.utmSource ?? '');
            data.append('utm[utmMedium]', utm.utmMedium ?? '');
            data.append('utm[utmCampaign]', utm.utmCampaign ?? '');
            data.append('utm[utmContent]', utm.utmContent ?? '');
            data.append('utm[utmTerm]', utm.utmTerm ?? '');

            handleUpdateUtm(data);
        }
    }, [order]);

    return null;
}

export default Utm;