import React, { useEffect, useState } from 'react';
import { Box, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import closeModalIcon from 'src/theme/scss/icons/close-modal.svg';
import { ContentView } from '../Content';
import Cookies from 'js-cookie';

const useStyles = makeStyles({
    closeButton: {
        cursor: 'pointer',
        position: 'absolute',
        top: '4px',
        right: '4px',
        transform: 'translate(50%, -50%)',
        filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1))',
        zIndex: '2',
        '& + div': {
            borderRadius: '8px'
        }
    }
});

export default function ModalEntry({ id, json }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        Cookies.set(`modal-entry-${id}`, 'true');
        setOpen(false);
    };

    useEffect(() => {
        const modalEntry = Cookies.get(`modal-entry-${id}`);
        if (!modalEntry) {
            setOpen(true);
        }
    }, []);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            PaperProps={{ style: { maxWidth: '700px', width: '100%', overflow: 'visible' } }}
        >
            <Box className={classes.closeButton} onClick={handleClose}>
                <img src={closeModalIcon} alt="" />
            </Box>
            {json && <ContentView json={json} />}
        </Dialog>
    );
}
