import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Breadcrumbs,
    Container,
    Grid,
    Link,
    Typography,
    Box
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    breadcrumb: {
        color: '#333333',
        backgroundColor: '#ffffff',
        padding: '5px 0 9px'
    },
    breadcrumbItem: {
        fontSize: '10px !important',
        fontWeight: 800,
        textTransform: 'uppercase',
        color: '#777777',
    },
    breadcrumbSeparator: {
        fontSize: 16,
        marginTop: 5
    }
}));

function Breadcrumb({ className, textLink, textTypography, textBreadcrumb, linkToBreadcrumb, ...rest }) {
    const classes = useStyles();

    return (
        <Box className={classes.breadcrumb + ' hide-on-print'}>
            <Container maxWidth={false}>
                <Grid item>
                    <Breadcrumbs
                        separator={<NavigateNextIcon className={classes.breadcrumbSeparator} />}
                        aria-label="breadcrumb"
                    >
                        <Link
                            variant="body1"
                            to="/"
                            component={RouterLink}
                            className={classes.breadcrumbItem}
                            style={{ color: '#333333' }}
                        >
                            {textLink}
                        </Link>
                        {linkToBreadcrumb !== undefined && (
                            <Link
                                variant="body1"
                                to={linkToBreadcrumb}
                                component={RouterLink}
                                className={classes.breadcrumbItem}
                            >
                                {textBreadcrumb}
                            </Link>
                        )}
                    </Breadcrumbs>
                    {/* 
                    <Grid item>
                        <Typography variant="h3">
                            { textTypography }
                        </Typography>
                    </Grid> 
                    */}
                </Grid>
            </Container>
        </Box>
    );
}

Breadcrumb.propTypes = {
    className: PropTypes.string
};

export default Breadcrumb;
