import React from 'react';
import { useLocation } from 'react-router-dom';
import { APP_VERSION } from 'src/config';
import {
    makeStyles,
    Typography,
    Box,
    Link
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    copyright: {
        backgroundColor: "#2e2e2e",
        [theme.breakpoints.down('sm')]: {
            paddingTop: '8px',
            paddingBottom: props => props.isCheckoutPage ? '8px' : '80px',
        },
    },
    copyrightLink: {
        color: '#FFFFFF',
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: 8,
        '&:hover': {
            textDecoration: 'none'
        }
    }
}));

export function EmutuaFooter() {
    const location = useLocation();
    const classes = useStyles({ isCheckoutPage: location.pathname === '/onestepcheckout' });

    return (
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.copyright + ' hide-on-print'}>
            <Link href="https://emutuadigital.com" target="_blank" rel="nofollow" className={classes.copyrightLink}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box pt={1} pb={1}>
                        <Typography component={Box} className={classes.copyrightLink}>
                            Desenvolvido por
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <img
                            alt="eMutua - Criando negócios digitais"
                            title={"eStart - " + APP_VERSION}
                            src='/static/logo-emutua-white.png'
                            height="10"
                        />
                    </Box>
                </Box>
            </Link>
        </Box>
    );
}