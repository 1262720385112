import React from 'react';

import {
    Box,
    Button,
    Typography,
    makeStyles,
    Hidden
} from '@material-ui/core';

import { formatPrice, getProductValues } from 'src/utils/functions';

import cart from 'src/theme/scss/icons/blue-cart.svg';

import ameImage from 'src/theme/scss/images/ame-logo.png';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    priceInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '16px',
        marginBottom: '16px',
    },
    priceInfoContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    priceValue: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            marginBottom: '8px'
        }
    },
    priceNow: {
        color: '#0085f8', //'#DB1842',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        '& p': {
            color: 'inherit',
            fontSize: '12px',
            whiteSpace: 'nowrap'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    pricePix: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        '& p': {
            color: '#000', //'#DB1842',
            fontSize: '12px',
            whiteSpace: 'nowrap'
        },
    },
    infoDiscount: {
        fontSize: 9,
        fontWeight: 800,
        textTransform: 'uppercase',
        color: '#000',
        backgroundColor: 'rgba(57, 217, 80, 1)',
        borderRadius: 4,
        width: 'max-content',
        padding: '5px 10px',
        marginBottom: 3,
        [theme.breakpoints.down('xs')]: {
            margin: '0 auto 5px'
        },
    },
    infoAme: {
        lineHeight: '1',
        fontSize: '12px',
        fontWeight: '400',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        gap: '4px',
        marginTop: '8px',
        marginBottom: '8px',
        '& img': {
            display: 'inline-block',
            width: '48px',
            height: 'auto',
        },
        '& span:nth-of-type(1)': {
            color: '#5f5f5f'
        },
        '& span:nth-of-type(2)': {
            fontSize: '15px',
            color: '#f6235e'
        },
        '& span:nth-of-type(3)': {
            color: '#1b0487',
            whiteSpace: 'nowrap'
        }
    },
    fromPrice: {
        color: '#777777',
        textDecoration: 'line-through'
    },
    installmentBlock: {
        marginTop: '8px',
        color: '#777777',
        fontSize: '12px',
        '& span': {
            color: '#0085f8',
            fontWeight: 'bold',
            fontSize: '14px',
        }
    },
}));

export function ProductPrice({ isReview, product, classes }) {
    const discountPercentage = 10;
    const { formattedDiscountPrice } = getProductValues(product, discountPercentage);

    return (
        <Button
            variant="text"
            href={isReview ? false : `/${product.url}`}
            className={classes.priceFrom}
        >
            <Hidden smDown>
                <img src={cart} className={classes.iconCart} />
            </Hidden>
            a partir de {formattedDiscountPrice}
        </Button>
    );
}

export function FeaturedPrice({ product }) {
    const classes = useStyles();

    const store = useSelector((state) => state.interface.store);

    const discountPercentage = 10;

    const { price, formattedDiscountPrice, discountPrice, installmentValue, installmentValueWithInterest } = getProductValues(product, discountPercentage);

    const getAmePrice = () => {
        if (store) {
            const ame = Object.values(store.payment).find((paymentMethod) => paymentMethod.id === 'ame')

            if (!ame)
                return;

            let cashbackPercentual = ame.cashback;
            const labelCashback = `(${cashbackPercentual}% de volta)`

            return (
                <Box className={classes.infoAme}>
                    <img src={ameImage} alt="AME" className={classes.ameImage} />
                    <span>receba</span>
                    <span>{formatPrice(discountPrice * (cashbackPercentual / 100))}</span>
                    <span>{labelCashback}</span>
                </Box>
            )
        }
    }

    if (!product.saleable) {
        return <Box p={1} />;
    }

    return (
        <Box className={classes.priceInfo}>
            <Box className={classes.priceInfoContent}>
                <Typography variant="caption" className={classes.fromPrice}>De: {formatPrice(price)}</Typography>
                {/* <Typography variant="caption" className={classes.priceText}>No PIX por</Typography> */}
                <Box className={classes.priceValue}>
                    <Box className={classes.priceNow}>
                        <Typography variant="h3">Por:</Typography>
                        <Typography variant="h3">{formattedDiscountPrice}</Typography>
                    </Box>
                    <Box className={classes.pricePix}>
                        <Typography variant="body1">no PIX</Typography>
                        <Box className={classes.infoDiscount}>
                            {discountPercentage}% de  desconto
                        </Box>
                    </Box>
                </Box>
                <Typography variant="caption" className={classes.installmentBlock}>
                    em até 12x de {installmentValue} sem juros
                </Typography>
                <Typography variant="caption" className={classes.installmentBlock}>
                    ou <span>21x de {installmentValueWithInterest}</span>
                </Typography>
            </Box>
        </Box>
    )
}
