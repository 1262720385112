import React from "react";

import {
  makeStyles,
  Box,
  Container,
  useTheme,
  useMediaQuery
} from "@material-ui/core";

import { v4 as uuidv4 } from 'uuid';

import { Helmet } from "react-helmet";

import { getBoxMarginProps, getBreakpointValue, getColor } from "./functions";

const useStyles = makeStyles(() => ({
  sectionCompanyVideo: {
    position: "relative",
    overflow: 'hidden'
  }
}));


export const VideoBox = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

  const componentId = ("video" + uuidv4()).replaceAll('-', '');

  const videoUpload = getBreakpointValue(props, breakpoint, 'upload');

  return (
    <Box
      {...getBoxMarginProps(props, breakpoint)}
      height={`${getBreakpointValue(props, breakpoint, 'height')}px`}
      className={classes.sectionCompanyVideo}
    >
      {videoUpload ? (
        <>
          <video
            id={"player-" + componentId}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            autoPlay
            playsInline
            play
            muted
            loop
            src={videoUpload.url}
          >
          </video>
          <Helmet key={componentId}>
            <script defer>
              {`
                document.getElementById('player-${componentId}').play();
              `}
            </script>
          </Helmet>
        </>
      ) : props.videoId ? (
        <>
          <Helmet key={componentId}>
            <script defer>
              {`
                let plyr${componentId} = new Plyr('#plyr-${componentId}', {
                    autoplay: false,
                    muted: true,
                    loop: {
                    active: false
                    },
                    controls: [],
                    clickToPlay: false
                });

                plyr${componentId}.on('ready', (event) => {
                    const instance = event.detail.plyr;
                    instance.play();
                })
                plyr${componentId}.on('ended', (event) => {
                    const instance = event.detail.plyr;
                    instance.play();
                })
              `}
            </script>
          </Helmet>

          <div id={"player-" + componentId}>
            <div id={"plyr-" + componentId} data-plyr-provider="youtube" data-plyr-embed-id={props.videoId}></div>
          </div>
        </>
      ) : <></>}

      <Box
        style={{
          position: "absolute",
          backgroundColor: getColor(props.overlay),
          left: 0,
          bottom: 0,
          top: 0,
          right: 0,
          display: 'flex'
        }}
        alignItems={getBreakpointValue(props, breakpoint, 'alignItems')}
      >
        <Container>
          {props.children}
        </Container>
      </Box>
    </Box>
  )
};