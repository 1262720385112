import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import ManagerApi from 'src/services/managerApi';
import { addMessage, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';

const useStyles = makeStyles(theme => ({
    mainButton: {
        width: '100%',
        height: '44px',
        backgroundColor: '#DB1842',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontFamily: 'Manrope',
    },
    controlNotifyMe: {
        marginBottom: '15px'
    },
    buttonNotifyMe: {
        display: 'block',
        backgroundColor: '#DB1842',
        fontSize: '14px',
        margin: '0 auto 15px',
        textTransform: 'uppercase',
        fontFamily: 'Manrope',
    },
    labelNotifyMe: {
        marginBottom: '5px'
    }
}));

function NotifyMe(product) {
    const dispatch = useDispatch();
    const [translate, i18n] = useTranslation();
    const [errorsBackend, setErrorsBackend] = React.useState([]);

    const classes = useStyles();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const notifyMeValidationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(translate('errorRequiredField')),
        email: Yup.string().email(translate('errorEmailField')).max(255).required(translate('errorRequiredField'))
    });

    const openDialog = () => {
        setDialogIsOpen(true);
    };

    const closeDialog = () => {
        setDialogIsOpen(false);
    };

    async function handleNotifyMe(values, resetForm) {

        try {
            const requestId = uuidv4();

            dispatch(addLoadingGlobal(requestId));

            values.product = product.product;

            new ManagerApi('/catalog/productAlertStock').post(values)
                .then((response) => {
                    if (response.data.success) {
                        dispatch(addMessage(translate('alertProductStockNotifyMeSuccessfully'), 'success'));
                        resetForm();
                    } else if (!response.data.success) {
                        setErrorsBackend(response.data.errors)
                    }
                }).catch(error => {
                    dispatch(addMessage(error.response.data.message, 'error'));
                }).finally(() => {
                    dispatch(removeLoadingGlobal(requestId));
                });

        } catch { }

    }

    return (
        <Box onClick={e => e.preventDefault()}>
            <Button color="primary" variant="contained" fullWidth onClick={openDialog} className={classes.mainButton}>
                Avise-me
            </Button>
            <Dialog open={dialogIsOpen} onClose={closeDialog} fullWidth maxWidth="md">
                <DialogTitle>
                    <Typography variant='h4'>
                        Avise-me quando chegar
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{ name: '', email: '', newsletter: false }}
                        validationSchema={notifyMeValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={async (values, { resetForm }) => handleNotifyMe(values, resetForm)}
                    >
                        {({
                            values,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            touched,
                            errors,
                        }) => {
                            return (
                                <form>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <FormControl className={classes.controlNotifyMe}>
                                                    <FormLabel className={classes.labelNotifyMe}>{translate('titleLabelNameCustomer')}:</FormLabel>
                                                    <TextField
                                                        error={Boolean(touched.name && errors.name)}
                                                        helperText={touched.name && errors.name}
                                                        name="name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <FormControl className={classes.controlNotifyMe}>
                                                    <FormLabel className={classes.labelNotifyMe}>{translate('titleLabelEmailCustomer')}:</FormLabel>
                                                    <TextField
                                                        error={Boolean(touched.email && errors.email)}
                                                        helperText={touched.email && errors.email}
                                                        name="email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                    />
                                                    {errorsBackend.email &&
                                                        <FormHelperText error>
                                                            {errorsBackend.email}
                                                        </FormHelperText>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            className={classes.controlNotifyMe}
                                            control={<Checkbox checked={values.newsletter} onChange={handleChange} name="newsletter" />}
                                            label="Também desejo receber e-mails de promoções e lançamentos"
                                        />
                                    </Box>
                                    <Button
                                        color="primary"
                                        className={classes.buttonNotifyMe}
                                        variant="contained"
                                        onClick={handleSubmit}>
                                        Cadastrar aviso
                                    </Button>
                                </form>
                            )
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default NotifyMe;
