import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Container,
    Grid,
    Box
} from '@material-ui/core';

import PromoBanner from 'src/components/PromoBanner';
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import MainLayout from 'src/layouts/MainLayout'


const useStyles = makeStyles((theme) => ({
    root: {

    }
}));

function GuestOrderViewLayout({ children }) {
    const classes = useStyles();

    return (
        <MainLayout>
            <Box className={classes.root}>
                <PromoBanner />
                <Breadcrumb
                    textLink="Página Inicial"
                    textTypography="Meus pedidos"
                    textBreadcrumb="Meus pedidos"
                    linkToBreadcrumb="Meus pedidos"
                />
                <Container maxWidth={false}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid style={{ marginTop: '50px' }} item md={8} xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </MainLayout>
    );
}

GuestOrderViewLayout.propTypes = {
    children: PropTypes.any
};

export default GuestOrderViewLayout;
