import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
} from '@material-ui/core';
import promotionalBanner from 'src/theme/scss/images/promotionalBanner.png';

function PromoBanner() {
    return (<></>);
    return (
        <Box className="promotionalBanner" style={{ backgroundImage: 'url("' + promotionalBanner + '")' }}>
            <Typography variant="subtitle2" className="promotionalSub2">BLACK FRIDAY COM</Typography>
            <Typography variant="h3" className="promotionalH3">50% DE DESCONTO</Typography>
            <Typography variant="body1" className="promotionalBody1">USE O CUPOM</Typography>
            <Typography variant="h5" className="promotionalH5">50BLACK</Typography>
        </Box>
    )
}

export default PromoBanner;