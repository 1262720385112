import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Plus as PlusIcon,
    ChevronDown as DownIcon,
} from 'react-feather';
import {
    AppBar,
    Box,
    Container,
    makeStyles,
    IconButton,
    Typography,
    Avatar,
    useTheme,
    useMediaQuery,
    Grid,
    Button,
    Menu,
} from '@material-ui/core';
import { logout, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import DrawerSearch from 'src/layouts/MainLayout/Topbar/Menu/DrawerSearch';
import logo from 'src/theme/scss/icons/logoNewBlack.svg';
import lupa from 'src/theme/scss/icons/lupa.svg';
import user from 'src/theme/scss/icons/user.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        padding: '30px 0',
        background: '#ffffff !important'
    },
    centerArea: {
        textAlign: 'center'
    },
    linkItem: {
        fontFamily: 'Manrope',
        fontSize: 14,
        color: '#333333',
        textDecoration: 'none',
        textTransform: 'none',
        padding: '6px 25px',
        position: 'relative',
        '&:first-child': {
            '&:after': {
                content: '""',
                display: 'block',
                width: 4,
                height: 4,
                borderRadius: '50%',
                backgroundColor: '#ED1C24',
                position: 'absolute',
                top: 15,
                right: -2
            }
        }
    },
    linkItemUser: {
        fontSize: 12,
        color: '#ED1C24',
        textDecoration: 'none',
        display: 'block',
        padding: '6px 15px',
        position: 'relative',
        '&:after': {
            content: '""',
            display: 'block',
            width: 5,
            height: 5,
            borderRadius: '50%',
            backgroundColor: '#ED1C24',
            position: 'absolute',
            top: 12,
            left: 0
        },
        '&:hover': {
            color: '#ED1C24',
        }
    },
    linkItemUserBlack: {
        color: '#333333',
        '&:after': {
            backgroundColor: '#333333',
        },
    },
    userMenu: {
        filter: 'drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.06))',
        boxShadow: 'none'
    },
    topUser: {
        borderBottom: '1px solid #F4F4F4',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 60,
        marginBottom: 15
    },
    logoContainer: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1
        },
    },
    logoImage: {
        marginRight: theme.spacing(2),
        height: 44,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 100,
        },
    },
    divider: {
        width: 1,
        height: 64,
    },
    searchForm: {
        flex: 1,
        marginRight: theme.spacing(2)
    },
    searchInput: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        padding: '8px',
        borderRadius: '10px',
        width: '100%',
    },
    iconButton: {
        color: '#333333',
        padding: 10,
        background: 'transparent',
        borderRadius: '0%',
        height: 64,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 50
        },
    },
    poper: {
        zIndex: '99999999',
        width: '200px'
    },
    dividerAccount: {
        width: '100%',
    },
    styleIconButton: {
        borderRadius: '0',
        width: '100%'
    },
    avatar: {
        width: 30,
        height: 30,
        marginRight: 5
    },
    avatarMenu: {
        width: 48,
        height: 48,
        position: 'absolute',
        top: 10,
        left: 0
    },
    mobileContainer: {
        paddingRight: 0
    },
    menu: {
        width: '100%',
        maxHeight: '400px',
        overflow: 'auto',
        zIndex: '99999',
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0px 1px 5px 1px #DDD'
    },
    typographyMenu: {
        textDecoration: 'none',
        color: '#263238',
    },
    divMenu: {
        width: '100%'
    },
    topicButton: {
        fontFamily: 'Manrope',
        fontWeight: 800,
        fontSize: 11,
        textDecoration: 'none',
        color: '#ffffff',
        backgroundColor: '#0084F4',
        padding: '10px 20px',
        borderRadius: 6,
        margin: '0 40px',
        '&:hover': {
            backgroundColor: '#0084F4',
            color: '#ffffff'
        },
        '&:after': {
            content: '""',
            display: 'block',
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: '#ED1C24',
            position: 'absolute',
            top: 17,
            right: -40
        }
    },
    iconPlus: {
        marginRight: 10,
        verticalAlign: 'middle'
    },
    imgUser: {
        marginRight: 7
    }
}));

function ForumMenu({ className, ...rest }) {
    const store = useSelector((state) => state.interface.store);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [openDrawer, setOpenDrawer] = useState(null);


    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [searchProducts, setSearchProducts] = useState([]);
    const [translate, i18n] = useTranslation();

    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const userAccount = useSelector((state) => state.account.user);


    const showMenuAccount = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const logoutUser = () => {
        dispatch(logout());
        setOpen(false);
        history.push('/forum');
    }

    const handleCloseMenuAccount = () => {
        setOpen(false);
    }

    const handleDrawerOpen = (drawerId) => {
        if (openDrawer === drawerId) {
            drawerId = null
        }
        setOpenDrawer(drawerId)
    };

    const handleDrawerClose = () => {
        setOpenDrawer(null);
    };


    const authenticatedMenu = () => {
        return <>
            <Box className={classes.centerArea}>
                <Typography variant="h4">Seja bem-vindo(a), {userAccount.name}</Typography>
                <Box className={classes.links}>
                    <RouterLink to="/forum/meu-perfil" className={classes.linkItem}>
                        Editar meus dados
                    </RouterLink>
                    <RouterLink to="#" onClick={logoutUser} className={classes.linkItem}>
                        Fazer logoff
                    </RouterLink>
                </Box>
            </Box>
        </>
    }

    const noAuthenticatedMenu = () => {
        return <>
            <Box className={classes.centerArea}>
                <Typography variant="h4">Seja bem-vindo!</Typography>
                <Box className={classes.links}>
                    <RouterLink to="/onestepcheckout/login" className={classes.linkItem}>
                        Fazer login
                    </RouterLink>
                    <RouterLink to="/onestepcheckout/customer/register" className={classes.linkItem}>
                        Faça seu cadastro
                    </RouterLink>
                </Box>
            </Box>
        </>
    }

    const authUserMenu = () => {

        return <>
            <Box className={classes.topUser}>
                <Avatar alt="User" className={classes.avatarMenu} />
                <Typography variant="h6" className={classes.userMenuTitle}>{userAccount.name}</Typography>
                <RouterLink className={classes.linkItemUser + ' ' + classes.linkItemUserBlack} to="/forum/meu-perfil">Editar meus dados</RouterLink>
                <RouterLink className={classes.linkItemUser + ' ' + classes.linkItemUserBlack} onClick={logoutUser} to="/">Fazer logoff</RouterLink>
            </Box>
            <RouterLink className={classes.linkItemUser} to="/forum/meus-topicos" onClick={handleCloseDrop}>Seus tópicos criados</RouterLink>
            <RouterLink className={classes.linkItemUser} to="/forum/respostas-criadas" onClick={handleCloseDrop}>Respostas criadas</RouterLink>
            <RouterLink className={classes.linkItemUser} to="/forum/topicos-favoritados" onClick={handleCloseDrop}>Tópicos favoritados</RouterLink>
        </>

    }

    const noAuthUserMenu = () => {

        return <>
            <Box className={classes.topUser}>
                <Typography variant="h6" className={classes.userMenuTitle}>Você não está logado</Typography>

            </Box>
            <RouterLink className={classes.linkItemUser} to="/onestepcheckout/customer/register">Faça seu registro</RouterLink>
            <RouterLink className={classes.linkItemUser} to="/onestepcheckout/login">Entrar agora</RouterLink>

        </>

    }

    const openDrop = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDrop = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar
                className={clsx(classes.root, className)}
                color="default"
                {...rest}
            >
                <Box className="main-header">
                    <Container maxWidth={false}>
                        <Grid
                            className={mobileSize ? classes.mobileContainer : null}
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Box className={classes.logoContainer}>
                                <RouterLink to="/">
                                    <img
                                        alt={store.name}
                                        src={logo}
                                        className={classes.logoImage}
                                    />
                                </RouterLink>
                            </Box>
                            <Box className={classes.info}>
                                {/* Não autenticado */}


                                {/* Autenticado */}
                                {userAccount != null ?
                                    authenticatedMenu :
                                    noAuthenticatedMenu}

                            </Box>
                            <Box className={classes.actions}>
                                <RouterLink to="/forum/novo-topico" className={classes.topicButton}>
                                    <PlusIcon size='16px' className={classes.iconPlus} />
                                    Criar um novo tópico
                                </RouterLink>
                                <IconButton onClick={() => handleDrawerOpen('search')} className={classes.iconButton + ' ' + classes.iconLogin}>
                                    <img src={lupa} />
                                </IconButton>
                                <IconButton
                                    id="user-button"
                                    aria-controls="user-menu"
                                    aria-haspopup="true"
                                    aria-expanded={openDrop ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClick}
                                    className={classes.iconButton}
                                >

                                    {userAccount !== null ?
                                        <Avatar alt="User" className={classes.avatar}>
                                            {userAccount.name.charAt(0)}
                                        </Avatar>
                                        : <img src={user} className={classes.imgUser} />}
                                    {/* */}
                                    {/* <Avatar alt="User" className={classes.avatar} /> */}
                                    <DownIcon size='20px' />
                                </IconButton>
                                <Menu
                                    id="user-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'user-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={openDrop}
                                    onClose={handleCloseDrop}
                                    className={classes.userMenu + ' userMenu'}
                                >
                                    <Box>

                                        {userAccount != null ?
                                            authUserMenu :
                                            noAuthUserMenu}

                                    </Box>
                                </Menu>
                            </Box>
                        </Grid>
                    </Container>
                </Box>
            </AppBar>

            <DrawerSearch handleDrawerClose={handleDrawerClose} openDrawer={openDrawer === 'search'} />
        </>
    );
}

ForumMenu.propTypes = {
    className: PropTypes.string
};

export default ForumMenu;
