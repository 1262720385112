import {
    Box,
    Button,
    Collapse,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import UserIcon from '@material-ui/icons/Person';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from 'src/actions';
import heartIcon from 'src/assets/heart.png';
import { LoginModal } from '../../Login/Modal';
import OrderStatus from './OrderStatus';

const drawerWidth = 460;

const useStyles = makeStyles((theme) => ({
    drawer: {
        backgroundColor: '#222',
        width: drawerWidth,
        maxWidth: "95%"
    },
    button: {
        justifyContent: 'left',
    },
    drawerContainer: {
    },
    drawerHeader: {
        color: '#fff',
        padding: theme.spacing(1)
    },
    drawerContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    link: {
        color: '#fff'
    },
    nestedLink: {
        paddingLeft: theme.spacing(5)
    }
}));

export default function DrawerCategories({ handleDrawerClose, openDrawer, order }) {
    const [orderBlock, setOrderBlock] = useState(false);
    const store = useSelector((state) => state.interface.store);
    const classes = useStyles();
    const [translate, i18n] = useTranslation();
    const dispatch = useDispatch();
    let history = useHistory();
    const categories = useSelector((state) => state.interface.globalData.categoriesMenu);

    const [menu, setMenu] = useState(null);

    const [openList, setOpenList] = useState(null);
    const [openSublist, setOpenSublist] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openDrop = Boolean(anchorEl);

    const [showLoginBlock, setShowLoginBlock] = useState(false);

    const handleClickDrop = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDrop = () => {
        setAnchorEl(null);
    };

    const handleClick = (categoryId) => {
        if (openList === categoryId) {
            categoryId = null;
        }
        setOpenList(categoryId);
    };

    const handleSubClick = (categoryId) => {
        if (openSublist === categoryId) {
            categoryId = null;
        }
        setOpenSublist(categoryId);
    };

    const generateMenu = () => {
        let items = [];

        for (let key in menu) {
            let item = null
            let menuItem = menu[key];

            if (menuItem.url === 'pecas-avulsas') continue;

            let hasChildren = (menuItem.children.length > 0);

            item = (
                <Grid item xs={12} key={key} className="grid-item">
                    {hasChildren ? (
                        <>
                            <ListItem
                                button
                                className="list-item"
                                onClick={() => handleClick(menuItem.id)}
                            // component={RouterLink}
                            // to={`/${menuItem.url}`}
                            // onClick={handleDrawerClose}
                            >
                                <ListItemText
                                    primary={menuItem.name}
                                    className='level-0'
                                />
                            </ListItem>
                            <Button className="list-item-button" onClick={() => handleClick(menuItem.id)}>
                                {openList === menuItem.id ? <ExpandLess style={{ color: '#ff367d' }} /> : <ExpandMore style={{ color: '#ff367d' }} />}
                            </Button>
                            <Collapse in={openList === menuItem.id} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <>
                                        {
                                            menuItem.children.length > 0 && menuItem.children.map((child) => {

                                                let hasChildChildren = (child.children.length > 0);

                                                return (
                                                    <Box key={child.id} className="list-item-children">
                                                        {hasChildChildren ? (
                                                            <>
                                                                <Button
                                                                    className="list-item-button"
                                                                    onClick={() => handleSubClick(child.id)}
                                                                >
                                                                    {openSublist === child.id ? <ExpandLess style={{ color: '#ff367d' }} /> : <ExpandMore style={{ color: '#ff367d' }} />}
                                                                </Button>
                                                                <ListItem
                                                                    button
                                                                    className={classes.nestedLink}
                                                                    onClick={() => handleSubClick(child.id)}
                                                                >
                                                                    <ListItemText primary={child.name} className='level-1' />
                                                                </ListItem>
                                                                <Collapse in={openSublist === child.id} timeout="auto" unmountOnExit>
                                                                    <List component="div" disablePadding>
                                                                        <>
                                                                            {
                                                                                child.children.length > 0 && child.children.map((item) => {
                                                                                    return (
                                                                                        <ListItem
                                                                                            key={item.id}
                                                                                            button
                                                                                            component={RouterLink}
                                                                                            to={`/${item.url}`}
                                                                                            className="level-2-link"
                                                                                            onClick={handleDrawerClose}
                                                                                        >
                                                                                            <ListItemText
                                                                                                primary={item.name}
                                                                                                className='level-2'
                                                                                            />
                                                                                        </ListItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <ListItem
                                                                                button
                                                                                component={RouterLink}
                                                                                to={`${child.url}`}
                                                                                className="level-2-link"
                                                                                onClick={handleDrawerClose}
                                                                            >
                                                                                <ListItemText
                                                                                    primary={`Todos produtos em ${child.name}`}
                                                                                    className='level-2'
                                                                                />
                                                                            </ListItem>
                                                                        </>
                                                                    </List>
                                                                </Collapse>
                                                            </>
                                                        ) : (
                                                            <ListItem
                                                                button
                                                                component={RouterLink}
                                                                to={`/${child.url}`}
                                                                className={classes.nestedLink}
                                                                onClick={handleDrawerClose}
                                                            >
                                                                <ListItemText primary={child.name} className='level-1' />
                                                            </ListItem>
                                                        )}
                                                    </Box>
                                                )
                                            })
                                        }
                                        <ListItem
                                            button
                                            component={RouterLink}
                                            to={`${menuItem.url}`}
                                            className="level-2-link"
                                            onClick={handleDrawerClose}
                                        >
                                            <ListItemText
                                                primary={`Todos produtos em ${menuItem.name}`}
                                                className='level-1'
                                                style={{
                                                    paddingLeft: 68
                                                }}
                                            />
                                        </ListItem>
                                    </>
                                </List>
                            </Collapse>
                        </>
                    ) : (

                        <>
                            {menuItem.url.indexOf('http') == 0 ? (
                                <ListItem
                                    button
                                    component={Link}
                                    href={menuItem.url}
                                    target="_blank"
                                    onClick={handleDrawerClose}
                                    className="list-item"
                                >
                                    <ListItemText primary={menuItem.name} className='level-0' />
                                </ListItem>
                            ) : (
                                <ListItem
                                    button
                                    component={RouterLink}
                                    to={menuItem.url}
                                    onClick={handleDrawerClose}
                                    className="list-item"
                                >
                                    <ListItemText primary={menuItem.name} className='level-0' />
                                </ListItem>
                            )}
                        </>
                    )}
                </Grid>
            );

            items.push(item);

        }

        return items;
    }

    useEffect(() => {
        let menu = [];

        categories.map((category, index) => {
            if (category.url !== 'notebooks') return;

            if (!menu[category.id] && !category.parent) {
                menu[category.id] = {
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    slug: category.slug,
                    url: '/' + category.url,
                    children: []
                };
            }
        });
        categories.map((category, index) => {
            if (category.parent && menu[category.parent.id]) {
                menu[category.parent.id]['children'].push({
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    slug: category.slug,
                    url: '/' + category.url,
                    children: category.children
                });
            }
        });
        for (let key in menu) {
            const category = menu[key];
            const categoryItems = category.children;
            const sortByName = (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            if (categoryItems) {
                categoryItems.sort(sortByName);
                categoryItems.forEach(categoryItem => {
                    const subcategoryItem = categoryItem.children;
                    if (subcategoryItem) {
                        subcategoryItem.sort(sortByName)
                    }
                })
            }
        }

        store.menuItem.forEach(item => {
            menu[item.id] = {
                id: item.id,
                name: item.title,
                description: '',
                slug: item.id,
                url: item.url,
                children: []
            };

            item.children.forEach(itemChild => {
                menu[item.id].children.push({
                    id: itemChild.id,
                    name: itemChild.title,
                    description: '',
                    slug: itemChild.id,
                    url: itemChild.url,
                    children: []
                });
            })
        });

        setMenu(menu);
    }, [categories]);

    const getFirstName = () => {
        return userAccount.name.split(" ")[0]
    }

    const userAccount = useSelector((state) => state.account.user);

    const logoutUser = async () => {
        dispatch(logout());
        history.push('/');
    }

    function handleOpenLivechat() {
        window.open('https://chat.movidesk.com/ChatWidgetNewNew/Landing/185B5AAE5596487A9FD4838043E84163', 'Chat', 'location=yes,height=570,width=520,top=50,location=no,status=yes,scrollbars=yes,resizable=yes')
    }

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleDrawerClose}
            classes={{
                paper: classes.drawer
            }}
        >
            <Box className={classes.drawerContainer}>
                <Box
                    className="drawerHeader"
                    width="100%"
                    display="flex"
                    alignItems="right"
                >
                    <Box mr={1} className="box-close">
                        <IconButton onClick={handleDrawerClose}>
                            {openDrawer ? <Close style={{ color: 'white' }} /> : <Close style={{ color: 'white' }} />}
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Box className="menu-categories mobile">
                    <Grid container>
                        {generateMenu()}

                        {/* {userAccount != null ?
                            <>
                                <Grid item xs={12} className="grid-item links customer-links">
                                    <ListItem
                                        button
                                        component={RouterLink}
                                        to='/customer/account'
                                        onClick={handleDrawerClose}
                                        className="list-item"
                                    >
                                        <ListItemText primary={'Olá, ' + getFirstName()} className='level-0' />
                                    </ListItem>
                                    <ListItem
                                        component={RouterLink}
                                        to='/customer/account'
                                        className="list-item"
                                        onClick={handleDrawerClose}
                                    >
                                        <ListItemText primary="Minha conta" className='level-0' />
                                    </ListItem>
                                    <ListItem
                                        component={RouterLink}
                                        to='/customer/account/orders'
                                        className="list-item"
                                        onClick={handleDrawerClose}
                                    >
                                        <ListItemText primary="Meus pedidos" className='level-0' />
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={RouterLink}
                                        to='/customer/account/addresses'
                                        className="list-item"
                                        onClick={handleDrawerClose}
                                    >
                                        <ListItemText primary="Meus endereços" className='level-0' />
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={RouterLink}
                                        to='/customer/account/update-password'
                                        className="list-item"
                                        onClick={handleDrawerClose}
                                    >
                                        <ListItemText primary="Alterar minha senha" className='level-0' />
                                    </ListItem>
                                    <ListItem
                                        component={RouterLink}
                                        onClick={logoutUser}
                                        to='#'
                                        className="list-item"
                                    >
                                        <ListItemText primary="Sair" className='level-0' />
                                    </ListItem>
                                </Grid>
                            </> :
                            <>
                                <Grid item xs={12} className="grid-item register customer-links">
                                    <Button
                                        component={RouterLink}
                                        to='/onestepcheckout/customer/register'
                                        className="register-button"
                                        startIcon={<InsertEmoticonIcon />}
                                    >
                                        Cadastre-se
                                    </Button>
                                    <Button
                                        onClick={() => setShowLoginBlock(!showLoginBlock)}
                                        className="register-button"
                                        startIcon={<UserIcon />}
                                    >
                                        Entrar
                                    </Button>
                                </Grid>

                            </>
                        } */}
                        {/* {
                            showLoginBlock && (
                                <Grid item xs={12}>
                                    <LoginModal close={() => setShowLoginBlock(false)} />
                                </Grid>
                            )
                        } */}
                        <Divider />
                        <Grid item xs={12} className="grid-item links">
                            <ListItem
                                button
                                onClick={handleOpenLivechat}
                                className="list-item"
                            >
                                <ListItemText primary="Peças Avulsas" className='level-0' />
                            </ListItem>
                            <ListItem
                                button
                                component={RouterLink}
                                to="/central-de-vendas"
                                onClick={handleDrawerClose}
                                className="list-item"
                            >
                                <ListItemText primary="Central de Vendas" className='level-0' />
                            </ListItem>
                            <ListItem
                                button
                                component={RouterLink}
                                to="/suporte-tecnico"
                                onClick={handleDrawerClose}
                                className="list-item"
                            >
                                <ListItemText primary="Suporte Técnico" className='level-0' />
                            </ListItem>
                            <ListItem
                                button
                                component={Link}
                                href='https://store.nvidia.com/pt-br/consumer/?page=1&limit=12&locale=pt-br&manufacturer=AVELL&manufacturer_filter=ACER~1,AOC~1,ASUS~18,AVELL~10,DELL~1,GALAX~8,GIGABYTE~24,LENOVO~2,MSI~13,PALIT~6,PNY~11,SAMSUNG~1,ZOTAC~9'
                                target="_blank"
                                onClick={handleDrawerClose}
                                className="list-item"
                            >
                                <ListItemText primary="NVIDIA Store" className='level-0' />
                            </ListItem>
                            {/* <ListItem
                                button
                                component={RouterLink}
                                to="/wishlist"
                                onClick={handleDrawerClose}
                                className="list-item wishlist"
                            >
                                <img src={heartIcon} alt="Lista de Desejos" className="icon" />
                                <ListItemText primary="Lista de Desejos" className='level-0' />
                            </ListItem> */}
                            {userAccount == null && (
                                <>
                                    <ListItem
                                        button
                                        id="status-button"
                                        variant="contained"
                                        disableElevation
                                        onClick={() => setOrderBlock(!orderBlock)}
                                        className="list-item"
                                    >
                                        <ListItemText primary="Status de Pedidos" className='level-0' />
                                    </ListItem>
                                    {orderBlock && (
                                        <Box className={classes.boxOrderStatus}>
                                            <OrderStatus />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Drawer>
    )
}