import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Grid,
    makeStyles,
    Box,
    Typography,
    Link,
    Container,
    Input,
    FormControl,
    FormLabel,
    Switch,
    FormHelperText,
    TextField,
    InputAdornment,
    IconButton,
    darken
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { login } from 'src/actions/accountActions';
import { addMessage, addLoadingGlobal, removeLoadingGlobal } from 'src/actions';
import ManagerApi from 'src/services/managerApi';
import ClassUserLogin from 'src/models/UserLoginModels';
import CheckoutHeader from 'src/layouts/CheckoutLayout/Topbar/CheckoutHeader';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { useMask } from 'src/hooks/useMask';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Icon } from '@material-ui/core';
import moment from 'moment';
import {
    AlertCircle as AlertCicleIcon
} from 'react-feather';
import { trackLogin } from 'src/utils/analytics';

let classUserLogin = new ClassUserLogin();

const defaultFormShape = classUserLogin.getObjects;

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5),
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#db1842'
        }
    },
    checkoutBlock: {
        paddingTop: 80,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 30,
        },
    },
    buttonResetPassword: {
        color: '#db1842',
        borderColor: '#db1842',
    }
}));

function ModalLogin() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const recoveryPasswordApi = new ManagerApi('/customer/recovery');
    const [recoverPassword, setRecoverPassword] = useState(false);
    const [loginByDocument, setLoginByDocument] = useState(false);
    const [translate, i18n] = useTranslation();

    const { dinamicMaskDocument } = useMask();

    const [showPassword, setShowPassword] = useState(false);
    const handleToglePasswordVisibility = () => setShowPassword(!showPassword);

    let history = useHistory();

    const { state } = useLocation()

    const validationSchemaLogin = Yup.object().shape({
        email: Yup.string().email(translate('errorEmailLogin')).max(255).required(translate('errorEmailCart')),
        password: Yup.string().max(255).required(translate('errorPasswordLogin')),
    });

    const validationSchemaLoginByDocument = Yup.object().shape({
        document: Yup.string().min(14).max(18).required(translate('errorDocumentLogin')),
        password: Yup.string().max(255).required(translate('errorPasswordLogin')),
    });

    const validationSchemaRecoverPassword = Yup.object().shape({
        recoverPassword: Yup.string().required(translate('errorRecoverPassword'))
    });

    const handleRedirect = () => {
        let redirectTo = state != undefined ? state.from.pathname : '/';

        history.push(redirectTo);
    }


    return (
        <>
            <CheckoutHeader
                textTypography="Acesse sua conta"
                textBreadcrumb="Acesse sua conta"
                linkToBreadcrumb="/onestepcheckout/login" />
            <Box className={classes.checkoutBlock}>
                <Formik
                    initialValues={defaultFormShape}
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={!recoverPassword ? (loginByDocument ? validationSchemaLoginByDocument : validationSchemaLogin) : validationSchemaRecoverPassword}
                    onSubmit={async (values, { resetForm }) => {
                        const requestId = uuidv4();

                        if (values.recoverPassword !== '' && values.recoverPassword !== undefined) {

                            dispatch(addLoadingGlobal(requestId));

                            try {

                                const response = await recoveryPasswordApi.post({ email: values.recoverPassword }).catch(error => {
                                    throw error;
                                });

                                if (response) {
                                    dispatch(addMessage('Visualize seu email para recuperação de senha.', 'success'));
                                    resetForm();
                                };

                            } catch (error) {
                                dispatch(addMessage(error.response.data.message, 'error'));
                            } finally {
                                dispatch(removeLoadingGlobal(requestId));
                            }


                        } else {
                            let response = null;

                            if (loginByDocument) {
                                response = await dispatch(login({
                                    document: values.document,
                                    password: values.password
                                }));
                            } else {
                                response = await dispatch(login({
                                    email: values.email,
                                    password: values.password
                                }));
                            }

                            if (response) {
                                trackLogin('Account');
                                dispatch(addMessage(translate('alertSucessLogin'), 'success'));
                                handleRedirect();
                            } else {
                                dispatch(addMessage(translate('alertErrorLogin'), 'error'));
                            }
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setValues,
                    }) => {

                        const handleRecoverPassword = () => {
                            recoverPassword ? setRecoverPassword(false) : setRecoverPassword(true);

                            if (recoverPassword) {
                                setValues({ email: '', password: '', document: '' });
                            } else {
                                setValues({ recoverPassword: '' })
                            }
                        };

                        const handleLoginOption = () => {
                            loginByDocument ? setLoginByDocument(false) : setLoginByDocument(true);
                        };


                        return (
                            <Box className="loginPage">
                                <Container className={classes.root}>

                                    {/* Todo: Remover bloco após 2022-07-01 */}
                                    {!recoverPassword && (moment().format('YYYY-MM-DD') < '2022-07-01') && (
                                        <Grid container justifyContent="center">
                                            <Grid item md={6} sm={8} xs={12}>
                                                <Box p={5} style={{ backgroundColor: "#eee", borderRadius: '5px', textAlign: 'center' }}>
                                                    <Box pb={2}>
                                                        <AlertCicleIcon color="#db1842" size='32px' />
                                                    </Box>
                                                    <Box pb={4}>
                                                        <p>Se você já possui uma conta no nosso antigo e-commerce, é necessário redefinir sua senha. Clique em "redefinir a senha", informe seu e-mail e siga o passo a passo que você receberá também no e-mail.</p>
                                                    </Box>
                                                    <Box>
                                                        <Button className={classes.buttonResetPassword} variant="outlined" type="button" onClick={handleRecoverPassword}>
                                                            Redefinir a senha
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid container justifyContent="center">
                                        <Grid item md={4} sm={8} xs={12}>
                                            <Form onSubmit={handleSubmit} >
                                                <Box className="titleBox">
                                                    <Typography variant="overline" className="bigTitle">
                                                        {!recoverPassword ? translate('Entrar') : translate('Senha')}
                                                    </Typography>
                                                    <Typography variant="h3" className="loginTitle">
                                                        {!recoverPassword ? translate('dialogTitleLogin') : translate('dialogTitleForgotPassword')}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {!recoverPassword ?
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Box className="loginOption">
                                                                    <Box className="switch" onClick={handleLoginOption}>
                                                                        <Switch />
                                                                    </Box>
                                                                    <Typography>Logar com meu CPF/CNPJ</Typography>
                                                                </Box>
                                                                {
                                                                    loginByDocument ? (
                                                                        <InputMask
                                                                            mask={dinamicMaskDocument(values.document)}
                                                                            maskChar={null}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            value={values.document}
                                                                        >
                                                                            {() => (
                                                                                <Box mb={2}>
                                                                                    <FormControl>
                                                                                        <FormLabel>Seu CPF/CNPJ</FormLabel>
                                                                                        <Input
                                                                                            error={Boolean(touched.document && errors.document)}
                                                                                            helperText={touched.document && errors.document}
                                                                                            fullWidth
                                                                                            name="document"
                                                                                            placeholder="Qual seu CPF/CNPJ?" />
                                                                                    </FormControl>
                                                                                    <FormHelperText error>
                                                                                        {errors.document}
                                                                                    </FormHelperText>
                                                                                </Box>
                                                                            )}
                                                                        </InputMask>
                                                                    ) : (
                                                                        <Box mb={2}>
                                                                            <FormControl>
                                                                                <FormLabel>Seu e-mail</FormLabel>
                                                                                <Input
                                                                                    error={Boolean(touched.email && errors.email)}
                                                                                    helperText={touched.email && errors.email}
                                                                                    fullWidth
                                                                                    autoFocus
                                                                                    name="email"
                                                                                    onBlur={handleBlur}
                                                                                    onChange={handleChange}
                                                                                    type="email"
                                                                                    value={values.email}
                                                                                    placeholder="Qual seu e-mail?" />
                                                                            </FormControl>
                                                                            <FormHelperText error>
                                                                                {errors.email}
                                                                            </FormHelperText>
                                                                        </Box>
                                                                    )
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box mb={2}>
                                                                    <FormControl>
                                                                        <Box display="flex" justifyContent="space-between" mb={1}>
                                                                            <FormLabel>Senha</FormLabel>
                                                                            <span onClick={handleRecoverPassword} className="btnForgetPass">
                                                                                {recoverPassword ?
                                                                                    translate('returnToLoginModal')
                                                                                    :
                                                                                    translate('forgotPassword')
                                                                                }
                                                                            </span>
                                                                        </Box>
                                                                        <Input
                                                                            error={Boolean(touched.password && errors.password)}
                                                                            helperText={touched.password && errors.password}
                                                                            fullWidth
                                                                            name="password"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            type={showPassword ? "text" : "password"}
                                                                            value={values.password}
                                                                            placeholder="Informe sua senha:"
                                                                            endAdornment={(
                                                                                <InputAdornment position="end">
                                                                                    <Icon
                                                                                        style={{ height: '8', cursor: 'pointer' }}
                                                                                        aria-label="toggle password visibility"
                                                                                        onClick={handleToglePasswordVisibility}
                                                                                    >
                                                                                        {showPassword ? <Visibility style={{ color: '#E4E4E4' }} /> : <VisibilityOff style={{ color: '#E4E4E4' }} />}
                                                                                    </Icon>
                                                                                </InputAdornment>
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        :
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Box mb={2}>
                                                                    <FormControl>
                                                                        <Box display="flex" justifyContent="space-between" mb={1}>
                                                                            <FormLabel>Seu e-mail</FormLabel>
                                                                            <span onClick={handleRecoverPassword} className="btnForgetPass">
                                                                                {recoverPassword ?
                                                                                    translate('returnToLoginModal')
                                                                                    :
                                                                                    translate('forgotPassword')
                                                                                }
                                                                            </span>
                                                                        </Box>
                                                                        <Input
                                                                            error={Boolean(touched.recoverPassword && errors.recoverPassword)}
                                                                            helperText={touched.recoverPassword && errors.recoverPassword}
                                                                            label={translate('labelForgotPassword')}
                                                                            name="recoverPassword"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            type="recoverPassword"
                                                                            placeholder="Qual seu e-mail?"
                                                                        />
                                                                    </FormControl>
                                                                    <FormHelperText error>
                                                                        {errors.recoverPassword}
                                                                    </FormHelperText>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Box>
                                                {!recoverPassword ?
                                                    <Box>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            className="btnLogin"
                                                        >
                                                            {translate('buttonLogIn')}
                                                        </Button>
                                                        <Box className="registerAction">
                                                            Ainda não tem cadastro?
                                                            <Button component={RouterLink} to="/onestepcheckout/customer/register" className="btnLink">
                                                                Cadastre-se agora
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Box>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            className="btnLogin"
                                                        >
                                                            {translate('buttonForgotPassword')}
                                                        </Button>
                                                        <Box className="registerAction">
                                                            Ainda não tem cadastro?
                                                            <Button component={RouterLink} to="/onestepcheckout/customer/register" className="btnLink">
                                                                Cadastre-se agora
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Form>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        )
                    }}
                </Formik>
            </Box>
        </>
    );
}

export default ModalLogin;