import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge as MuiBadge, IconButton } from '@material-ui/core';
import { ShoppingCartOutlined as ShoppingCartIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import DrawerCart from 'src/layouts/MainLayout/Cart';

const Badge = withStyles({
    badge: {
        top: 0,
        color: '#FFFFFF',
        backgroundColor: '#ff367d',
    },
})(MuiBadge);

function BottomNavCart() {
    const [cartDrawerIsOpen, setCartDrawerIsOpen] = useState(false);
    const order = useSelector(state => state.cart.order);
    const ref = useRef();

    const quantity = useMemo(() => {
        return order.items
            .map(item => {
                if (item.parent && item.type !== 'addon') {
                    return 0;
                }
                return Number(item.quantity);
            })
            .reduce((acc, current) => acc + current, 0);
    }, [order.items]);

    useEffect(() => {
        if (quantity > 0) {
            if (ref.current) {
                ref.current.className = ref.current.className + ' shopping-cart';
            }
        }
        const timeout = setTimeout(() => {
            if (ref.current) {
                ref.current.className = 'MuiBadge-root';
            }
        }, 1000);
        return () => clearTimeout(timeout);
    }, [quantity]);

    return (
        <>
            <IconButton onClick={() => setCartDrawerIsOpen(true)}>
                <Badge ref={ref} badgeContent={quantity} color="secondary">
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
            <DrawerCart
                openDrawer={cartDrawerIsOpen}
                order={order}
                handleDrawerClose={() => setCartDrawerIsOpen(false)}
            />
        </>
    );
}

export default BottomNavCart;
