import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    featureItems: {
        marginBottom: 15,
        paddingLeft: 15,
        '& li+li': {
            marginTop: '8px'
        }
    },
    featureItem: {
        color: '#777777',
        fontSize: '12px',
        fontFamily: 'Manrope',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.03333em'
    }
}));

export default function ConfigurationList({ item={}, items }) {
    const classes = useStyles();

    const bundleName = item.additionalInformation?.bundle?.template?.name;

    let list = [];
    items.map(child => {
        if (child.parent && child.parent == item.id) {
            list.push(
                <li key={child.id} className={classes.featureItem}>{child.name}</li>
            );
        }
    });

    return (
        <Box className={classes.featureItems}>
            <ul>
                { bundleName && (
                    <li className={classes.featureItem}>Configuração <strong>{bundleName}</strong></li>
                )}
                {list}
            </ul>
        </Box>
    )
}
