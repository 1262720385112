import React from 'react';
import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from '@material-ui/core';

export function HeaderMessage() {
  const theme = useTheme();
  const company = useSelector((store) => store.interface.store);

  return (
    <>
      {company.headerMessage && (
        <Box
          width="100%"
          height="30px"
          top="0"
          position="sticky"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.palette.primary.main}
          color="#fff"
        >
          <Typography
            variant="body1"
            component="div"
            align="center"
          >
            {company.headerMessage}
          </Typography>
        </Box>
      )
      }
    </>
  )
}
