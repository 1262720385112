import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { Favorite, FavoriteBorderOutlined } from "@material-ui/icons";
import useFavorites from "src/hooks/useFavorites";

function MobileCardFavoriteButton({ product, color }) {
    const { addProductToFavorites, removeProductFromFavorite, browserHasSuport } = useFavorites();
    const localStorageFavoritesList = useSelector((state) => state.interface.localStorageFavorites);
    const [databaseFavoriteId, setDatabaseFavoriteId] = useState(null);
    const favorites = useSelector((state) => state.interface.favorites);

    async function handleFavorite (event) {
        event.preventDefault();
        if (databaseFavoriteId) {
            await removeProductFromFavorite(product.id)
        } else {
            await addProductToFavorites(product);
        }
    }

    useEffect(_ => {
        if (!browserHasSuport) {
            const favoriteData = localStorageFavoritesList.find(fav => fav === product.id);
            if (favoriteData) {
                setDatabaseFavoriteId(favoriteData)
            } else {
                setDatabaseFavoriteId(null)
            }

        }
    }, [localStorageFavoritesList, browserHasSuport, product]);

    useEffect(_ => {
        if (browserHasSuport) {
            const favoriteData = favorites.find(fav => fav?.productId === product.id);
            if (favoriteData) {
                setDatabaseFavoriteId(favoriteData.id)
            } else {
                setDatabaseFavoriteId(null)
            }
        }
    }, [favorites, product]);


    return (
        <IconButton
            size="small"
            onClick={handleFavorite}
        >
            {databaseFavoriteId ? (
                <Favorite className="filled" />
            ) : (
                <FavoriteBorderOutlined />
            )}
        </IconButton>
    );
}

export default MobileCardFavoriteButton;
