import { useTranslation } from 'react-i18next';

export function useMask() {
  const [translate, i18n] = useTranslation();

  const language = i18n.language;

  function maskDocument(type) {
    switch (language) {
      case 'en-US':
        return maskDocumentEnUs(type);
      case 'pt-BR':
        return maskDocumentPtBr(type);
    }
  }

  function maskDocumentEnUs(type) {
    return type === 'company'
      ? {
        documentLabel: translate('titleLabelCompanyDocumentCheckout'),
        documentMask: '99-9999999'
      } : {
        documentLabel: translate('titleLabelDocumentCheckout'),
        documentMask: '******************'
      }
  }

  function maskDocumentPtBr(type) {
    return type === 'company'
      ? {
        documentLabel: translate('titleLabelCompanyDocumentCheckout'),
        documentMask: '99.999.999/9999-99'
      } : {
        documentLabel: translate('titleLabelDocumentCheckout'),
        documentMask: '999.999.999-99'
      }
  }

  function dinamicMaskDocument(value) {
    return value && value.length > 14
      ? '99.999.999/9999-99'
      : '999.999.999-999'
  }


  function maskPhone(phone) {
    switch (language) {
      case 'en-US':
        return maskPhoneEnUs(phone);
      case 'pt-BR':
        return maskPhonePtBr();
      default:
        return maskPhonePtBr();
    }
  };

  function maskPhonePtBr(phone) {
    return (phone && phone.length > 14) ? '(99) 99999-9999' : '(99) 9999-99999';
  }

  function maskPhoneEnUs() {
    return '(999) 999-9999';
  }

  function maskZipCode() {
    switch (language) {
      case 'en-US':
        return maskZipCodeEnUs();
      case 'pt-BR':
        return maskZipCodePtBr();
    }
  };

  function maskZipCodePtBr() {
    return '99999-999';
  }

  function maskZipCodeEnUs() {
    return '99999';
  }

  return { maskDocument, maskPhone, maskZipCode, dinamicMaskDocument };
}
