import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import { fetchCart } from 'src/actions/cartActions';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = 'LOGOUT';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login({
  email,
  password,
  document,
  accessTokenFacebook,
  accessTokenGoogle
}) {
  return async (dispatch, getState) => {

    const { cart } = getState();

    try {
      dispatch({ type: LOGIN_REQUEST });

      let user = null;

      if(accessTokenFacebook || accessTokenGoogle) {
        const token = accessTokenFacebook || accessTokenGoogle;
        const type = accessTokenFacebook ? 'facebookToken' : 'googleToken'
        user = await authService.loginWithSocialMedia(token, type);
      }
      else if (document) {
        user = await authService.loginWithDocumentAndPassword(document, password, cart.order.id);
      } else {
        user = await authService.loginWithEmailAndPassword(email, password, cart.order.id);
      }

      dispatch(fetchCart(user.customer_cart));

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });

      return {
        result: true,
        isCustomerDataComplete: isCustomerDataComplete(user)
      };
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });

      return false;
    }
  };
}

function isCustomerDataComplete(user) {
  const { document, birthDate, phone } = user;
  
  return document && birthDate && phone;
}

export function setUserData(user) {
  return async (dispatch) => {
    try {
      dispatch(fetchCart(user.customer_cart));
      dispatch({
        type: SILENT_LOGIN,
        payload: {
          user
        }
      });

      return true;
    } catch (error) {
    }
  };
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function updateProfile(user) {
  return async dispatch => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        user,
      },
    });
  };
}
