import React from 'react';

import { useSelector } from 'react-redux';

import { GoogleAnalytics } from './GoogleAnalytics';
import { GoogleTagManager } from './GoogleTagManager';
import { FacebookPixel } from './FacebookPixel';
import { Criteo } from './Criteo';
import { TikTokAnalytics } from './TikTokAnalytics';

export function Analytics() {
    const store = useSelector((state) => state.interface.store);
    const customer = useSelector((state) => state.account.user);

    const googleAnalyticsId = store.tools?.googleAnalyticsId;
    const googleTagManagerId = store.tools?.googleTagManagerId;
    const facebookPixelId = store.tools?.facebookPixelId;
    return (
        <>
            <GoogleAnalytics analyticsId={googleAnalyticsId} customer={customer} />
            <GoogleTagManager tagManagerId={googleTagManagerId} />
            <FacebookPixel pixelId={facebookPixelId} />
            <Criteo />
            <TikTokAnalytics />
        </>
    );
}
