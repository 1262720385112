import { useSelector } from 'react-redux';

const useSeals = product => {
    const attributes = useSelector(state => state.interface.globalData.attributesMenu);

    const getSeal = (code) => {
      const sealAttribute = attributes.find(attribute => attribute.code === code);
      const productSeal = product.attributes.find(attribute => attribute.code === code);
      return sealAttribute?.options.find(option => option.id === productSeal?.optionId);
    }

    return {
      seal1: getSeal('selo-01'),
      seal2: getSeal('selo-02'),
      seal3: getSeal('selo-03'),
    }
};

export default useSeals;
