import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { FavoriteBorderOutlined as HeartIcon } from '@material-ui/icons';

function BottomNavHeart() {
    return (
        <IconButton component={Link} to="/wishlist">
            <HeartIcon />
        </IconButton>
    );
}

export default BottomNavHeart;
