import React from 'react';

import {
    Box,
    Container,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    makeStyles
} from '@material-ui/core';

import Swiper from 'react-id-swiper';

import discount from 'src/theme/scss/icons/discount.svg';
import delivery from 'src/theme/scss/icons/delivery.svg';
import warranty from 'src/theme/scss/icons/warranty.svg';
import safeBuy from 'src/theme/scss/icons/safeBuy.svg';
import pixIcon from 'src/theme/scss/icons/pix-icon.svg';

const useStyles = makeStyles((theme) => ({
    swiperMainContainer: {
        '& .swiper-container': {
            padding: '20px 0 60px 0',
            '& .swiper-pagination-bullets': {
                bottom: 0,

                '& .swiper-pagination-bullet': {
                    backgroundColor: '#C9C9C9',
                },
                '& .swiper-pagination-bullet-active': {
                    backgroundColor: 'transparent',
                    border: '2px solid #e71b23',
                    height: '12px',
                    width: '12px',
                    transform: 'scale(1)'
                }
            }
        }
    },
    featuresAvell: {
        backgroundColor: '#ffffff',
        height: '210px',
        display: 'flex',
        alignItems: 'center'
    },
    containerFeatures: {
        height: '100%',
        [theme.breakpoints.down('1099')]: {
            flexWrap: 'nowrap',
            overflowX: 'scroll',
        },
    },
    featureAvell: {
        position: 'relative',
        paddingLeft: '110px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 375
        },
    },
    featureIcon: {
        position: 'absolute',
        left: '0',
        border: '3px solid #F4F4F4',
        borderRadius: '50%',
        width: '100px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        width: 48,
        height: 48
    },
    fetaureText: {
        fontSize: '22px',
    },
    hidden: {
        display: 'none'
    },
    show: {
        display: 'block'
    }
}));


export function Features() {
    const theme = useTheme();
    const mobileSize = !(useMediaQuery(theme.breakpoints.up('sm')));

    const classes = useStyles();

    const featureItems = [
        {
            id: 1,
            icon: pixIcon,
            title: "<strong>10% de desconto</strong>",
            subtitle: "no PIX",
        },
        {
            id: 2,
            icon: delivery,
            title: "Entrega",
            subtitle: "<strong>muito rápida</strong>",
        },
        {
            id: 3,
            icon: warranty,
            title: "Garantia",
            subtitle: "de <strong>1 ano</strong>",
        },
        {
            id: 4,
            icon: safeBuy,
            title: "Compra <strong>segura</strong>",
            subtitle: "<strong>e protegida</strong>",
        },
    ];

    const swiperParams = {
        speed: 500,
        loop: false,
        spaceBetween: 0,
        effect: 'slide',
        slidesPerView: 1,
        rebuildOnUpdate: true,
        observer: true,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    };

    const FeatureItem = ({ item }) => {
        return (
            <Box className={classes.featureAvell}>
                <div className={classes.featureIcon}>
                    <img src={item.icon} className={classes.icon} />
                </div>
                <Typography className={classes.fetaureText}>
                    <Box component="span" dangerouslySetInnerHTML={{ __html: item.title }} /><br />
                    <Box component="span" dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                </Typography>
            </Box>
        )
    };

    return (
        <Box className={classes.featuresAvell}>
            <Container maxWidth={false} style={{ height: "100%" }}>
                {mobileSize ? (
                    <Box mt={6} className={classes.swiperMainContainer}>
                        <Swiper {...swiperParams}>
                            {featureItems.map(item => (
                                <div
                                    key={item.id}
                                >
                                    <FeatureItem item={item} />
                                </div>
                            ))}
                        </Swiper>
                    </Box>
                ) : (
                    <Grid
                        container
                        alignItems="center"
                        className={classes.containerFeatures}
                    >
                        {featureItems.map(item => (
                            <Grid item md={3} xs={12} key={item.id}>
                                <FeatureItem item={item} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Container>
        </Box>
    );
}