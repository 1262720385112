import React from "react";

import {
  Link,
  Box,
  makeStyles
} from "@material-ui/core";
import { getBoxMarginProps, getBreakpointValue } from "./functions";
import { useTheme } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";

import LazyLoad from 'react-lazy-load';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%'
  }
}));

export const Image = (props) => {

  const classes = useStyles();

  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

  const imageProps = {
    src: getBreakpointValue(props, breakpoint, 'image', 'url'),
    style: {}
  };


  const maxWidth = getBreakpointValue(props, breakpoint, 'maxWidth');

  if (maxWidth) {
    imageProps.style.maxWidth = `${maxWidth}px`
  }

  if(getBreakpointValue(props, breakpoint, 'border')) {
    const borderRadius = {
        borderTopLeftRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopLeftRadius')) || null,
        borderTopRightRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopRightRadius')) || null,
        borderBottomLeftRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomLeftRadius')) || null,
        borderBottomRightRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomRightRadius')) || null
    };

    Object.entries(borderRadius).map(borderSide => {
        if (borderSide[1]) {
            imageProps.style[borderSide[0]] = borderSide[1];
        }
    })
  }

  return (
    <Box
      { ...getBoxMarginProps(props, breakpoint) }
      textAlign={getBreakpointValue(props, breakpoint, 'textAlign')}
    >
      <LazyLoad>
        {props.link ? (
          <Link href={props.link} target="_blank">
            <img className={classes.image} {...imageProps} />
          </Link>
        ) : (<img className={classes.image} {...imageProps} />
        )}
      </LazyLoad>
    </Box>
  )
}
