import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Box, TextField, InputLabel, ClickAwayListener } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Search as FeatherSearchIcon } from 'react-feather';
import { Form, Formik } from 'formik';
import { trackSearch } from 'src/utils/analytics';

const useStyles = makeStyles({
    search: {
        position: 'fixed',
        bottom: props => props.menuIsVisible ? '80px' : '8px',
        left: '8px',
        right: '8px',
        backgroundColor: '#333333',
        padding: '16px',
        borderRadius: '8px',
        opacity: '0',
        visibility: 'hidden',
        transition: 'all .3s',
        '&.show': {
            opacity: '1',
            visibility: 'visible'
        },
        '& .MuiInputBase-root': {
            padding: '0'
        },
        '& ..MuiOutlinedInput-adornedEnd': {
            padding: '0'
        },
        '& label.Mui-focused': {
            color: '#FFFFFF'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FFFFFF'
        },
        '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#FFFFFF'
        }
    },
    inputLabel: {
        display: 'inline-block',
        fontFamily: 'Gilroy-ExtraBold',
        fontSize: '18px',
        color: '#FFFFFF',
        marginBottom: '12px'
    },
    notchedOutline: {
        borderWidth: 0,
        borderColor: '',
        '&:hover': {
            backgroundColor: 'rgb(7, 177, 77, 0.42)'
        }
    }
});

function BottomNavSearch({ menuIsVisible }) {
    const classes = useStyles({ menuIsVisible });
    const [showSearchField, setShowSearchField] = useState(false);
    const searchRef = useRef();
    const inputRef = useRef();
    const history = useHistory();

    useEffect(() => {
        if (showSearchField) {
            searchRef.current.classList.add('show');
        } else {
            searchRef.current.classList.remove('show');
        }
    }, [showSearchField]);

    return (
        <ClickAwayListener onClickAway={() => setShowSearchField(false)}>
            <Box>
                <IconButton onClick={() => setShowSearchField(!showSearchField)}>
                    <SearchIcon />
                </IconButton>
                <Box ref={searchRef} className={classes.search}>
                    <Formik
                        initialValues={{ term: '' }}
                        enableReinitialize
                        onSubmit={async (values, { resetForm }) => {
                            setShowSearchField(false);
                            trackSearch(values.term);
                            history.push('/search?q=' + values.term);
                            resetForm();
                        }}
                    >
                        {({ handleBlur, handleSubmit, setFieldValue, values }) => {
                            return (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <InputLabel htmlFor="term" className={classes.inputLabel}>
                                            Buscar por produto
                                        </InputLabel>
                                        <TextField
                                            inputRef={inputRef}
                                            id="term"
                                            onBlur={handleBlur}
                                            onChange={e => setFieldValue('term', e.target.value)}
                                            value={values.term}
                                            placeholder="O que você procura?"
                                            InputLabelProps={{
                                                style: {
                                                    color: '#757575'
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        <IconButton onClick={handleSubmit}>
                                                            <FeatherSearchIcon style={{ color: '#ed1c24' }} />
                                                        </IconButton>
                                                    </>
                                                ),
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            variant="outlined"
                                        />
                                    </Form>
                                </>
                            );
                        }}
                    </Formik>
                </Box>
            </Box>
        </ClickAwayListener>
    );
}

export default BottomNavSearch;
