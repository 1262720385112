import React from 'react';
import { darken, withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Box, Input, Grid, FormControl } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ManagerApi from 'src/services/managerApi';
import { useDispatch } from 'react-redux';
import { addMessage } from 'src/actions';
import dialogIllustration from 'src/theme/scss/images/modal-telefone.png';
import closeModalIcon from 'src/theme/scss/icons/close-modal.svg'
import { useHistory } from 'react-router';

const DialogContent = withStyles((theme) => ({
    root: {
        padding: '80px 60px',
        backgroundColor: '#F4F4F4',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            padding: '48px 24px',
        }
    },
}))(MuiDialogContent);

const DialogHeading = withStyles((theme) => ({
    root: {
        color: '#000000',
        fontSize: '30px',
        lineHeight: '1.2',
        fontFamily: 'Gilroy-ExtraBold',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            marginTop: '32px',
            textAlign: 'center',
        },
        '& span': {
            textTransform: 'uppercase',
            color: '#ff367d', // '#db1842',
        },
    },
}))(Typography);

const DialogSubHeading = withStyles((theme) => ({
    root: {
        fontSize: '18px',
        color: '#333333',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            textAlign: 'center'
        },
    },
}))(Typography);

const DialogImageContainer = withStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        '& img': {
            width: '100%',
            [theme.breakpoints.down('md')]: {
                width: '80%',
            }
        }
    },
}))(Box);

const DialogInfoContainer = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '60px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 'initial',
        },
        '& form': {
            '& > *': {
                marginBottom: '12px',
            },
            '& input': {
                fontSize: '1.125rem',
                '&::placeholder': {
                    fontSize: '1.125rem',
                }
            },
            '& button': {
                width: '100%',
                color: '#ffffff',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: '800',
                fontFamily: 'Manrope',
                borderRadius: '6px',
                padding: '12px 24px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '14px',
                }
            }
        }
    },
}))(Box);

const DialogCloseButton = withStyles((theme) => ({
    root: {
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'translate(50%, -50%)',
        filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1))'
    },
}))(Box);


const ErrorMessage = withStyles((theme) => ({
    root: {
        color: '#FF0000',
        fontSize: '.875rem',
    },
}))(Typography);

const SubmitButton = withStyles((theme) => ({
    root: {
        backgroundColor: '#ff367d', // '#db1842',
        '&:hover': {
            backgroundColor: darken('#ff367d', 0.1),
        }
    }
}))(Button);

export default function DialogTelefoneSuporte({ handleClickOpen, open, handleClose }) {
    const dispatch = useDispatch();

    let history = useHistory();

    const validationSchema = Yup.object().shape({
        areaCode: Yup.string().max(3).required("Informe o DDD"),
        phone: Yup.string().max(10, "Informe no máximo 10 dígitos").required("Informe o número"),
    });

    let initialForm = {
        areaCode: '',
        phone: ''
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                PaperProps={{ style: { maxWidth: '1004px', overflow: 'visible' } }}
            >
                <DialogCloseButton onClick={handleClose}>
                    <img src={closeModalIcon} alt="" />
                </DialogCloseButton>
                <DialogContent>

                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <DialogImageContainer>
                                <img src={dialogIllustration} alt="Nós Ligamos" />
                            </DialogImageContainer>
                        </Grid>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DialogInfoContainer>
                                <DialogHeading>Informe seu telefone que <span>nós ligamos</span> para você!</DialogHeading>
                                <DialogSubHeading>
                                    Informe abaixo o seu número que entraremos em contato, ou caso preferir, ligue para o nosso suporte pelo telefone (47) 3801-6000.
                                </DialogSubHeading>
                                <Formik
                                    validationSchema={validationSchema}
                                    initialValues={initialForm}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                    onSubmit={async (values, { resetForm }) => {
                                        const clickToCallApi = new ManagerApi('/click-to-call');

                                        let data = new FormData();
                                        data.append('target', 'support');

                                        for (let key in values) {
                                            data.append(key, values[key]);
                                        }

                                        const response = await clickToCallApi.post(data);

                                        if (response.data) {
                                            let message = response.data.success ? 'Solicitação realizada com sucesso !' : response.data.message;

                                            dispatch(addMessage(message, response.data.success ? 'success' : 'error'));
                                            if (response.data.success) {
                                                setTimeout(() => {
                                                    handleClose();
                                                }, 2000);
                                                resetForm();
                                            } else if (!response.data.success) {

                                            }
                                        }
                                    }}
                                >
                                    {({ values, handleSubmit, handleBlur, handleChange, errors }) => {
                                        return (
                                            <form>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl >
                                                            <Input
                                                                placeholder='DDD'
                                                                fullWidth
                                                                label="DDD"
                                                                name="areaCode"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.areaCode}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={9}>
                                                        <FormControl >
                                                            <Input placeholder='Telefone'
                                                                fullWidth
                                                                label="Telefone"
                                                                name="phone"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.phone}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {/* <Button onClick={() => {handleSubmit(); history.push('/contato');} }  variant="contained" color="primary">Enviar Solicitação</Button> */}
                                                {/* <Button onClick={() => {handleSubmit(); history.push('/contato');} }  variant="contained" color="primary">E-MAIL</Button> */}
                                                <SubmitButton onClick={handleSubmit} variant="contained">Enviar solicitação</SubmitButton>
                                                <Box display="flex" flexDirection="column">
                                                    {errors.areaCode && <ErrorMessage>{errors.areaCode}</ErrorMessage>}
                                                    {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
                                                </Box>
                                                <Box>
                                                    <Typography style={{ textAlign: 'center' }}>
                                                        Atenção, horário de atendimento: <br /> de segunda a sexta das 08h30 às 18h00
                                                    </Typography>
                                                </Box>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </DialogInfoContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}