import React from "react";

import { Grid, Box, useTheme, useMediaQuery, Link } from "@material-ui/core";

import { Link as RouterLink } from 'react-router-dom';

import { getBreakpointBackgroundImage, getBoxMarginProps, getBoxPaddingProps, getBreakpointValue, getColor, getHeight } from "./functions";

export const GridContainer = (props) => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

    const boxProps = {
        style: {
            backgroundImage: getBreakpointBackgroundImage(props, breakpoint),
            backgroundSize: getBreakpointValue(props, breakpoint, 'background', 'size'),
            backgroundColor: getColor(getBreakpointValue(props, breakpoint, 'background', 'color')),
            backgroundRepeat: getBreakpointValue(props, breakpoint, 'background', 'repeat'),
            backgroundPosition: getBreakpointValue(props, breakpoint, 'background', 'position'),
        }
    };

    const height = getHeight(props, breakpoint);
    
    if(height) boxProps.style.height = height;

    if( getBreakpointValue(props, breakpoint, 'hidden', null, false)) boxProps.style.display = 'none';

    if(getBreakpointValue(props, breakpoint, 'border')) {
        const borderRadius = {
            borderTopLeftRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopLeftRadius')) || null,
            borderTopRightRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopRightRadius')) || null,
            borderBottomLeftRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomLeftRadius')) || null,
            borderBottomRightRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomRightRadius')) || null
        };

        Object.entries(borderRadius).map(borderSide=>{
            if(borderSide[1]) {
                boxProps.style[borderSide[0]] = borderSide[1];
            }
        })

        if(getBreakpointValue(props, breakpoint, 'border', 'borderColor')) {
            boxProps.style.borderTopWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopWidth')) || 0;
            boxProps.style.borderBottomWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomWidth')) || 0;
            boxProps.style.borderRightWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderRightWidth')) || 0;
            boxProps.style.borderLeftWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderLeftWidth')) || 0;
            boxProps.style.borderColor = getColor(getBreakpointValue(props, breakpoint, 'border', 'borderColor'));
            boxProps.style.borderStyle = 'solid';
        }
    }

    return (
        <Box
            {...getBoxMarginProps(props, breakpoint)}
            {...getBoxPaddingProps(props, breakpoint)}
            {...boxProps}
        >
            <Grid
                container
                spacing={Number(getBreakpointValue(props, breakpoint, 'spacing'))}
                justifyContent={getBreakpointValue(props, breakpoint, 'justifyContent')}
                alignItems={getBreakpointValue(props, breakpoint, 'alignItems')}
            >
                {props.children}
            </Grid>
        </Box>
    )
};

export const GridItem = (props) => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

    const boxProps = {
        style: {
            height: '100%',
            width: '100%',
            backgroundImage: getBreakpointBackgroundImage(props, breakpoint),
            backgroundSize: getBreakpointValue(props, breakpoint, 'background', 'size'),
            backgroundColor: getColor(getBreakpointValue(props, breakpoint, 'background', 'color')),
            backgroundRepeat: getBreakpointValue(props, breakpoint, 'background', 'repeat'),
            backgroundPosition: getBreakpointValue(props, breakpoint, 'background', 'position')
        }
    };


    if(getBreakpointValue(props, breakpoint, 'border')) {
        const borderRadius = {
            borderTopLeftRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopLeftRadius')) || null,
            borderTopRightRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopRightRadius')) || null,
            borderBottomLeftRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomLeftRadius')) || null,
            borderBottomRightRadius: Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomRightRadius')) || null
        };
    
        Object.entries(borderRadius).map(borderSide=>{
            if(borderSide[1]) {
                boxProps.style[borderSide[0]] = borderSide[1];
            }
        })
    
        if(getBreakpointValue(props, breakpoint, 'border', 'borderColor')) {
            boxProps.style.borderTopWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderTopWidth')) || 0;
            boxProps.style.borderBottomWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderBottomWidth')) || 0;
            boxProps.style.borderRightWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderRightWidth')) || 0;
            boxProps.style.borderLeftWidth = Number(getBreakpointValue(props, breakpoint, 'border', 'borderLeftWidth')) || 0;
            boxProps.style.borderColor = getColor(getBreakpointValue(props, breakpoint, 'border', 'borderColor'));
            boxProps.style.borderStyle = 'solid';
        }
    }

    if(props.href) {
        const isRouterUrl = (props.href.indexOf("://") < 0);

		if(isRouterUrl) {
			boxProps.component = RouterLink;
			boxProps.to = props.href;
		}
		else {
            boxProps.component = Link;
			boxProps.href = props.href;
			boxProps.target = '_blank';
		}

        boxProps.style.color = 'inherit';
        boxProps.style.textDecoration = 'none';
    }

    const gridStyle = {
        style: {}
    };

    const height = getHeight(props, breakpoint);
    
    if(height) gridStyle.style.height = height;

    if( getBreakpointValue(props, breakpoint, 'hidden', null, false)) gridStyle.style.display = 'none';

    const margins = getBoxMarginProps(props, breakpoint, false);

    Object.entries(margins).map(marginSide=>{
        gridStyle.style[marginSide[0]] = theme.spacing(marginSide[1]);
    });

    return (
        <Grid
            item
            xs={getBreakpointValue(props, breakpoint, 'size')}
            md={getBreakpointValue(props, breakpoint, 'size')}
            {...gridStyle}
        >
            <Box
                {...getBoxPaddingProps(props, breakpoint)}
                display="flex"
                justifyContent={getBreakpointValue(props, breakpoint, 'justifyContent')}
                alignItems={getBreakpointValue(props, breakpoint, 'alignItems')}
                flexDirection={getBreakpointValue(props, breakpoint, 'flexDirection')}
                {...boxProps}
            >
                {props.children}
            </Box>
        </Grid>
    );
};